import { useRequest } from "..";
import { postWithAuth, getWithAuth, patchWithAuth } from "../requests";
import { toFullName } from "../format";

const useGetCustomerInstitution = (customerId) => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}institution?page=1&employee=${customerId}`,
    getWithAuth(),
    (raw: any) => raw,
    customerId !== null
  );
};

const mapper = (raw: any) => {
  return {
    ...raw,
    employees: raw.employees.map((employee) => {
      return {
        ...employee,
        fullname: toFullName(employee.firstname, employee.lastname),
      };
    }),
  };
};

const useGetInstitutions = (
  page: number = 1,
  validate: boolean = true,
  setValidate: (validate: boolean) => void = () => {}
) => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}institution?page=${page}`,
    getWithAuth(),
    (raw: any) => {
      return raw.map((institution) => {
        return mapper(institution);
      });
    },
    validate,
    setValidate
  );
};

const useGetInstitutionsCount = () => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}institution/count`,
    getWithAuth(),
    (raw: any) => raw,
    true
  );
};

const useGetInstitution = (id: string, validate = true) => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}institution/${id}`,
    getWithAuth(),
    (raw: any) => mapper(raw),
    validate
  );
};

const useGetInstitutionsForSelect = () => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}institution/all/light`,
    getWithAuth(),
    (raw: any) => {
      return raw.map((institution) => {
        return {
          value: institution.id,
          label: institution.name,
        };
      });
    },
    true
  );
};

const usePostOrPatchInstitution = (
  institutionId,
  body: any,
  validate: boolean,
  setValidate: (validate: boolean) => void
) => {
  const serializedBody = JSON.stringify(body);
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}institution${
      institutionId ? `/${institutionId}` : ""
    }`,
    institutionId
      ? patchWithAuth(serializedBody)
      : postWithAuth(serializedBody),
    (raw: any) => raw,
    validate,
    setValidate
  );
};

export {
  useGetCustomerInstitution,
  usePostOrPatchInstitution,
  useGetInstitutions,
  useGetInstitutionsForSelect,
  useGetInstitution,
  useGetInstitutionsCount,
};
