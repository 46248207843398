import {
  PCRTests,
  Hospital24,
  Laboratory24,
  Clock24,
  GirlPlus24,
} from "../assets/icons/icons";

export function MissionItem({ bookmark, index, warnParent, selected }) {
  function changeIndex(index) {
    warnParent(index);
  }

  return (
    <div
      className="bg-fff br-05 row pt-1 pb-1 pl-1 pr-1"
      style={{
        display: "grid",
        gridTemplateColumns: "auto min(350px) auto 1fr",
        columnGap: "1rem",
      }}
    >
      <div
        onClick={() => changeIndex(index)}
        className="circle ml-05 mr-05 as-c d-flex pointer"
        style={{
          width: "1.25rem",
          height: "1.25rem",
          borderRadius: "50%",
          border: "1px solid #221B43",
          gridRow: "1/2",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className={selected ? "bg-green circle" : "circle"}
          style={{
            width: "16px",
            height: "16px",
          }}
        ></div>
      </div>

      <div className="row as-fs">
        <img
          src={bookmark.isHospital ? Hospital24 : Laboratory24}
          className="mr-1 blue-icon"
          style={{ width: "24px" }}
          alt=""
        />
        <div
          style={{
            fontWeight: "600px",
            whiteSpace: "nowrap",
            fontSize: "20px",
          }}
          className="mt-a blue semibold"
        >
          {bookmark.bookmarkName}
        </div>
      </div>
      <div
        className="row as-fs"
        style={{
          gridRow: "2/3",
          gridColumn: "2/3",
        }}
      >
        <img src={PCRTests} className="mr-1" alt="" />
        <div className="blue semibold">{bookmark.serviceLabel}</div>
      </div>
      <div
        className="row as-fs"
        style={{
          gridRow: "2/3",
          gridColumn: "3/4",
        }}
      >
        <img src={Clock24} className="mr-1 blue-icon" alt="" />
        <div className="blue">{bookmark.hourRange}</div>
      </div>

      <div className="row as-fs">
        <img src={GirlPlus24} className="mr-05 blue-icon" alt="" />
        <div className="blue">{bookmark.occupationLabel}</div>
      </div>
    </div>
  );
}
