import React from "react";
import useGetGcu from "../../api/gcu";
import SubMenuWrapper from "./SubMenuWrapper";
import Title from "./Title";

const Gcu = () => {
  const { data } = useGetGcu()

  return (
    <SubMenuWrapper>
      <Title label="Conditions générales d’utilisation ‍📄" />
      {data && (
      <>
        <br></br>
        <div style={{ maxWidth: '700px' }}
        dangerouslySetInnerHTML={{
          __html: data.content
        }}></div>
      </>
    )}
    </SubMenuWrapper>
  )
}
export default Gcu