import React from "react";

function Toggle({ items, chooseToggle, fixedWidth, small = false, selectedItemIdx = 0 }) {

    function getClasses(i) {
        let cl = 'toggle-item';
        if (i === selectedItemIdx) {
            cl = cl.concat(' active');
        }
        if (fixedWidth) {
            cl = cl.concat(' fixedWidth')
        }
        if (small) {
            cl = cl.concat(' small-item')
        }
        return cl;
    }

	return (
        <div className='toggle'>
            {items?.map((item, i) => {
				return (
					<div
						className={
                            getClasses(i)
						}
                        onClick={() => chooseToggle(i)}
						key={i}
					>
                        {item}
					</div>
				);
			})}
		</div>
	);
}

export default Toggle;
