import React from "react";
import { Block } from "../Block";
import { DateRange } from "./DateRange";
import { Calendar32 } from "../../../assets/icons/icons";
import { ArrayValueProps, StringValueProps } from "../valueProps";

type Props = {
  startDateProps: StringValueProps;
  endDateProps: StringValueProps;
  welcomeBoardDatesProps: ArrayValueProps;
};

const DateBlock = ({
  startDateProps,
  endDateProps,
  welcomeBoardDatesProps,
}: Props) => {
  return (
    <Block icon={Calendar32} label={"Date(s) de la mission"} alt="calendar">
      <DateRange
        startDateProps={startDateProps}
        endDateProps={endDateProps}
        welcomeBoardDatesProps={welcomeBoardDatesProps}
      />
    </Block>
  );
};

export default DateBlock;
