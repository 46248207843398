import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Stepper from './Stepper';
import { occupations, services } from '../data/occupations';
import { TextError } from './index';
import { Formik, Form } from 'formik';
import { CreateMissionContext } from './context/CreateMissionContext';
import { UserContext } from './context/UserContext';
import { useGetInstitution } from '../api/institutions';
import { firstFormSchema } from '../pages/create-mission/validationSchemas';
import SelectBlock from '../pages/create-mission/step-one/SelectBlock';
import UploadImagesBlock from '../pages/create-mission/UploadImagesBlock';
import { firstStepBlockProps } from '../pages/create-mission/step-two/blockProps';
import BookmarkBlock from '../pages/create-mission/step-one/BookmarkBlock';
import isDev from '../isDev';
import { Button, next } from './buttons/buttons';
import CircleInputBlock from '../pages/create-mission/step-two/CircleInputBlock';
import EstablishmentSelect from './EstablishmentSelect';
import { FormInput } from './FormInput';
import { servicePrecision } from '../pages/profile/institutions/inputs';

export default function AddMissionStepOne() {
  const {
    firstStepValues,
    setFirstStepValues,
    secondStepValues,
    setSecondStepValues,
  } = useContext(CreateMissionContext);
  const { selectedInstitution, isRh } = useContext(UserContext);
  const { data } = useGetInstitution(
    selectedInstitution,
    selectedInstitution !== null
  );
  const institution = firstStepValues?.institution ?? data;

  const isHospital = institution?.type === 'hospital';
  const navigate = useNavigate();
  const hospitalInitialValues = isHospital
    ? {
        occupation: isDev ? occupations[0] : null,
      }
    : {};

  return (
    <div>
      {institution && (
        <Formik
          initialValues={
            firstStepValues ?? {
              firstPicture: { url: '', overwrite: false },
              secondPicture: { url: '', overwrite: false },
              occupation: null,
              service:
                !isHospital || isDev
                  ? occupations[0].services.find(
                      (service) => service === services.laboratory
                    )
                  : null,
              institution: institution,
              bookmark: false,
              bookmarkName: '',
              selectedBookmark: null,
              isHospital,
              ...hospitalInitialValues,
              nbSubstitute: 1,
              servicePrecision: '',
            }
          }
          enableReinitialize={true}
          validationSchema={firstFormSchema}
          onSubmit={(values) => {
            setFirstStepValues(values);
            navigate('/missions/new/step-two');
          }}
        >
          {({
            values,
            setFieldValue,
            setValues,
            setFieldTouched,
            errors,
            resetForm,
          }) => {
            const onChange = (key, value) => {
              setFieldValue(key, value);
              setTimeout(() => setFieldTouched(key, true));
            };
            return (
              <Form>
                <Stepper currentStep={0} />
                {isRh() && (
                  <EstablishmentSelect
                    onSelected={() => {
                      resetForm();
                      setFirstStepValues(null);
                      setSecondStepValues(null);
                    }}
                  />
                )}
                <UploadImagesBlock
                  firstUploadImageProps={{
                    imageId: institution.photos?.[0]?.id ?? null,

                    imageUrl: values.firstPicture,
                    setImageUrl: (value) => onChange('firstPicture', value),
                  }}
                  secondUploadImageProps={{
                    imageId: institution.photos?.[1]?.id ?? null,

                    imageUrl: values.secondPicture,
                    setImageUrl: (value) => onChange('secondPicture', value),
                  }}
                />
                <TextError name="firstPicture" />
                {!errors.firstPicture ? (
                  <TextError name="secondPicture" />
                ) : null}
                <SelectBlock
                  selectedItem={values.occupation}
                  items={occupations.filter(
                    (occupation) => occupation.asStaffOnly !== true
                  )}
                  onItemSelected={(occupation) => {
                    setValues({
                      ...values,
                      occupation: occupation,
                      service: isHospital ? null : values.service,
                    });
                    setTimeout(() => setFieldTouched('occupation', true));
                    if (secondStepValues) {
                      setSecondStepValues({
                        ...secondStepValues,
                        capacitations: [null, null, null],
                      });
                    }
                  }}
                  placeholder="Métier"
                  blockProps={firstStepBlockProps.occupation}
                />
                <TextError name="occupation" />
                <SelectBlock
                  isClickable={
                    isHospital ? values.occupation?.services?.length > 0 : false
                  }
                  selectedItem={values.service}
                  items={values.occupation?.services}
                  onItemSelected={(value) => {
                    onChange('service', value);
                    if (secondStepValues) {
                      setSecondStepValues({
                        ...secondStepValues,
                        capacitations: [null, null, null],
                      });
                    }
                  }}
                  placeholder={'Sélectionnez un type de mission'}
                  blockProps={firstStepBlockProps.service}
                />
                <TextError name="service" />
                <FormInput
                  inputProps={servicePrecision}
                  value={values.servicePrecision}
                  setValue={(value) => onChange('servicePrecision', value)}
                />
                <TextError name="servicePrecision" />
                <BookmarkBlock
                  selectedBookmark={values.selectedBookmark}
                  setSelectedBookmark={(selectedBookmark) => {
                    setValues({
                      ...values,
                      selectedBookmark: selectedBookmark,
                      occupation: selectedBookmark.occupation,
                      service: selectedBookmark.service,
                      nbSubstitute: selectedBookmark.nbSubstitute,
                    });
                    if (secondStepValues) {
                      setSecondStepValues(null);
                    }
                  }}
                  institution={institution.id}
                />
                <CircleInputBlock
                  label="intérimaire(s)"
                  value={values.nbSubstitute}
                  onChange={(value) => onChange('nbSubstitute', value)}
                  blockProps={firstStepBlockProps.nbSubstitute}
                />
                <div className="row mt-4 mb-3">
                  <Button buttonProps={next} />
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
}
