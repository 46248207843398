type Relation = {
  label: string;
  imageId: string | null;
  id: string;
};

const relationMapper = (entries: any[]) => {
  return entries.map((entry) => {
    return {
      id: entry.id,
      imageId:
        entry.profilePicture ?? entry.photos?.find(() => true)?.id ?? null,
      label:
        entry.firstname || entry.lastname
          ? `${entry.firstname} ${entry.lastname}`
          : entry.name,
    };
  });
};

export { Relation, relationMapper };
