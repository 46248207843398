const getTitleByCategory = (category) => {
  switch (category) {
    case "institution_wb_created":
      return "mission(s) créée(s)";
    case "institution_substitute_selected":
      return "candidat(s) sélectionné(s)";
    case "institution_substitute_fidelized":
      return "candidat(s) fidélisé(s)";
    case "institution_app_filled":
      return "mission(s) complétée(s)";
    case "institution_quality_selected":
      return "le plus sélectionné";
    default:
      return "";
  }
};

const getTitle = (stats, category) => {
  return stats
    ? `${
        stats.find((stat) => stat.month === new Date().getMonth() + 1)?.value ??
        0
      } ${getTitleByCategory(category)}`
    : "";
};

export { getTitleByCategory, getTitle };
