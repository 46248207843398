import React, { useContext, useState } from "react";
import MissionSmall from "./MissionSmall";
import { useGetBookmarks, deleteBookmarks } from "../api/bookmarks";
import { UserContext } from "./context/UserContext";
import { WhiteBin } from "../assets/icons/icons";
import MissionSmallHeader from "./MissionSmallHeader";

export default function WelcomeBoards() {
  const [validate, setValidate] = useState(true);
  const { user } = useContext(UserContext);
  const isHospital = user?.institutions?.[0].type === "hospital";
  const { data } = useGetBookmarks(validate, setValidate);
  const [selectedBookmarks, setSelectedBookmarks] = useState([]);

  return (
    data && (
      <div
        className="ml-2 flex-2 pl-2"
        style={{ borderLeft: "1px solid lightgray" }}
      >
        <div className="nowrap h1 bold mb-2">
          Vos modèles de Welcome Board ‍📄️
        </div>
        <MissionSmallHeader isHospital={isHospital} isBookmark />
        {data.map((m, i) => {
          return (
            <div key={i} className="mb-1">
              <MissionSmall
                mission={m}
                isSelected={selectedBookmarks.includes(m.id)}
                onClicked={() => {
                  if (selectedBookmarks.includes(m.id)) {
                    const filteredSelectedBookmarks = selectedBookmarks.filter(
                      (b) => b !== m.id
                    );
                    setSelectedBookmarks(filteredSelectedBookmarks);
                  } else {
                    const updatedSelectedBookmarks = [
                      m.id,
                      ...selectedBookmarks,
                    ];
                    setSelectedBookmarks(updatedSelectedBookmarks);
                  }
                }}
                isBookmark
              />
            </div>
          );
        })}
        <button
          className={`btn-${
            selectedBookmarks.length > 0 ? "main" : "invalid"
          } mw-fc`}
          disabled={selectedBookmarks.length === 0}
          onClick={async () => {
            await deleteBookmarks(selectedBookmarks);
            setSelectedBookmarks([]);
            setValidate(true);
          }}
        >
          <div>Supprimer</div>
          <img src={WhiteBin} alt="" className="ml-1" />
        </button>
      </div>
    )
  );
}
