import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetCustomers, useGetCustomersCount } from "../api/customer";
import { ProtectedImage } from "./ProtectedImage";
import { getInstitutionIcon } from "../data/institutions";
import { Executive24, Phone24 } from "../assets/icons/icons";
import { Button, addManager } from "./buttons/buttons";
import { Plural } from "./Plural";
import InfiniteScroll from "react-infinite-scroll-component";

export default function ProximityManagers() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [validate, setValidate] = useState(true);
  const { data } = useGetCustomers(page, validate, setValidate);
  const { data: count } = useGetCustomersCount();
  return (
    <div
      className="ml-2 flex-2 pl-2"
      style={{ borderLeft: "1px solid lightgray" }}
    >
      {data && count && (
        <>
          <div className="mb-1 h1 bold">
            <Plural length={count} label="manager" suffix="de proximité 🖇" />
          </div>
          <InfiniteScroll
            dataLength={data.length}
            next={() => {
              setPage(page + 1);
              setValidate(true);
            }}
            hasMore={data.length % 25 === 0}
          >
            {data.map((m) => {
              return (
                <div
                  onClick={() =>
                    navigate(`/profile/proximity-managers/${m?.id}`)
                  }
                  className="bg-fff row br-05 mb-1 pointer"
                  key={m?.id}
                  style={{ maxHeight: "6rem", overflow: "hidden" }}
                >
                  <ProtectedImage
                    imageId={m.profilePicture?.id}
                    alt=""
                    style={{
                      borderRadius: ".5rem 0 0 .5rem",
                      width: "96px",
                      height: "96px",
                      objectFit: "cover",
                    }}
                  />
                  <div className="col p-1">
                    <div className="row mb-05 as-fs">
                      <img src={Executive24} alt="" />
                      <div className="bold ml-05">
                        {m.firstname} {m.lastname}
                      </div>
                    </div>
                    <div className="row mb-05 as-fs" style={{ width: 24 }}>
                      <img src={Phone24} alt="" />
                      <div className="gray  ml-05">{m.phone}</div>
                    </div>
                    <div className="row">
                      <img
                        src={getInstitutionIcon(m.institutions?.[0]?.type)}
                        alt=""
                        style={{ width: "24px" }}
                      />
                      <div className="gray ml-05">
                        {m.institutions
                          ?.map((institution) => institution.name)
                          .join(", ")}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </InfiniteScroll>
        </>
      )}
      <Button
        buttonProps={addManager}
        onClicked={() => navigate("/profile/proximity-managers/add")}
      />
    </div>
  );
}
