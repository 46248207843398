import React, { useContext } from 'react';
import BadgeInfo from '../../components/BadgeInfo';
import {
  Hospital24,
  Laboratory24,
  Clock24,
  Calendar24,
  GirlPlus24,
} from '../../assets/icons/icons';
import { ProtectedImage } from '../../components';
import { getBadgeInfoProps } from './badgeInfo';
import { useLocation } from 'react-router-dom';
import PointSystem from './PointSystem';
import Payments from './Payments';
import { UserContext } from '../../components/context/UserContext';
import Formatter from '../../components/utils/Formatter';

type Props = {
  mission: any;
  onClicked?: () => {};
};

function MissionCard({ mission, onClicked }: Props) {
  const location = useLocation();
  const { isRh } = useContext(UserContext);

  return (
    <div
      className="missionCard shadowEffect br-05"
      style={{
        cursor: onClicked ? 'pointer' : 'default',
      }}
      onClick={() => onClicked?.()}
    >
      <div className="blue">
        <ProtectedImage
          imageId={mission.medias?.[0]?.id}
          alt="mission"
          className="missionCard-img"
          style={{
            width: '283px',
            height: '161px',
            maxWidth: 'none',
            borderRadius: '.5rem 0 0 .5rem',
          }}
        />
        <div className="missionCard-body br-05">
          <div className="col">
            <div className="row jc-sb w-100">
              <div className="col">
                <div className="row aic mb-025">
                  <img
                    src={mission.isHospital ? Hospital24 : Laboratory24}
                    alt=""
                    style={{ maxWidth: '2rem' }}
                    className="mr-025"
                  />
                  <span className="semibold" style={{ fontSize: '20px' }}>
                    {Formatter.formatMissionTitle(mission.name)}
                  </span>
                  {/*                   {mission.status !== "draft" &&
                    mission.status !== "refused" && (
                      <div className="greyFlee semibold ml-1">
                        {mission.contractType === ContractType.FullTime
                          ? "V"
                          : "I"}
                      </div>
                    )} */}
                </div>
                <div className="row as-fs">
                  <img src={mission.service?.icon} alt="" className="mr-025" />
                  <span
                    className="text semibold as-fs"
                    style={{ fontSize: '16px' }}
                  >
                    {mission.service?.label} ({mission.servicePrecision})
                  </span>
                </div>
              </div>

              <div className="ml-a">
                <BadgeInfo
                  badgeInfoProps={getBadgeInfoProps(mission, location.pathname)}
                />
              </div>
            </div>
          </div>
          <div className="row" style={{ columnGap: '86px' }}>
            <div>
              <div className="row">
                <img src={Calendar24} alt="" className="grey-icon" />
                <span className="gray mr-a ml-05">Dates</span>
              </div>
              <span className="semibold">{mission.dateRange}</span>
            </div>

            <div>
              <div className="row">
                <img src={Clock24} alt="" className="grey-icon" />
                <span className="gray ml-05 mr-a">Horaires</span>
              </div>
              <span className="semibold">{mission.hourRange}</span>
            </div>

            <div>
              <div className="row">
                <img src={GirlPlus24} alt="" className="grey-icon" />
                {mission.status === 'draft' ? (
                  <span className="text gray ml-05 mr-a">
                    Candidat recherché
                  </span>
                ) : (
                  <span className="text gray ml-05">
                    Intérimaire(s) missionné(s)
                  </span>
                )}
              </div>
              <span className="semibold">{mission.occupationLabel}</span>
            </div>
          </div>
        </div>
      </div>
      {(location.pathname.includes('present/') ||
        location.pathname.includes('past/')) && (
        <div className="missionCard-infos p-1 bg-fff">
          <PointSystem mission={mission} />
        </div>
      )}
      {location.pathname.endsWith('past') && isRh() && (
        <div className="missionCard-infos p-1 bg-fff">
          <Payments mission={mission} />
        </div>
      )}
    </div>
  );
}

export default MissionCard;
