import React from "react";

type Props = {
  value: string;
  placeholder?: string;
  onChange: (value: string | null) => void;
  style?: React.CSSProperties;
};

const CircleInput = ({ value, placeholder, onChange, style }: Props) => (
  <input
    type="number"
    style={{
      textAlign: "center",
      borderRadius: "26px",
      width: "48px",
      height: "48px",
      padding: "8px",
      fontSize: '16px',
      color: '#000',
      ...style,
    }}
    onChange={(e) => onChange(e.target.value)}
    value={value}
    placeholder={placeholder}
  />
);

export default CircleInput;
