import React from "react";
import ApplicantInfo from "./ApplicantInfo";
import { Calendar24, Pay, Waves, Bill } from "../assets/icons/icons";

export default function PayInfo({ applicant, hourlyRate }) {
  return (
    <ApplicantInfo applicant={applicant}>
      <>
        <div className="col ai-fs jc-fs flex-1">
          <div className="row ml-2 mb-a">
            <img src={Calendar24} alt="" className="mr-1" />
            <div className="bold nowrap">
              {applicant.askedDates.length} jour
              {applicant.askedDates.length > 1 ? "s" : ""} travaillé
              {applicant.askedDates.length > 1 ? "s" : ""}
            </div>
          </div>
          <div className="row mb-a">
            <div className="ml-2 nowrap">
              Soit {applicant.askedDates.length * 8} heures
            </div>
          </div>
        </div>
        <div className="col flex-1">
          <div className="row">
            <img src={Bill} alt="" className="mr-1" />
            <div className="bold black">{Number(hourlyRate)}€/h</div>
          </div>
        </div>
        <div className="row flex-1 nowrap">
          <img src={Pay} alt="" />
          <img src={Waves} alt="" />
          <div className="pink" style={{ fontSize: "2rem" }}>
            {applicant.askedDates.length * 8 * Number(hourlyRate)}€
          </div>
        </div>
      </>
    </ApplicantInfo>
  );
}
