import { useRequest } from '..';
import { ContractType } from '../enums';
import { getWithAuth, patchWithAuth, postWithAuth } from '../requests';
import { occupations, services } from '../../data/occupations';
import { Selection } from '../../components/MissionDetails';
import { asDate, asHour, getDatesBetween } from '../format';
import moment from 'moment-timezone';

const useGetWelcomeBoardsForBoard = () => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}welcome_board?archived=false`,
    {},
    (raw: any) => raw
  );
};

const mapper = (raw: any) => {
  const { institution } = raw;
  const type = institution?.type ?? null;
  const isHospital = type === 'hospital';
  const occupation =
    occupations.find((occupation) => occupation.key === raw.occupation) ?? null;
  const service =
    Object.entries(services).find(
      ([_, service]) => service.key === raw.service
    )?.[1] ?? null;

  const startAt = moment(raw.startAt);
  return {
    ...raw,
    occupation: occupation,
    occupationLabel: occupation?.label,
    service: service,
    serviceLabel: service?.label,
    dateRange: `Du ${asDate(raw.startAt)} au ${asDate(raw.endAt)}`,
    startAtLabel: asDate(raw.startAt),
    hourRange: `${asHour(raw.startAt)} - ${asHour(raw.endAt)}`,
    institution: institution?.name,
    address: institution?.address?.label,
    isHospital: isHospital,
    medias: raw.photos,
    status: raw.welcomeBoardState,
    staffs: raw.staffs?.map((staff: any) => {
      return {
        number: staff.number,
        occupation:
          occupations.find((occupation) => occupation.key === staff.occupation)
            ?.label ?? '',
      };
    }),
    capacitationLabels: raw.capacitations?.map((capacitation: any) => {
      return (
        service?.capacitations.find(
          (localCapacitation) => localCapacitation.key === capacitation
        )?.label ?? ''
      );
    }),
    capacitations: raw.capacitations
      ?.map((capacitation: any) => {
        return (
          service?.capacitations.find(
            (localCapacitation) => localCapacitation.key === capacitation
          ) ?? null
        );
      })
      .filter((capacitation) => capacitation !== null),
    contractTypeLabel:
      raw.contractType === ContractType.FullTime ? 'intérimaire' : 'vacataire',
    datesBetween: getDatesBetween(raw.startAt, raw.endAt),
    hourlyRate: Number(raw.hourlyRate),
    welcomeBoardDatesLabels: raw.welcomeBoardDates?.map((item) => {
      return {
        ...item,
        dateLabel: moment(item.date).format('DD/MM/YYYY'),
        dateLightFormat: moment(item.date).format('YYYY-MM-DD'),
      };
    }),
    datesList: raw.welcomeBoardDates?.map((item) => {
      return moment(item.date).format('DD/MM/YYYY');
    }),
    todayStartAt: moment()
      .set({
        hour: startAt.hour(),
        minute: startAt.minute(),
        second: 0,
        millisecond: 0,
      })
      .toISOString(),
  };
};

const useGetWelcomeBoardById = (
  id: string | null,
  validate?: boolean,
  setValidate?: (validate: boolean) => void
) => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}welcome_board/${id}`,
    getWithAuth(),
    (raw: any) => mapper(raw),
    validate !== false && id !== null,
    setValidate
  );
};

const useGetWelcomeBoardsByInstitution = (
  page: number,
  state: string,
  institutionId: string,
  contractType: ContractType | null,
  validate: boolean,
  setValidate: (validate: boolean) => void,
  limit: number = 25,
  completed: boolean | undefined = undefined
) => {
  return useRequest(
    `${
      process.env.REACT_APP_API_BASE_URL
    }welcome_board?page=${page}&state=${state}&institution=${institutionId}${
      state === 'planned'
        ? contractType
          ? `&contract=${contractType}`
          : ''
        : ''
    }&limit=${limit}${
      completed !== undefined ? `&completed=${completed}` : ''
    }&archived=false`,
    getWithAuth(),
    (raw: any) => {
      return raw.map((item: any) => {
        return mapper(item);
      });
    },
    validate,
    setValidate
  );
};

const useGetWelcomeBoardsCountByInstitution = (
  institutionId: string,
  contractType: ContractType | null,
  validate: boolean,
  completed: boolean | undefined
) => {
  return useRequest(
    `${
      process.env.REACT_APP_API_BASE_URL
    }welcome_board/count?institution=${institutionId}&contract=${contractType}${
      completed !== undefined ? `&completed=${completed}` : ''
    }&archived=false`,
    getWithAuth(),
    (raw: any) => raw,
    validate
  );
};

const usePatchWelcomeBoardForValidation = (
  id: String,
  selection: Selection,
  comment: string,
  validate: boolean
) => {
  const body =
    selection === Selection.REFUSE
      ? {
          refusalComment: comment,
        }
      : {
          contractType:
            selection === Selection.VALIDATE_INTERIM
              ? ContractType.FullTime
              : ContractType.PartTime,
        };
  const serializedBody = JSON.stringify(body);
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}welcome_board/${id}`,
    patchWithAuth(serializedBody),
    (raw: any) => raw,
    validate
  );
};

const usePatchWelcomeBoardApplications = (
  id: string,
  applicationDates: string[],
  validate: boolean,
  setValidate: (validate: boolean) => void
) => {
  const body = {
    applicationDates,
  };
  const serializedBody = JSON.stringify(body);

  // console.log({ serializedBody });
  // // return;
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}welcome_board/${id}/application`,
    patchWithAuth(serializedBody),
    (raw: any) => raw,
    validate,
    setValidate
  );
};

const usePostAddApplicant = (
  welcomeBoardId: string,
  startAt: string,
  applicantId: string,
  applicationDates: string[],
  validate: boolean,
  setValidate: (validate: boolean) => void
) => {
  const startUTC2 = moment.tz(startAt, 'Europe/Paris');

  const body = {
    welcomeBoard: welcomeBoardId,
    candidate: applicantId,
    askedDates: applicationDates.map((str) => {
      const askedDateUTC2 = moment
      .tz(str, 'Europe/Paris')
      // .utcOffset(2)
      .set({
        y: moment(str).year(),
        M: moment(str).month(),
        date: moment(str).date(),
        h: startUTC2.hour(),
        m: startUTC2.minute(),
        s: startUTC2.clone().add(1, 's').second(),
      })
      .format()
      return { date: askedDateUTC2 };
    }),
  };
  const serializedBody = JSON.stringify(body);

  // console.log(body.askedDates);
  // return;
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}application/rh`,
    postWithAuth(serializedBody),
    (raw: any) => raw,
    validate,
    setValidate
  );
};

export {
  useGetWelcomeBoardsForBoard,
  useGetWelcomeBoardsByInstitution,
  useGetWelcomeBoardById,
  usePatchWelcomeBoardForValidation,
  useGetWelcomeBoardsCountByInstitution,
  usePatchWelcomeBoardApplications,
  usePostAddApplicant,
};
