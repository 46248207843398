import React, { useState, useEffect } from "react";
import Background from "../../components/Background";
import { usePostSendPassword } from "../../api/user";
import { PopupInfo } from "../../components/PopupInfo";
import { PopupSendPassword } from "../../components/popup/popupsProps";
import { useNavigate, useParams } from "react-router-dom";
import { ManualTextError } from "../../components";
import { TkCare } from "../../assets/logo/logo";
import { Formik, Form } from "formik";
import { FormInput } from "../../components/FormInput";
import { password, confirmPassword } from "../profile/institutions/inputs";
import { TextError } from "../../components";
import {
  validate as buttonValidate,
  Button,
} from "../../components/buttons/buttons";

function SendPassword() {
  const { token } = useParams();
  const [validate, setValidate] = useState(false);
  const [form, setForm] = useState(null);
  const { data, loading, error } = usePostSendPassword(
    token ?? null,
    form?.password ?? "",
    validate,
    setValidate
  );
  const [popupVisibility, setPopupVisibility] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setPopupVisibility(true);
    }
  }, [data]);

  const redirect = () => {
    navigate("/login");
  };

  return (
    <Background>
      <div className="login">
        <div className="login-left">
          <div style={{ marginLeft: "2rem", marginTop: "2rem" }}>
            <img src={TkCare} alt="" style={{ height: "63px" }} />
          </div>
        </div>
        <div className="login-right">
          <div style={{ padding: "2rem" }}>
            <h1 className="h0 mb-2">Bienvenue sur TkCare ! 🎉</h1>
            <div className="mb-2 h2">
              Créer des missions et trouver des candidats compétents rapidement
              et simplement !
            </div>
            <div className="mb-2 title">Mot de passe oublié 🔐</div>
            <div className="mb-2">
              Veuillez renseigner votre nouveau mot de passe
            </div>
            <Formik
              initialValues={{ password: "", confirmationPassword: "" }}
              //validationSchema={}
              onSubmit={(values) => {
                setForm(values);
                setTimeout(() => setValidate(true));
              }}
            >
              {({ values, setFieldValue, setFieldTouched, errors }) => {
                const onChange = (key, value) => {
                  setFieldValue(key, value);
                  setTimeout(() => setFieldTouched(key, true));
                };

                return (
                  <Form>
                    <FormInput
                      inputProps={password}
                      value={values.password}
                      setValue={(value) => onChange("password", value)}
                    />
                    <FormInput
                      inputProps={confirmPassword}
                      value={values.confirmationPassword}
                      setValue={(value) =>
                        onChange("confirmationPassword", value)
                      }
                    />
                    <TextError name="email" />
                    <div className="mt-3">
                      <Button
                        buttonProps={buttonValidate}
                        disabled={
                          loading ||
                          values.password.length === 0 ||
                          values.confirmationPassword.length === 0
                        }
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
            {error && <ManualTextError message={error} />}
          </div>
        </div>
      </div>
      {popupVisibility && (
        <PopupInfo
          popupProps={PopupSendPassword}
          onClosed={() => redirect()}
          onValidated={() => redirect()}
        />
      )}
    </Background>
  );
}

export default SendPassword;
