import React from "react";

type Props = {
  children: React.ReactNode;
};

export default function SubMenuWrapper({ children }: Props) {
  return (
    <div
      className="ml-2 flex-2 pl-2"
      style={{ borderLeft: "1px solid lightgray" }}
    >
      {children}
    </div>
  );
}
