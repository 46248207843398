import React from "react";
import { Block } from "./Block";
import { Photos } from "../../assets/icons/icons";
import { UploadImage } from "../../components";

type UploadImageProps = {
  imageId: string | null;
  imageUrl: string;
  setImageUrl: (value: string) => void;
};

type Props = {
  firstUploadImageProps: UploadImageProps;
  secondUploadImageProps: UploadImageProps;
};

const UploadImagesBlock = ({
  firstUploadImageProps,
  secondUploadImageProps,
}: Props) => (
  <Block icon={Photos} label="Photos de l’établissement" alt="photos">
    <div className="row">
      <UploadImage
        imageId={firstUploadImageProps.imageId}
        imageUrl={firstUploadImageProps.imageUrl}
        setImageUrl={firstUploadImageProps.setImageUrl}
      />
      <UploadImage
        imageId={secondUploadImageProps.imageId}
        imageUrl={secondUploadImageProps.imageUrl}
        setImageUrl={secondUploadImageProps.setImageUrl}
        className="ml-1"
      />
    </div>
  </Block>
);

export default UploadImagesBlock;
