import React from "react";
import { useNavigate } from "react-router-dom";
import Popup from "./Popup";
import PlusImg from "../assets/creation/plus.png";
import CheckImg from "../assets/icons/check/check.png";
import CongratsImg from "../assets/creation/congrats.png";
import { ApplicationDateState } from "../api/enums";

export function PopUpPublishMission({ clear }) {
  const navigate = useNavigate();
  return (
    <Popup
      title="Félicitations ! 🎉"
      image={
        <img
          src={CongratsImg}
          alt=""
          style={{
            borderRadius: ".5rem",
            marginBottom: "0 auto",
            width: "568px",
            height: "260px",
          }}
        />
      }
      question={
        <div
          style={{
            fontSize: "1.25rem",
            marginBottom: ".5rem",
            marginTop: "1rem",
          }}
        >
          Votre mission est créée et va être publiée auprès des intérimaires.
        </div>
      }
      className="popup"
      dismiss={() => navigate("/board")}
      button={
        <>
          <button
            className="btn-main mr-1 pink mw-fc"
            style={{
              backgroundColor: "#fef8fa",
              border: "1px solid #ee7a9b",
              color: "#ee7a9b",
            }}
            onClick={() => {
              clear();
              navigate("/missions/new/step-one");
            }}
          >
            <div>Créer une autre mission</div>
            <img src={PlusImg} alt="" className="ml-1" />
          </button>
          <button
            onClick={() => navigate("/recruitments/validation")}
            className="btn-main"
          >
            Suivre mes missions
            <img
              src={CheckImg}
              alt="check"
              style={{
                width: "1.75rem",
                marginLeft: "1rem",
              }}
            />
          </button>
        </>
      }
      centered
      bgColor="#221b43"
      color="white"
    />
  );
}
