import React, { useContext } from "react";
import { UserContext } from "./context/UserContext";
import UploadAvatar from "../pages/profile/UploadAvatar";
import { useGetCustomer } from "../api/customer";
import { FormInput } from "./FormInput";
import { inputs } from "../pages/profile/institutions/inputs";
import Title from "../pages/profile/Title";
import Subtitle from "../pages/profile/Subtitle";
import SubMenuWrapper from "../pages/profile/SubMenuWrapper";

export default function ProfileAccount() {
  const { user, getStatus, isMp } = useContext(UserContext);
  const { data } = useGetCustomer(user?.rhManager?.id ?? null);

  return (
    user && (
      <SubMenuWrapper>
        <Title label="Votre compte ‍💻" />
        <Subtitle label="Votre photo de profil" />
        <UploadAvatar />
        <Subtitle label="Vos informations générales" />
        <div className="row">
          <div className="flex-1">
            <FormInput
              inputProps={inputs.firstname}
              value={user.firstname}
              disabled={true}
            />
          </div>
          <div className="flex-1 ml-175">
            <FormInput
              inputProps={inputs.lastname}
              value={user.lastname}
              disabled={true}
            />
          </div>
        </div>
        <Subtitle label="Vos informations de poste" />
        <FormInput
          inputProps={inputs.status}
          value={getStatus()}
          disabled={true}
        />
        {isMp && data && (
          <>
            <FormInput
              inputProps={inputs.manager}
              value={data.fullname}
              disabled={true}
            />
          </>
        )}
        <Subtitle label="Vos coordonnées" />
        <FormInput
          inputProps={inputs.email}
          value={user.email}
          disabled={true}
        />
        <FormInput
          inputProps={inputs.phone}
          value={user.phone}
          disabled={true}
        />
      </SubMenuWrapper>
    )
  );
}
