import React, { useContext, useState } from "react";
import Navbar from "../components/Navbar";
import StrengthIconCorrespondances from "../components/StrengthIconCorrespondances";
import { useNavigate, useParams } from "react-router-dom";
import {
  Portrait24,
  Briefcase24,
  Capacitations24,
  Calendar24,
  Email24,
  Diploma24,
  Phone24,
  Location24,
  Birthday24,
  Strong,
} from "../assets/icons/icons";
import { ProtectedImage } from "../components";
import { MediaFormat } from "../data/medias";
import { capacitations } from "../data/occupations";
import { ApplicantContext } from "../components/context/ApplicantContext";
import { selectApplicant, Button } from "../components/buttons/buttons";
import { UserContext } from "../components/context/UserContext";
import { usePatchWelcomeBoardApplications } from "../api/welcomeboards";

export default function ApplicantFull() {
  const { applicantId } = useParams();
  const navigate = useNavigate();
  const {
    applicants,
    selectedCandidates,
    addOrRemoveCandidate,
    getAvailableDates,
    mission,
  } = useContext(ApplicantContext);
  const applicant = applicants?.find(
    (applicant) => applicant.id === applicantId
  );
  const candidate = applicant?.candidate;
  const selectedCandidate = selectedCandidates?.find(
    (selectedCandidate) => selectedCandidate.id === applicantId
  );
  const selectedDates = selectedCandidate?.selectedDates ?? getAvailableDates();
  const { isRh } = useContext(UserContext);
  const [validate, setValidate] = useState(false);
  const { _ } = usePatchWelcomeBoardApplications(
    mission?.id,
    selectedCandidates
      ?.map((selectedCandidate) =>
        selectedCandidate.selectedDates.map((selectedDate) => selectedDate.id)
      )
      .reduce((a, b) => a.concat(b), []),
    validate,
    setValidate
  );

  return (
    applicant && (
      <div className="applicant-full">
        <Navbar />
        <div className="view">
          <div
            className="mt-3 title as-fs pointer"
            onClick={() => navigate(-1)}
          >
            {"<"} Retour aux intérimaires
          </div>
          {/* </Link> */}
          <div className="row mt-2">
            <ProtectedImage
              imageId={candidate.profilePicture?.id}
              alt="candidate picture"
              format={MediaFormat.PROFILE}
              className="image"
              style={{
                borderRadius: ".5rem",
                width: "130px",
                height: "130px",
                objectFit: "cover",
              }}
            />

            <div
              className="col"
              style={{ height: "130px", padding: ".5rem", gap: ".5rem" }}
            >
              <div className="row mb-05 as-fs fs-125 semibold">
                <img src={Portrait24} alt="profile" className="mr-05" />
                {candidate.fullname}
              </div>
              <div className="row">
                <img src={Briefcase24} alt="profile" className="mr-05" />
                <div className="">{candidate.occupationLabel}</div>
              </div>
              <div className="row">
                <img src={Capacitations24} alt="profile" className="mr-05" />
                <div className="">{candidate.serviceLabels.join(" - ")}</div>
              </div>
            </div>
          </div>
          <div className="mb-2 mt-2 h2 as-fs semibold">
            Informations générales
          </div>
          <div className="row ai-fs">
            <div className="col mr-3">
              <div className="row gray mb-05 as-fs">
                <img
                  src={Briefcase24}
                  alt="profile"
                  className="grey-icon mr-05"
                />
                <span>Profession</span>
              </div>
              <span className="row semibold">{candidate.occupationLabel}</span>
            </div>
            <div className="col flex-1 ai-fs">
              <div className="row gray mb-05 as-fs">
                <img
                  src={Capacitations24}
                  alt="profile"
                  className="mr-05 grey-icon"
                />
                <div>Habilitations</div>
              </div>
              <div className="row semibold">
                {Object.keys(applicant.countOpinions.substituteCapacitation)
                  ?.length > 0 ? (
                  Object.entries(
                    applicant.candidate.countOpinions.substituteCapacitation
                  ).map((substituteCapacitation, key) => (
                    <span key={key}>
                      <span className="underlined">
                        {
                          Object.entries(capacitations).find((capacitation) => {
                            return (
                              capacitation[1].key === substituteCapacitation[0]
                            );
                          })?.[1].label
                        }{" "}
                        ({substituteCapacitation[1]})
                      </span>
                      &nbsp;&nbsp;
                    </span>
                  ))
                ) : (
                  <span>&emsp;&emsp;{"    -"}</span>
                )}
              </div>
            </div>
            {false && (
              <div className="col mr-3">
                <div className="row gray mb-05 as-fs">
                  <img
                    src={Diploma24}
                    alt="profile"
                    className="grey-icon mr-05"
                  />
                  <span>Diplômes</span>
                </div>
                <span className="row semibold"></span>
              </div>
            )}
            {false && (
              <div className="col">
                <div className="row gray mb-05 as-fs">
                  <img
                    src={Capacitations24}
                    alt="profile"
                    className="grey-icon mr-05"
                  />
                  <span>Habilitations</span>
                </div>
                <div className="row semibold">
                  {[].map((habilitation) => (
                    <span>
                      <span className="underlined semibold">
                        {habilitation}
                      </span>
                      &nbsp;&nbsp;
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="column">
            <div className="row mt-1 mb-05 gray">
              <img src={Calendar24} alt="" className="grey-icon mr-05" />
              <span>Date(s) mission</span>
            </div>
            <span className="row semibold">
              {applicant.askedDatesLabels.slice(0, 4).map((availability, i) => (
                <span key={i} className="mr-05 semibold">
                  <span
                    style={{
                      textDecoration:
                        !(
                          selectedCandidate?.selectedDates ??
                          getAvailableDates()
                        ).includes(availability) &&
                        !selectedDates.includes(availability.date)
                          ? "line-through"
                          : "none",
                    }}
                  >
                    {availability.date}
                  </span>
                </span>
              ))}
            </span>
            <div
              className="col"
              style={{
                justifyContent: "flex-start",
                borderBottom: "1px solid lightgray",
                paddingBottom: "3rem",
              }}
            >
              {Object.keys(applicant?.candidate?.countOpinions?.substituteMood)
                ?.length > 0 && (
                <>
                  <div className="row mt-1 mb-05 gray">
                    <img src={Strong} alt="" className="grey-icon mr-05" />
                    <span>Points forts</span>
                  </div>
                  <div className="row">
                    {Object.entries(
                      applicant.candidate.countOpinions.substituteMood
                    ).map((substituteMood, key) => {
                      return (
                        <StrengthIconCorrespondances
                          moodKey={substituteMood[0]}
                          key={key}
                        />
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
          {false && (
            <>
              <div className="mt-2 mr-a h2 semibold mb-2 ">
                Dernières missions effectuées
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "1.5rem",
                  borderBottom: "1px solid lightgray",
                }}
              ></div>
            </>
          )}
          {/* MORE INFOS */}
          <div className="mt-2 mr-a h2 semibold mb-1 ">
            Informations complémentaires
          </div>

          <div className="row mt-1 jc-sb ai-fs">
            <div className="col tal">
              <div className="row gray mb-05 as-fs">
                <img src={Email24} alt="" className="mr-05" />
                Adresse email
              </div>
              <span className="semibold gray">{candidate.email}</span>
            </div>
            <div className="col">
              <div className="row gray mb-05 as-fs">
                <img src={Phone24} alt="phone_icon" className="mr-05" />
                Numéro de téléphone
              </div>
              <span className="semibold gray as-fs">{candidate.phone}</span>
            </div>
            <div className="col">
              <div className="row gray mb-05 as-fs">
                <img src={Location24} alt="" className="mr-05" />
                Adresse postale
              </div>
              <span className="semibold gray" style={{ maxWidth: "400px" }}>
                {candidate.address?.label}
              </span>
            </div>
            <div className="col">
              <div className="row gray mb-05 as-fs">
                <img
                  src={Birthday24}
                  alt="localisation_icon"
                  className="mr-05"
                />
                Date de naissance
              </div>
              <span className="semibold gray as-fs">{candidate.birthdate}</span>
            </div>
          </div>
          {/* {isRh() && (
            <div style={{ width: "317px", marginTop: "88px" }}>
              <Button
                buttonProps={selectApplicant}
                alternate={selectedCandidate}
                onClicked={() => {
                  addOrRemoveCandidate(applicantId);
                  setValidate(true);
                }}
              />
            </div>
          )} */}
        </div>
      </div>
    )
  );
}
