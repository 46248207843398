import {
  Occupation as OccupationEnum,
  Service as ServiceEnum,
  Capacitation as CapacitationEnum,
} from "../api/enums";
import { SelectItem } from "./SelectItem";
import {
  AS,
  IADE,
  IBODE,
  IDE,
  Midwife,
  NurseryNurse,
  TechnicianCertified,
  TechnicianUncertified,
  Secretary,
  Laboratory24,
  Emergency,
  Event,
  ExtraHospital,
  LongTerm,
  MaternityNeonatology,
  MedicineSurgery,
  OperatingRoom,
  /*   Reception,
  HomeSamples, */
  Repatriation,
  Ppharm,
  Maniprx,
  Ap,
  Mes,
  Mkde,
} from "../assets/icons/icons";

type Capacitation = SelectItem;

type WithCapacitation = SelectItem & {
  capacitations: Capacitation[];
};

type Service = WithCapacitation;

type Occupation = SelectItem & {
  services: Service[];
  asStaffOnly?: boolean;
};

const capacitations = {
  secretariat: {
    key: CapacitationEnum.secretariat,
    label: "Secrétariat",
    icon: null,
  },
  dialysis: {
    key: CapacitationEnum.dialysis,
    label: "Dialyse",
    icon: null,
  },
  registration: {
    key: CapacitationEnum.registration,
    label: "Accueil/ Enregistrement dossier",
    icon: null,
  },
  preAnalyticalSampleSorting: {
    key: CapacitationEnum.preAnalyticalSampleSorting,
    label: "Tri et pré-analytique des échantillons",
    icon: null,
  },
  preAnalyticalSampleSortingHelp: {
    key: CapacitationEnum.preAnalyticalSampleSortingHelp,
    label: "Aide Tri et pré-analytique des échantillons",
    icon: null,
  },
  bloodSamples: {
    key: CapacitationEnum.bloodSamples,
    label: "Prélevement(s) sanguin(s)",
    icon: null,
  },
  pcrTests: {
    key: CapacitationEnum.pcrTests,
    label: "Tests PCR",
    icon: null,
  },
  patientCareIntensiveCare: {
    key: CapacitationEnum.patientCareIntensiveCare,
    label: "Prise en charge patient(s) en soins intensifs",
    icon: null,
  },
  careCoordination: {
    key: CapacitationEnum.careCoordination,
    label: "Coordination des soins",
    icon: null,
  },
  patientCareIot: {
    key: CapacitationEnum.patientCareIot,
    label: "Prise en charge de patient(es) IOT",
    icon: null,
  },
  patientCareDialysis: {
    key: CapacitationEnum.patientCareDialysis,
    label: "Prise en charge de patient(es) dialysé(es)",
    icon: null,
  },
  emergencyReceptionCoordination: {
    key: CapacitationEnum.emergencyReceptionCoordination,
    label: "Coordination accueil des urgences",
    icon: null,
  },
  lifeSavingEmergency: {
    key: CapacitationEnum.lifeSavingEmergency,
    label: "Gestion des soins d’urgence vitale",
    icon: null,
  },
  operatingRoomPreparation: {
    key: CapacitationEnum.operatingRoomPreparation,
    label: "Préparation bloc opératoire/TDM/echo",
    icon: null,
  },
  pediatricBloodSamples: {
    key: CapacitationEnum.pediatricBloodSamples,
    label: "Prélèvement(s) sanguin(s) pédiatrique",
    icon: null,
  },
  breastfeedingSupport: {
    key: CapacitationEnum.breastfeedingSupport,
    label: "Accompagnement allaitement",
    icon: null,
  },
  prematureIntensiveCare: {
    key: CapacitationEnum.prematureIntensiveCare,
    label: "Prise en charge nouveau-né prématuré en soins intensifs",
    icon: null,
  },
  operatingRoomInstrumentPreparation: {
    key: CapacitationEnum.operatingRoomInstrumentPreparation,
    label: "Préparation de l’instrumentation du bloc opératoire",
    icon: null,
  },
  carTour: {
    key: CapacitationEnum.carTour,
    label: "Tournée voiture",
    icon: null,
  },
  walkingTour: {
    key: CapacitationEnum.walkingTour,
    label: "Tournée à pied",
    icon: null,
  },
  bikeTour: {
    key: CapacitationEnum.bikeTour,
    label: "Tournée en 2 roues",
    icon: null,
  },
  parenteralNutritionManagement: {
    key: CapacitationEnum.parenteralNutritionManagement,
    label: "Gestion alimentation parentérale",
    icon: null,
  },
  dressCode: {
    key: CapacitationEnum.dressCode,
    label: "Code vestimentaire imposé",
    icon: null,
  },
  englishSpeaker: {
    key: CapacitationEnum.englishSpeaker,
    label: "Langue parlée: anglais exigé",
    icon: null,
  },
  analyticalPhaseManagement: {
    key: CapacitationEnum.analyticalPhaseManagement,
    label: "Gestion de la phase analytique",
    icon: null,
  },
};

const services = {
  laboratory: {
    key: ServiceEnum.Laboratory,
    label: "Laboratoire",
    icon: Laboratory24,
    capacitations: [
      capacitations.registration,
      capacitations.preAnalyticalSampleSorting,
      capacitations.preAnalyticalSampleSortingHelp,
      capacitations.bloodSamples,
      capacitations.pcrTests,
    ],
  },
  emergency: {
    key: ServiceEnum.Emergency,
    label: "Urgence",
    icon: Emergency,
    capacitations: [
      capacitations.patientCareIntensiveCare,
      capacitations.careCoordination,
      capacitations.patientCareIot,
      capacitations.patientCareDialysis,
      capacitations.emergencyReceptionCoordination,
      capacitations.lifeSavingEmergency,
    ],
  },
  reanimation: {
    key: ServiceEnum.Reanimation,
    label: "Réanimation/Soins intensifs",
    icon: Emergency,
    capacitations: [
      capacitations.patientCareIntensiveCare,
      capacitations.careCoordination,
      capacitations.patientCareIot,
      capacitations.patientCareDialysis,
      capacitations.emergencyReceptionCoordination,
      capacitations.lifeSavingEmergency,
    ],
  },
  medicineSurgery: {
    key: ServiceEnum.MedicineSurgery,
    label: "Médecine, Chirurgie",
    icon: MedicineSurgery,
    capacitations: [
      capacitations.bloodSamples,
      capacitations.careCoordination,
      capacitations.operatingRoomPreparation,
      capacitations.registration,
    ],
  },
  longTerm: {
    key: ServiceEnum.LongTerm,
    label: "Unités de soins longue durée (SSR,...)",
    icon: LongTerm,
    capacitations: [
      capacitations.bloodSamples,
      capacitations.careCoordination,
      capacitations.operatingRoomPreparation,
      capacitations.registration,
    ],
  },
  maternityNeonatology: {
    key: ServiceEnum.MaternityNeonatology,
    label: "Maternité/Néonatologie",
    icon: MaternityNeonatology,
    capacitations: [
      capacitations.pediatricBloodSamples,
      capacitations.careCoordination,
      capacitations.operatingRoomPreparation,
      capacitations.registration,
      capacitations.breastfeedingSupport,
      capacitations.prematureIntensiveCare,
    ],
  },
  operatingRoom: {
    key: ServiceEnum.OperatingRoom,
    label: "Bloc opératoire/SSPI",
    icon: OperatingRoom,
    capacitations: [
      capacitations.careCoordination,
      capacitations.operatingRoomInstrumentPreparation,
    ],
  },
  extraHospital: {
    key: ServiceEnum.ExtraHospital,
    label: "Extra-Hospitalier",
    icon: ExtraHospital,
    capacitations: [
      capacitations.careCoordination,
      capacitations.carTour,
      capacitations.walkingTour,
      capacitations.bikeTour,
      capacitations.prematureIntensiveCare,
      capacitations.patientCareIot,
      capacitations.patientCareDialysis,
      capacitations.lifeSavingEmergency,
      capacitations.parenteralNutritionManagement,
    ],
  },
  event: {
    key: ServiceEnum.Event,
    label: "Evènementiel",
    icon: Event,
    capacitations: [
      capacitations.pcrTests,
      capacitations.dressCode,
      capacitations.bloodSamples,
      capacitations.englishSpeaker,
    ],
  },
  repatriation: {
    key: ServiceEnum.Repatriation,
    label: "Rapatriement",
    icon: Repatriation,
    capacitations: [
      capacitations.careCoordination,
      capacitations.patientCareIntensiveCare,
      capacitations.patientCareIot,
      capacitations.lifeSavingEmergency,
      capacitations.parenteralNutritionManagement,
    ],
  },
  technicalPlatform: {
    key: ServiceEnum.TechnicalPlatform,
    label: "Plateau technique",
    icon: null,
    capacitations: [
      capacitations.preAnalyticalSampleSortingHelp,
      capacitations.preAnalyticalSampleSorting,
      capacitations.analyticalPhaseManagement,
      capacitations.bloodSamples,
    ],
  },

  pediatrics: {
    key: ServiceEnum.Pediatrics,
    label: "Pédiatrie",
    icon: null,
    capacitations: [
      capacitations.careCoordination,
      capacitations.carTour,
      capacitations.walkingTour,
      capacitations.bikeTour,
      capacitations.prematureIntensiveCare,
      capacitations.patientCareIot,
      capacitations.patientCareDialysis,
      capacitations.lifeSavingEmergency,
      capacitations.parenteralNutritionManagement,
      capacitations.preAnalyticalSampleSortingHelp,
      capacitations.preAnalyticalSampleSorting,
      capacitations.analyticalPhaseManagement,
      capacitations.bloodSamples,
    ],
  },

  egg: {
    key: ServiceEnum.EGG,
    label: "EGG",
    icon: null,
    capacitations: [
      capacitations.careCoordination,
      capacitations.carTour,
      capacitations.walkingTour,
      capacitations.bikeTour,
      capacitations.prematureIntensiveCare,
      capacitations.patientCareIot,
      capacitations.patientCareDialysis,
      capacitations.lifeSavingEmergency,
      capacitations.parenteralNutritionManagement,
      capacitations.preAnalyticalSampleSortingHelp,
      capacitations.preAnalyticalSampleSorting,
      capacitations.analyticalPhaseManagement,
      capacitations.bloodSamples,
    ],
  },

  nuclearMedicine: {
    key: ServiceEnum.NuclearMedicine,
    label: "Médecine nucléaire",
    icon: null,
    capacitations: [
      capacitations.careCoordination,
      capacitations.carTour,
      capacitations.walkingTour,
      capacitations.bikeTour,
      capacitations.prematureIntensiveCare,
      capacitations.patientCareIot,
      capacitations.patientCareDialysis,
      capacitations.lifeSavingEmergency,
      capacitations.parenteralNutritionManagement,
      capacitations.preAnalyticalSampleSortingHelp,
      capacitations.preAnalyticalSampleSorting,
      capacitations.analyticalPhaseManagement,
      capacitations.bloodSamples,
    ],
  },

  radiotherapy: {
    key: ServiceEnum.Radiotherapy,
    label: "Radiothérapie",
    icon: null,
    capacitations: [
      capacitations.careCoordination,
      capacitations.carTour,
      capacitations.walkingTour,
      capacitations.bikeTour,
      capacitations.prematureIntensiveCare,
      capacitations.patientCareIot,
      capacitations.patientCareDialysis,
      capacitations.lifeSavingEmergency,
      capacitations.parenteralNutritionManagement,
      capacitations.preAnalyticalSampleSortingHelp,
      capacitations.preAnalyticalSampleSorting,
      capacitations.analyticalPhaseManagement,
      capacitations.bloodSamples,
    ],
  },

  irm: {
    key: ServiceEnum.IRM,
    label: "IRM",
    icon: null,
    capacitations: [
      capacitations.careCoordination,
      capacitations.carTour,
      capacitations.walkingTour,
      capacitations.bikeTour,
      capacitations.prematureIntensiveCare,
      capacitations.patientCareIot,
      capacitations.patientCareDialysis,
      capacitations.lifeSavingEmergency,
      capacitations.parenteralNutritionManagement,
      capacitations.preAnalyticalSampleSortingHelp,
      capacitations.preAnalyticalSampleSorting,
      capacitations.analyticalPhaseManagement,
      capacitations.bloodSamples,
    ],
  },

  scanner: {
    key: ServiceEnum.Scanner,
    label: "Scanner",
    icon: null,
    capacitations: [
      capacitations.careCoordination,
      capacitations.carTour,
      capacitations.walkingTour,
      capacitations.bikeTour,
      capacitations.prematureIntensiveCare,
      capacitations.patientCareIot,
      capacitations.patientCareDialysis,
      capacitations.lifeSavingEmergency,
      capacitations.parenteralNutritionManagement,
      capacitations.preAnalyticalSampleSortingHelp,
      capacitations.preAnalyticalSampleSorting,
      capacitations.analyticalPhaseManagement,
      capacitations.bloodSamples,
    ],
  },

  radiology: {
    key: ServiceEnum.Radiology,
    label: "Radiologie",
    icon: null,
    capacitations: [
      capacitations.careCoordination,
      capacitations.carTour,
      capacitations.walkingTour,
      capacitations.bikeTour,
      capacitations.prematureIntensiveCare,
      capacitations.patientCareIot,
      capacitations.patientCareDialysis,
      capacitations.lifeSavingEmergency,
      capacitations.parenteralNutritionManagement,
      capacitations.preAnalyticalSampleSortingHelp,
      capacitations.preAnalyticalSampleSorting,
      capacitations.analyticalPhaseManagement,
      capacitations.bloodSamples,
    ],
  },

  freeAgent: {
    key: ServiceEnum.FreeAgent,
    label: "Tous services",
    icon: null,
    capacitations: [
      capacitations.careCoordination,
      capacitations.carTour,
      capacitations.walkingTour,
      capacitations.bikeTour,
      capacitations.prematureIntensiveCare,
      capacitations.patientCareIot,
      capacitations.patientCareDialysis,
      capacitations.lifeSavingEmergency,
      capacitations.parenteralNutritionManagement,
      capacitations.preAnalyticalSampleSortingHelp,
      capacitations.preAnalyticalSampleSorting,
      capacitations.analyticalPhaseManagement,
      capacitations.bloodSamples,
    ],
  },
};

const occupations: Occupation[] = [
  {
    key: OccupationEnum.IDE,
    label: "IDE",
    services: [
      services.laboratory,
      services.emergency,
      services.reanimation,
      services.medicineSurgery,
      services.longTerm,
      services.maternityNeonatology,
      services.operatingRoom,
      services.extraHospital,
      services.event,
    ],
    icon: IDE,
  },
  {
    key: OccupationEnum.IADE,
    label: "IADE",
    services: [services.operatingRoom, services.extraHospital],
    icon: IADE,
  },
  {
    key: OccupationEnum.IBODE,
    label: "IBODE",
    services: [services.operatingRoom, services.extraHospital],
    icon: IBODE,
  },
  {
    key: OccupationEnum.AS,
    label: "AS",
    services: [
      services.laboratory,
      services.operatingRoom,
      services.emergency,
      services.reanimation,
      services.repatriation,
      services.medicineSurgery,
      services.maternityNeonatology,
      services.extraHospital,
      services.event,
    ],
    icon: AS,
  },
  {
    key: OccupationEnum.TechnicianCertified,
    label: "Technicien certifié",
    services: [services.laboratory, services.event],
    icon: TechnicianCertified,
  },
  {
    key: OccupationEnum.TechnicianUncertified,
    label: "Technicien non certifié",
    services: [services.laboratory, services.event],
    icon: TechnicianUncertified,
  },
  {
    key: OccupationEnum.NurseryNurse,
    label: "Puéricultrice",
    services: [
      services.maternityNeonatology,
      services.operatingRoom,
      services.event,
    ],
    icon: NurseryNurse,
  },
  {
    key: OccupationEnum.Midwife,
    label: "Sage-femme",
    services: [
      services.laboratory,
      services.maternityNeonatology,
      services.operatingRoom,
      services.event,
    ],
    icon: Midwife,
  },
  {
    key: OccupationEnum.Secretary,
    label: "Secrétaire",
    services: [services.laboratory],
    icon: Secretary,
  },
  {
    key: OccupationEnum.Biologist,
    label: "Biologiste",
    services: [],
    icon: null,
    asStaffOnly: true,
  },
  {
    key: OccupationEnum.MKDE,
    label: "Masseur kinésithérapeute",
    services: [services.freeAgent, services.extraHospital],
    icon: Mkde,
  },
  {
    key: OccupationEnum.PPHARM,
    label: "Préparateur en pharmacie",
    services: [services.freeAgent, services.extraHospital],
    icon: Ppharm,
  },
  {
    key: OccupationEnum.MANIPRX,
    label: "Manipulateur en électroradiologie",
    services: [services.radiology, services.scanner, services.irm, services.radiotherapy, services.nuclearMedicine, services.operatingRoom, services.egg],
    icon: Maniprx,
  },
  {
    key: OccupationEnum.AP,
    label: "Auxiliaire de puériculture",
    services: [services.maternityNeonatology, services.pediatrics, services.extraHospital],
    icon: Ap,
  },
  {
    key: OccupationEnum.MES,
    label: "Moniteur éducateur spécialité",
    services: [services.freeAgent, services.extraHospital],
    icon: Mes,
  },
];

type Staff = {
  number: string | null;
  occupation: SelectItem | null;
};

export { occupations, services, Staff, capacitations, WithCapacitation };
