import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FormInput } from "../FormInput";
import { email } from "../../pages/profile/institutions/inputs";
import { TextError } from "../TextError/TextError";
import { Letter } from "../../assets/icons/icons";

const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .email("Veuillez renseigner un email valide")
    .required("Champ requis"),
});

export default function ForgottenPasswordForm({ loading, onValidated }) {
  return (
    <div>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={EmailSchema}
        onSubmit={(values) => {
          onValidated(values.email);
        }}
      >
        {({ values, setFieldValue, setFieldTouched, errors }) => {
          const onChange = (key, value) => {
            setFieldValue(key, value);
            setTimeout(() => setFieldTouched(key, true));
          };
          return (
            <Form>
              <FormInput
                inputProps={email}
                value={values.email}
                setValue={(value) => onChange("email", value)}
              />
              <TextError name="email" />
              <button
                type="submit"
                disabled={loading || !values.email || errors.email}
                className={
                  loading || !values.email || errors.email
                    ? "btn-invalid w-100 mt-3"
                    : "btn-main w-100 mt-3"
                }
              >
                <div className="fff">Envoyer le lien sur mon e-mail</div>
                <img src={Letter} alt="next" className="ml-1" />
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
