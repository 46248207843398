import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useGetNotifications } from "../../api/notifications";
import { NotificationContext } from "../../components/context/NotificationContext";
import Navbar from "../../components/Navbar";

export default function Notifications() {
  const { updateLastCheckDate } = useContext(NotificationContext);
  const [validateGetNotifications, setValidateGetNotifications] =
    useState(true);
  const [page, setPage] = useState(1);
  const { data: notifications } = useGetNotifications(
    false,
    validateGetNotifications,
    setValidateGetNotifications,
    page
  );

  useEffect(() => {
    if (notifications?.length > 0) {
      updateLastCheckDate(notifications[0].createdAt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  return (
    <>
      <Navbar />
      <div className="view">
        {notifications && (
          <InfiniteScroll
            dataLength={notifications.length}
            next={() => {
              setPage(page + 1);
              setValidateGetNotifications(true);
            }}
            hasMore={true}
            loader={""}
          >
            {notifications.map((notification: any, index: number) => (
              <div
                className="row jc-sb bg-fff p-1 br-05 w-100 shadowEffect mt-1"
                style={{
                  backgroundColor: "#fff",
                }}
                key={index}
              >
                <div className="col ">
                  <div className="as-fs bold">{notification.title}</div>
                  <div className="as-fs">{notification.message}</div>
                </div>
                <div className="gray ml-a" style={{ fontSize: "12px" }}>
                  {notification.updatedAtLabel}
                </div>
              </div>
            ))}
          </InfiniteScroll>
        )}
      </div>
      ;
    </>
  );
}
