enum SubscriptionState {
  Connected = "connected",
  Subscribed = "subscribed",
}

enum Occupation {
  IDE = "ide",
  IADE = "iade",
  IBODE = "ibode",
  AS = "as",
  TechnicianCertified = "technician_certified",
  TechnicianUncertified = "technician_uncertified",
  NurseryNurse = "nursery_nurse",
  Midwife = "midwife",
  Secretary = "secretary",
  Biologist = "biologist",
  MKDE = "mkde",
  PPHARM = "ppharm",
  MANIPRX = "maniprx",
  AP = "ap",
  MES = "mes",
}

enum Service {
  Laboratory = "laboratory",
  Emergency = "emergency",
  Reanimation = "reanimation",
  MedicineSurgery = "medicine_surgery",
  LongTerm = "long_term",
  MaternityNeonatology = "maternity_neonatology",
  OperatingRoom = "operating_room",
  ExtraHospital = "extra_hospital",
  Event = "event",
  Repatriation = "repatriation",
  TechnicalPlatform = "technical_platform",
  Pediatrics = "pediatrics",
  EGG = "eeg",
  NuclearMedicine = "nuclear_medicine",
  Radiotherapy = "radiotherapy",
  IRM = "irm",
  Scanner = "scanner",
  Radiology = "radiology",
  FreeAgent = "free_agent",
}

enum ScheduleType {
  SpareTime = "spare_time",
  MedicalAppointment = "medical_appointment",
  Holiday = "holiday",
  Other = "other",
  InContract = "in_contract",
}

enum WelcomeBoardState {
  Draft = "draft",
  Planned = "planned",
  Applied = "applied",
  Ongoing = "ongoing",
  Finished = "finished",
  Refused = "refused",
  Canceled = "canceled",
  Absent = "absent",
}

enum VaccataireCapacitation {
  Secretariat = "secretariat",
  Reception = "reception",
  BloodSamples = "blood_samples",
  PCRSamples = "pcr_samples",
  FileFilling = "file_filling",
}

enum MissionReportState {
  Opened = "opened", // Rapport ouvert
  Noted = "noted", // Note attribué
  Payed = "payed", // Paie annoncé
}

enum ApplicationState {
  Applied = "applied",
  OfferProposed = "offer_proposed",
  Planned = "planned",
  Ongoing = "ongoing",
  Canceled = "canceled",
  Absent = "absent",
  Finished = "finished",
  Refused = "refused",
  Blocked = "blocked",
}

enum ApplicationDateState {
  Pending = "pending",
  Accepted = "accepted",
  Refused = "refused",
  Finished = "finished",
  Canceled = "canceled",
  Ongoing = "ongoing",
  Blocked = "blocked",
}

enum Role {
  User = "ROLE_USER",
  AdminTK = "ROLE_ADMIN",
  AdminClient = "ROLE_AC",
  RH = "ROLE_RH",
  MP = "ROLE_MP",
  Vaccataire = "ROLE_VACCATAIRE",
}

enum VaccataireMood {
  Adaptability = "adaptability",
  Efficiency = "efficiency",
  Empathy = "empathy",
  TeamSpirit = "team_spirit",
  Punctuality = "punctuality",
  Reactivity = "reactivity",
}

enum InstitutionQuality {
  Reception = "reception",
  TopTeam = "top_team",
  Reliability = "reliability",
  Reactivity = "reactivity",
  Organization = "organization",
  Equipment = "equipment",
  Management = "management",
}

enum ContractType {
  PartTime = "part_time",
  FullTime = "full_time",
}

enum Capacitation {
  secretariat = "secretariat",
  dialysis = "dialysis",
  registration = "registration",
  preAnalyticalSampleSorting = "pre_analytical_sample_sorting",
  preAnalyticalSampleSortingHelp = "pre_analytical_sample_sorting_help",
  bloodSamples = "blood_samples",
  pcrTests = "pcr_tests",
  patientCareIntensiveCare = "patient_care_intensive_care",
  careCoordination = "care_coordination",
  patientCareIot = "patient_care_iot",
  patientCareDialysis = "patient_care_dialysis",
  emergencyReceptionCoordination = "emergency_reception_coordination",
  lifeSavingEmergency = "life_saving_emergency",
  operatingRoomPreparation = "operating_room_preparation",
  pediatricBloodSamples = "pediatric_blood_samples",
  breastfeedingSupport = "breastfeeding_support",
  prematureIntensiveCare = "premature_intensive_care",
  operatingRoomInstrumentPreparation = "operating_room_instrument_preparation",
  carTour = "car_tour",
  walkingTour = "walking_tour",
  bikeTour = "bike_tour",
  parenteralNutritionManagement = "parenteral_nutrition_management",
  dressCode = "dress_code",
  englishSpeaker = "english_speaker",
  analyticalPhaseManagement = "analytical_phase_management",
}

export {
  SubscriptionState,
  Occupation,
  Service,
  ScheduleType,
  WelcomeBoardState,
  VaccataireCapacitation,
  MissionReportState,
  ApplicationState,
  ApplicationDateState,
  Role,
  VaccataireMood,
  InstitutionQuality,
  ContractType,
  Capacitation,
};
