import { useRequest } from '..';
import { getWithAuth, patchWithAuth, postWithAuth } from '../requests';
import { toFullName, asDate } from '../format';
import { occupations, services } from '../../data/occupations';
import moment from 'moment';

const applicationMapper = (application: any) => {
  const now = moment();
  return {
    ...application,

    candidate: {
      ...application.candidate,
      fullname: toFullName(
        application.candidate.firstname,
        application.candidate.lastname
      ),
      occupationLabel:
        occupations.find(
          (occupation) => occupation.key === application.candidate.occupation
        )?.label ?? '',
      serviceLabels: application.candidate.services.map(
        (serviceKey: string) => {
          return (
            Object.entries(services).find(
              (service) => service[1].key === serviceKey
            )?.[1]?.label ?? ''
          );
        }
      ),
      birthdate: asDate(application.candidate.birthdate),
      substituteMoods: [],
      substituteCapacitations: [],
    },
    //askedDatesLabels: application.askedDates.map(
    //  (askedDate: any) =>
    //    `${asDay(askedDate.date).slice(0, 3)}. ${asDate(askedDate.date)}`
    //),
    askedDates: application.askedDates?.map((item) => {
      return { ...item, dateLabel: moment(item.date).format('DD/MM/YYYY') };
    }),
    askedDatesLabels: application.askedDates.map((askedDate: any) => {
      return {
        id: askedDate.id,
        date: asDate(askedDate.date),
        state: askedDate.state,
        welcomeBoardDateStateMapped: application,
      };
    }),
    isRefused: !application.askedDates
      .filter((askedDate: any) => moment(askedDate.date).isAfter(now))
      .some((askedDate: any) => askedDate.state !== 'refused'),
  };
};

const useGetApplicationById = (
  id: string,
  validate: boolean,
  setValidate: (validate: boolean) => void
) => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}application/${id}`,
    getWithAuth(),
    (raw: any) => {
      return applicationMapper(raw);
    },
    validate,
    setValidate
  );
};

const useGetApplicationsByWelcomeBoard = (
  welcomeBoardId: string | null,
  state: string | null = null,
  validate: boolean = false,
  setValidate: (validate: boolean) => void = (_: boolean) => {},
  withoutBlockedAskedDates: boolean = false
) => {
  return useRequest(
    `${
      process.env.REACT_APP_API_BASE_URL
    }application?welcome_board=${welcomeBoardId}&limit=200${
      state ? `&state=${state}` : ''
    }`,
    getWithAuth(),
    (raw: any) => {
      return raw
        .map((application) => {
          if (withoutBlockedAskedDates) {
            return {
              ...application,
              askedDates: application.askedDates.filter(
                (askedDate) => askedDate.state !== 'blocked'
              ),
            };
          } else {
            return application;
          }
        })
        .filter((application) => {
          if (withoutBlockedAskedDates) {
            return application.askedDates.length > 0;
          } else {
            return true;
          }
        })
        .map((application) => {
          return applicationMapper(application);
        });
    },
    validate,
    setValidate
  );
};

const usePatchApplicationDateWithDailyComment = (
  id: string,
  dailyComment: string,
  validate: boolean,
  setValidate: (validate: boolean) => void
) => {
  const body = {
    dailyComment,
  };
  const serializedBody = JSON.stringify(body);
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}application_date/${id}/daily_comment`,
    patchWithAuth(serializedBody),
    (raw: any) => raw,
    validate,
    setValidate
  );
};

const usePatchApplicationWithAbsenceComment = (
  id: string,
  absenceComment: string,
  validate: boolean,
  setValidate: (validate: boolean) => void
) => {
  const body = {
    absenceComment,
  };
  const serializedBody = JSON.stringify(body);
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}application/${id}/absence`,
    patchWithAuth(serializedBody),
    (raw: any) => raw,
    validate,
    setValidate
  );
};

const postApplicationNote = async (applicants: any[]): Promise<boolean> => {
  const requests = applicants.map((applicant) => {
    const body = {
      substituteMoods: applicant.candidate.substituteMoods.map(
        (mood: any) => mood.key
      ),
      substituteCapacitations: applicant.candidate.substituteCapacitations.map(
        (capacitation: any) => capacitation.key
      ),
    };
    const serializedBody = JSON.stringify(body);
    return fetch(
      `${process.env.REACT_APP_API_BASE_URL}application/${applicant.id}/note`,
      postWithAuth(serializedBody)
    );
  });
  const responses = await Promise.all(requests);
  const errors = responses.filter((response) => !response.ok);
  return errors.length === 0;
};

const patchApplicationPay = async (applicants: any[]): Promise<boolean> => {
  const requests = applicants.map((applicant) => {
    const body = {
      pay: applicant.candidate.pay ?? 0,
    };
    const serializedBody = JSON.stringify(body);
    return fetch(
      `${process.env.REACT_APP_API_BASE_URL}application/${applicant.id}/pay`,
      patchWithAuth(serializedBody)
    );
  });
  const responses = await Promise.all(requests);
  const errors = responses.filter((response) => !response.ok);
  return errors.length === 0;
};

const usePatchApplicationPay = (
  id: string,
  pay: string,
  validate: boolean,
  setValidate: (validate: boolean) => void
) => {
  const body = {
    pay,
  };
  const serializedBody = JSON.stringify(body);
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}application/${id}/pay`,
    patchWithAuth(serializedBody),
    (raw: any) => raw,
    validate,
    setValidate
  );
};

const usePatchApplicationHours = (
  id: string,
  arrivalDate: string,
  departureDate: string,
  arrivalHour: string | null,
  departureHour: string | null,
  validate: boolean,
  setValidate: (validate: boolean) => void
) => {
  const arrival = moment(arrivalDate, 'YYYY-MM-DD');
  if (arrivalHour) {
    arrival.set({
      hour: Number(arrivalHour.substring(0, 2)),
      minute: Number(arrivalHour.substring(3, 5)),
      second: 0,
      millisecond: 0,
    });
  }
  const departure = moment(departureDate, 'YYYY-MM-DD');
  if (departureHour) {
    departure.set({
      hour: Number(departureHour.substring(0, 2)),
      minute: Number(departureHour.substring(3, 5)),
      second: 0,
      millisecond: 0,
    });
  }
  const body = {
    customerCheckinDatetime: arrival.toISOString(),
    customerCheckoutDatetime: departure.toISOString(),
  };
  const serializedBody = JSON.stringify(body);
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}application_date/${id}/check`,
    patchWithAuth(serializedBody),
    (raw: any) => raw,
    validate,
    setValidate
  );
};

export {
  useGetApplicationById,
  useGetApplicationsByWelcomeBoard,
  usePatchApplicationDateWithDailyComment,
  usePatchApplicationWithAbsenceComment,
  postApplicationNote,
  patchApplicationPay,
  usePatchApplicationPay,
  usePatchApplicationHours,
};
