import * as Yup from 'yup';
import { requiredField } from '../../data/yup';

const firstFormSchema = Yup.object().shape({
  isHospital: Yup.boolean(),
  firstPicture: Yup.object().test(
    'oneOfRequired',
    'Veuillez importer au moins une photo',
    (value, testContext) => {
      return value.url || testContext.parent.secondPicture.url;
    }
  ),
  secondPicture: Yup.object().test(
    'oneOfRequired',
    'Veuillez importer au moins une photo',
    (value, testContext) => {
      return value.url || testContext.parent.firstPicture.url;
    }
  ),
  occupation: Yup.object().nullable().required(requiredField),
  service: Yup.object().nullable().required(requiredField),
  servicePrecision: Yup.string().required(requiredField),
  nbSubstitute: Yup.number()
    .min(1, 'Une personne minimum')
    .required(requiredField),
  bookmark: Yup.boolean(),
  bookmarkName: Yup.string().when('bookmark', {
    is: true,
    then: Yup.string().required(requiredField),
  }),
});

const secondFormSchema = Yup.object().shape({
  isHospital: Yup.boolean(),
  description: Yup.string().nullable().required(requiredField),
  capacitations: Yup.array().test(
    'capacitationsCompleted',
    requiredField,
    (capacitations) => {
      return capacitations.some((capacitation) => capacitation !== null);
    }
  ),
  startDate: Yup.string().nullable().required(requiredField),
  endDate: Yup.string().nullable().required(requiredField),
  startHour: Yup.string().required(requiredField),
  endHour: Yup.string().required(requiredField),
  hourlyRate: Yup.string().required(requiredField),
  staffs: Yup.array()
    .of(
      Yup.object().shape({
        number: Yup.string(),
        occupation: Yup.object().nullable(),
      })
    )
    .test('staffsCompleted', requiredField, (staffs) => {
      // TODO Check why number is undefined instead of empty string
      return staffs.some(
        (staff) =>
          staff.number !== '' &&
          staff.number !== undefined &&
          staff.occupation !== null
      );
    }),
  bed: Yup.string().when('isHospital', {
    is: true,
    then: Yup.string().required(requiredField),
  }),
  dailyRoutines: Yup.array()
    .of(
      Yup.object({
        startHour: Yup.string(),
        endHour: Yup.string(),
        dailyTask: Yup.object().nullable(),
      })
    )
    .when('isHospital', {
      is: true,
      // TODO Check why startHour and endHour are undefined instead of empty string
      then: Yup.array().test(
        'dailyRoutinesCompleted',
        requiredField,
        (dailyRoutines) => {
          return (
            !dailyRoutines.some(
              (dailyRoutine) =>
                dailyRoutine.startHour === '' ||
                dailyRoutine.startHour === undefined ||
                dailyRoutine.endHour === '' ||
                dailyRoutine.endHour === undefined ||
                dailyRoutine.dailyTask === null
            ) && dailyRoutines.length > 0
          );
        }
      ),
    }),
  searchJunior: Yup.boolean().test(
    'oneOfRequired',
    "Veuillez sélectionner au moins un niveau d'expérience",
    (value, testContext) => {
      return (
        value ||
        testContext.parent.searchAverage ||
        testContext.parent.searchSenior
      );
    }
  ),
});

export { firstFormSchema, secondFormSchema };
