import { SelectItem } from './SelectItem';

type SubstituteCapacitation = SelectItem;

type DailyRoutine = {
  startHour: string;
  endHour: string;
  dailyTask: SelectItem | null;
};

const substituteCapacitations: SubstituteCapacitation[] = [
  {
    key: 'toilet_beginning',
    label: 'Début des toilettes',
    icon: null,
  },
  {
    key: 'oral_or_written_transmissions',
    label: 'Transmissions écrites/ orales',
    icon: null,
  },
  {
    key: 'monitoring_and_medic_tour',
    label: 'Tour des médicaments/constantes',
    icon: null,
  },
  {
    key: 'toilet_hygiene_patient',
    label: 'Toilette/ hygiène du patient',
    icon: null,
  },
  {
    key: 'preoperative_preparation',
    label: 'Préparation pré opératoire',
    icon: null,
  },
  {
    key: 'bandages',
    label: 'Pansements',
    icon: null,
  },
  {
    key: 'staff_meeting',
    label: "Réunion d'équipe/ Staff",
    icon: null,
  },
  {
    key: 'post_operative_monitoring',
    label: 'Retour de bloc/ Surveillances post opératoire',
    icon: null,
  },
  {
    key: 'entry_reception',
    label: 'Accueil des entrées',
    icon: null,
  },
  {
    key: 'checking_emergency_equipment',
    label: "Vérification du matériel d'urgence",
    icon: null,
  },
];

export { DailyRoutine, substituteCapacitations };
