import React, { useContext, useEffect, useState } from 'react';
import DisplayChoice from '../../components/DisplayChoice';
import { TkCare } from '../../assets/logo/logo';
import CheckImg from '../../assets/icons/check/check.png';
import ProfilIconImg from '../../assets/icons/profilicon-dark/profilicon-dark.png';
import ArrowImg from '../../assets/icons/arrowDown/arrowdown.png';
import Applicant from '../../components/Applicant';
import { Diploma24 } from '../../assets/icons/icons';
import { useGetApplicationsByWelcomeBoard } from '../../api/applications';
import { useNavigate, useLocation } from 'react-router-dom';
import { PopupInfo } from '../../components/PopupInfo';
import {
  PopupAssignCandidateError,
  PopupAssignCandidatesCompleted,
  PopupAssignCandidatesUncompleted,
} from '../../components/popup/popupsProps';
import { ApplicantContext } from '../../components/context/ApplicantContext';
import { UserContext } from '../../components/context/UserContext';
import { usePatchWelcomeBoardApplications } from '../../api/welcomeboards';
import { ApplicationState, WelcomeBoardState } from '../../api/enums';
import { Row } from 'antd';
import { ApplicationDateState } from '../../api/enums';

type Props = {
  mission: any;
};

export default function Applicants({ mission }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const assign = location.pathname.includes('dispatch');
  const { data: applicants } = useGetApplicationsByWelcomeBoard(
    mission.id,
    [
      ApplicationState.Applied,
      ApplicationState.Planned,
      ApplicationState.Ongoing,
      ApplicationState.Refused,
      ApplicationState.OfferProposed,
    ].join(','),
    true,
    () => {},
    false
  );
  const [displayMode, setdisplayMode] = useState(1);
  const canSubmit = applicants?.length > 0;
  const { isRh } = useContext(UserContext);

  const {
    setApplicants,
    setMission,
    selectedCandidates,
    selectedInterim,
    applicationsInfos,
    addOrRemoveCandidate,
    addOrRemoveApplicationDates,
    getAvailableDates,
  } = useContext(ApplicantContext);

  const [validate, setValidate] = useState(false);

  const {
    data: patchResult,
    loading,
    error,
  } = usePatchWelcomeBoardApplications(
    mission?.id,
    Object.keys(applicationsInfos)
      ?.map((key) =>
        applicationsInfos[key].applicantsList?.map((item) => item.idAskedDate)
      )
      ?.reduce((a, b) => a.concat(b), []),
    validate,
    setValidate
  );
  useEffect(() => {
    if (applicants) {
      setApplicants(applicants);
      setMission(mission);
    }
    console.log({ applicants });
  }, [applicants]);

  const redirect = () => {
    if (error) {
      navigate(0);
    } else {
      navigate('/recruitments/dispatch');
    }
  };

  return (
    <>
      {selectedCandidates && applicants && (
        <div>
          <div className="row mt-2 mb-2">
            <div className="h2 bold validateMissionTitle">
              {assign ? applicants.length : selectedCandidates.length}{' '}
              {mission.contractTypeLabel}
              {(assign ? applicants.length : selectedCandidates.length) > 1
                ? 's'
                : ''}{' '}
              à venir pour cette mission
            </div>
            {/* <DisplayChoice
              passInfoToParent={(selectedIndex) => {
                setdisplayMode(selectedIndex);
              }}
            /> */}
          </div>
          {assign && (
            <>
              <div className="row mb-2 semibold" style={{ textAlign: 'left' }}>
                Choisissez un (ou plusieurs) {mission.contractTypeLabel}(s)
                avant le {mission.startAtLabel} afin que les candidats{' '}
                {mission.contractTypeLabel}s sélectionnés aient le temps de se
                préparer à la mission.
              </div>
              <div className="row mb-1" style={{ textAlign: 'left' }}>
                <img src={TkCare} className="logo-small" alt="logo" />
                <span className="bold">vous recommande</span>
              </div>
            </>
          )}
          {displayMode === 2 && (
            <div>
              <div className="row jc-sb mb-1 mt-1 pr-1 pl-1">
                <div className="bold d-flex flex-1 tal aic">
                  <img src={ProfilIconImg} alt="profile" className="mr-05" />
                  Prénom/Nom
                  <img src={ArrowImg} alt="profile" className="ml-05" />
                </div>
                <div className="gray tal d-flex flex-1 aic">
                  <img src={Diploma24} alt="profile" className="mr-05" />
                  Diplômes
                </div>
                <div className="gray tal d-flex flex-1 aic">
                  <img src={Diploma24} alt="profile" className="mr-05" />
                  Habilitations
                </div>
                <div className="gray tal d-flex flex-1 aic">
                  <img src={Diploma24} alt="profile" className="mr-05" />
                  Date(s) de mission
                </div>
              </div>
            </div>
          )}

          <Row gutter={[30, 10]}>
            {applicants
              .filter((applicant: any) =>
                assign
                  ? true
                  : selectedCandidates.find(
                      (selectedCandidate) =>
                        selectedCandidate.id === applicant.id
                    )
              )
              .map((applicant, i) => {
                const selectedCandidate = selectedCandidates.find(
                  (selectedCandidate) => selectedCandidate.id === applicant.id
                );
                return (
                  <Applicant
                    key={i}
                    applicant={applicant}
                    addOrRemoveApplicationDates={addOrRemoveApplicationDates}
                    setIsSelected={() => addOrRemoveCandidate(applicant.id)}
                    isSelected={selectedCandidate}
                    mission={mission}
                    selectedDates={
                      selectedCandidate?.selectedDates ?? getAvailableDates()
                    }
                    selectedInterim={selectedInterim}
                    assign={assign && isRh()}
                  />
                );
              })}
          </Row>

          {assign && isRh() && (
            <button
              className={canSubmit && !loading ? 'btn-main' : 'btn-invalid'}
              style={{
                width: 'fit-content',
                marginTop: '5rem',
              }}
              onClick={() => {
                if (canSubmit && !loading) {
                  setValidate(true);
                }
              }}
            >
              <img src={CheckImg} alt=""></img>
              <span style={{ marginLeft: '1rem' }}>Valider les candidats</span>
            </button>
          )}
          {(patchResult || error) && (
            <PopupInfo
              popupProps={
                patchResult
                  ? patchResult.completed
                    ? PopupAssignCandidatesCompleted
                    : PopupAssignCandidatesUncompleted
                  : {
                      ...PopupAssignCandidateError,
                      description: error,
                    }
              }
              onClosed={() => redirect()}
              onValidated={() => redirect()}
            />
          )}
        </div>
      )}
    </>
  );
}
