import React from "react";
import {
  Laboratory24,
  Hospital24,
  Rocket24,
  Calendar24,
  Clock24,
  ArrowDown,
} from "../assets/icons/icons";

export default function MissionSmallHeader({ isHospital, isBookmark }) {
  return (
    <div className="row jc-sb mb-1 pl-1 pr-1">
      <div className="row bold" style={{ width: "36%", fontSize: "14px" }}>
        <img
          src={isHospital ? Hospital24 : Laboratory24}
          alt="lab"
          className="mr-05"
          style={{ width: "24px" }}
        />
        {isBookmark ? "Nom" : "Etablissement"}
        <img src={ArrowDown} alt="arrow" className="ml-05" />
      </div>
      <div className="row" style={{ width: "30%", fontSize: "14px" }}>
        <img src={Rocket24} alt="lab" className="mr-05 grey-icon" />
        <div className="gray" style={{ whiteSpace: " nowrap" }}>
          Type de mission
        </div>
      </div>
      <div className="row" style={{ width: "34%", fontSize: "14px" }}>
        <img
          src={isBookmark ? Clock24 : Calendar24}
          alt="lab"
          className="mr-05 grey-icon"
        />
        <div className="gray" style={{ whiteSpace: " nowrap" }}>
          {isBookmark ? "Heures de la mission" : "Dates de la mission"}
        </div>
      </div>
    </div>
  );
}
