import React from "react";
import { Bin } from "../../../assets/icons/icons";

type Props = {
  onClick: () => void;
  style?: React.CSSProperties;
};

const BinButton = ({ onClick, style }: Props) => (
  <div
    className="bg-fff pointer"
    style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "26px",
      ...style,
      width: "48px",
      height: "48px",
      padding: "8px",
    }}
    onClick={onClick}
  >
    <img src={Bin} alt="delete" />
  </div>
);

export default BinButton;
