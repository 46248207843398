import React from "react";

function Background({ children }) {
	return (
		<div className='background-container'>
			<div className='background'>{children}</div>
		</div>
	);
}

export default Background;
