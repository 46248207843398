import React, { useRef, useEffect } from "react";

type Props = {
  onClickedOutside: () => void;
  children: React.ReactNode;
};

const OutsideClickHandler = ({ onClickedOutside, children }: Props) => {
  const wrapperRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClickedOutside();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div ref={wrapperRef}>{children}</div>;
};

export { OutsideClickHandler };
