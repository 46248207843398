import React, { useState, useEffect } from "react";
import Background from "../components/Background";
import { usePostResetPassword } from "../api/user";
import ForgottenPasswordForm from "../components/forms/ForgottenPasswordForm";
import { PopupInfo } from "../components/PopupInfo";
import { PopupResetPassword } from "../components/popup/popupsProps";
import { useNavigate } from "react-router-dom";
import { ManualTextError } from "../components";
import { TkCare } from "../assets/logo/logo";

function ForgottenPassword() {
  const [validate, setValidate] = useState(false);
  const [email, setEmail] = useState("");
  const { data, loading, error } = usePostResetPassword(
    email,
    validate,
    setValidate
  );
  const [popupVisibility, setPopupVisibility] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setPopupVisibility(true);
    }
  }, [data]);

  const redirect = () => {
    navigate("/login");
  };

  return (
    <Background>
      <div className="login">
        <div className="login-left">
          <div style={{ marginLeft: "2rem", marginTop: "2rem" }}>
            <img src={TkCare} alt="" style={{ height: "63px" }} />
          </div>
        </div>
        <div className="login-right">
          <div style={{ padding: "2rem" }}>
            <h1 className="h0 mb-2">Bienvenue sur TkCare ! 🎉</h1>
            <div className="mb-2 h2">
              Créer des missions et trouver des candidats compétents rapidement
              et simplement !
            </div>
            <div className="mb-2 title">Mot de passe oublié 🔐</div>
            <div className="mb-2">
              Vous avez oublié votre passe ? Pas de panique. Entrez votre
              adresse mail. TkCare vous enverra un lien par e-mail pour convenir
              d’un nouveau mot de passe.
            </div>
            <ForgottenPasswordForm
              loading={loading}
              onValidated={(email) => {
                setEmail(email);
                setTimeout(() => setValidate(true));
              }}
            />
            {error && <ManualTextError message={error} />}
          </div>
        </div>
      </div>
      {popupVisibility && (
        <PopupInfo
          popupProps={PopupResetPassword}
          onClosed={() => redirect()}
          onValidated={() => redirect()}
        />
      )}
    </Background>
  );
}

export default ForgottenPassword;
