import React from "react";
import { Add } from "../../../assets/icons/icons";

type Props = {
  onClick: () => void;
  label: string;
};

const AddButton = ({ onClick, label }: Props) => (
  <button type="button" className="btn-green mr-a mw-fc mt-2" onClick={onClick}>
    <img src={Add} alt="add" className="mr-1" />
    {label}
  </button>
);

export default AddButton;
