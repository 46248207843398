import React from "react";
import { Block } from "../Block";
import { Capacitations32 } from "../../../assets/icons/icons";
import { Select } from "../../../components";
import { WithCapacitation } from "../../../data/occupations";
import { SelectItem } from "../../../data/SelectItem";

type Props = {
  withCapacitation: WithCapacitation;
  capacitations: (SelectItem | null)[];
  onChange: (values: (SelectItem | null)[]) => void;
};

const CapacitationBlock = ({
  withCapacitation,
  capacitations,
  onChange,
}: Props) => (
  <Block
    icon={Capacitations32}
    label="Compétences requises"
    alt="capacitations"
  >
    {capacitations.map((capacitation, index) => {
      return (
        <Select
          index={index + 1}
          key={index}
          selectedItem={capacitation}
          placeholder={withCapacitation.capacitations[index]?.label ?? ""}
          items={withCapacitation.capacitations}
          onItemSelected={(capacitation) => {
            const updatedCapacitations = capacitations.map(
              (currentCapacitation, subIndex) =>
                subIndex === index ? capacitation : currentCapacitation
            );
            onChange(updatedCapacitations);
          }}
          style={{ marginTop: index !== 0 ? "16px" : "0px" }}
        />
      );
    })}
  </Block>
);

export { CapacitationBlock };
