import React from "react";
import useGetPrivacyPolicy from "../../api/privacyPolicy";
import SubMenuWrapper from "./SubMenuWrapper";
import Title from "./Title";

const PrivacyPolicy = () => {
  const { data } = useGetPrivacyPolicy()

  return (
    <SubMenuWrapper>
    <Title label="Charte des données personnelles ‍🔐" />
    {data && (
      <>
        <br></br>
        <div style={{ maxWidth: '700px' }}
        dangerouslySetInnerHTML={{
          __html: data.content
        }}></div>
      </>
    )}
  </SubMenuWrapper>
  )
}

export default PrivacyPolicy