import React from 'react';

type ButtonProps = {
  buttonClassName: string;
  label: string;
  labelClassName: string;
  icon: string;
  alt: string;
  type: 'submit' | 'reset' | 'button';
  iconPosition: 'left' | 'right';
  alternateButtonClassName?: string;
  alternateLabel?: string;
  alternateIcon?: string;
  alternateAlt?: string;
};

type Props = {
  buttonProps: ButtonProps;
  disabled?: boolean;
  onClicked?: () => void;
  alternate?: boolean;
  containerStyle?: object;
};

const Button = ({
  buttonProps,
  disabled = false,
  onClicked,
  alternate = false,
  containerStyle,
}: Props) => {
  const Icon = ({ className }) => (
    <img
      src={buttonProps.icon}
      alt={!alternate ? buttonProps.alt : buttonProps.alternateAlt}
      className={className}
      style={{ width: '32px' }}
    />
  );

  return (
    <button
      className={
        !alternate
          ? buttonProps.buttonClassName
          : buttonProps.alternateButtonClassName
      }
      type={buttonProps.type}
      disabled={disabled}
      onClick={() => onClicked?.()}
      style={{ ...containerStyle }}
    >
      {buttonProps.iconPosition === 'left' && <Icon className="mr-075" />}
      <span className={buttonProps.labelClassName}>
        {!alternate ? buttonProps.label : buttonProps.alternateLabel}
      </span>
      {buttonProps.iconPosition === 'right' && <Icon className="ml-1" />}
    </button>
  );
};

export { ButtonProps, Button };
