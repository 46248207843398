import React from "react";
import { Calendar as ReactCalendar } from "react-calendar";
import { OutsideClickHandler } from "./utils/OutsideClickHandler";
import moment, { min } from "moment";
import { asDate } from "../api/format";
import { boolean } from "yup";

export type CalendarProps = {
  defaultDate?: string;
  highlightedDates?: string[];
  availableDates?: string[] | null;
  completedDates?: string[];
  finishedDates?: string[];
  defaultAvailableDates?: string[] | null;
  dismiss: () => void;
  onDateSelected?: (date: string) => void;
  type?: "checkin" | "selection";
  minDate?;
  tiny?: boolean;
};

const Calendar = ({
  defaultDate,
  highlightedDates = [],
  availableDates = null,
  completedDates = null,
  finishedDates = [],
  defaultAvailableDates = [],
  dismiss,
  onDateSelected,
  type,
  minDate,
  tiny = false,
}: CalendarProps) => {
  function tileClassName({ date, view }) {
    if (view === "month") {
      if (
        finishedDates?.includes(moment(date).format("YYYY-MM-DD")) ||
        finishedDates?.includes(moment(date).format("DD/MM/YYYY"))
      ) {
        return "finished-day";
      }

      // Check completed dates
      if (completedDates?.includes(moment(date).format("YYYY-MM-DD"))) {
        if (
          highlightedDates.includes(asDate(date)) ||
          highlightedDates.includes(moment(date).format("YYYY-MM-DD"))
        ) {
          return "completed-day selection-active-day";
        } else {
          return "completed-day";
        }
      }

      if (
        highlightedDates.includes(asDate(date)) ||
        highlightedDates.includes(moment(date).format("YYYY-MM-DD"))
      ) {
        switch (type) {
          case "checkin":
            if (
              type === "checkin" &&
              moment(date).isSame(moment(defaultDate), "day")
            ) {
              return "checkin-selected-day";
            } else {
              return "checkin-active-day";
            }

          case "selection":
            return "selection-active-day";
          default:
            return "active-day";
        }
      } else if (availableDates?.includes(moment(date).format("DD/MM/YYYY"))) {
        switch (type) {
          case "selection":
            return "available-active-day";
          default:
            return "";
        }
      } else if (
        defaultAvailableDates?.includes(moment(date).format("DD/MM/YYYY"))
      ) {
        switch (type) {
          case "checkin":
            break;
          case "selection":
            return "no-selection-day default-selectable-day";
          default:
            return "";
        }
      } else {
        switch (type) {
          case "checkin":
            break;
          case "selection":
            return "no-selection-day";
          default:
            return "";
        }
      }
    }
  }

  const getDisabledDates = ({ date }: { date: Date }) => {
    return (
      !onDateSelected ||
      (availableDates !== null &&
        !availableDates?.includes(moment(date).format("DD/MM/YYYY")))
    );
  };

  return (
    <OutsideClickHandler onClickedOutside={dismiss}>
      <ReactCalendar
        defaultView="month"
        formatShortWeekday={(locale, date) => {
          return tiny
            ? moment(date).format("ddd").slice(0, 1).toUpperCase()
            : moment(date).format("ddd");
        }}
        prev2Label=""
        next2Label=""
        view="month"
        minDate={minDate}
        className={tiny ? "tiny-calendar" : ""}
        tileClassName={tileClassName}
        showNeighboringMonth={true}
        tileDisabled={getDisabledDates}
        defaultActiveStartDate={new Date(defaultDate ?? Date.now())}
        onClickDay={(value: Date) => {
          onDateSelected(moment(value).format("YYYY-MM-DD"));
        }}
        locale="fr"
      />
    </OutsideClickHandler>
  );
};

export default Calendar;
