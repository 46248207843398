const jsonHeader = {
  headers: {
    "Content-Type": "application/json",
  },
};

const jsonHeaderAuthenticated = () => {
  let token = localStorage.getItem("tkcare_token");
  if (token === null) {
    token = sessionStorage.getItem("tkcare_token");
  }
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

const post = (body: any = null) => {
  return {
    method: "POST",
    body,
    ...jsonHeader,
  };
};

const get = () => {
  return {
    method: "GET",
  };
};

const postWithAuth = (body: any = null, extraHeader = {}) => {
  // We remove null keys
  const headers = Object.fromEntries(
    Object.entries({ ...jsonHeaderAuthenticated(), ...extraHeader }).filter(
      ([_, v]) => v != null
    )
  );
  return {
    method: "POST",
    body,
    headers: headers,
  };
};

const getWithAuth = () => {
  return {
    method: "GET",
    headers: { ...jsonHeaderAuthenticated() },
  };
};

const patchWithAuth = (body: any = null) => {
  return {
    method: "PATCH",
    body,
    headers: { ...jsonHeaderAuthenticated() },
  };
};
const putWithAuth = (body: any = null) => {
  return {
    method: "PUT",
    body,
    headers: { ...jsonHeaderAuthenticated() },
  };
};

const deleteWithAuth = () => {
  return {
    method: "DELETE",
    headers: { ...jsonHeaderAuthenticated() },
  };
};

export {
  post,
  get,
  postWithAuth,
  getWithAuth,
  patchWithAuth,
  putWithAuth,
  deleteWithAuth,
};
