import React from "react";
import Popup from "./Popup";

type PopupProps = {
  title: string;
  image: any;
  description: any;
  buttonLabel: string;
  buttonIcon: any;
};

type Props = {
  popupProps: PopupProps;
  onClosed: () => void;
  onValidated: () => void;
};

const PopupInfo = ({ popupProps, onClosed, onValidated }: Props) => {
  return (
    <Popup
      title={popupProps.title}
      image={
        <img
          src={popupProps.image}
          alt="popup"
          style={{
            marginBottom: "0 auto",
          }}
        />
      }
      question={
        <div
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginBottom: ".5rem",
            marginTop: "1rem",
            lineHeight: "33px",
          }}
        >
          {popupProps.description}
        </div>
      }
      dismiss={() => onClosed()}
      button={
        <button
          onClick={() => onValidated()}
          style={{
            marginBottom: "1rem",
            marginRight: "1rem",
            marginTop: "1.5rem",
            fontSize: "20px",
            padding: "0.5em 1em",
          }}
        >
          {popupProps.buttonLabel}
          <img
            src={popupProps.buttonIcon}
            alt="validate"
            className={"white-icon"}
            style={{
              width: "1.75rem",
              marginLeft: "0.5rem",
            }}
          />
        </button>
      }
      bgColor="#221b43"
      color="white"
      subtitle={undefined}
      textarea={undefined}
      managers={undefined}
      welcomeBoardList={undefined}
      style={{}}
    />
  );
};

export { PopupProps, PopupInfo };
