import React from "react";
import { TkCare } from "../assets/logo/logo";

export default function ContactCustomerService() {
  function displayChat() {
    window.$crisp.push(["do", "chat:open"]);
  }

  return (
    <div className="contactCustomerService shadowEffect p-05 mt-3 bg-fff br-05">
      <div className="row jc-sb">
        <img src={TkCare} className="logo-small mb-05" alt="logo" />
        <button className="btn-mission" onClick={displayChat}>Contacter le service client</button>
      </div>
      <div className="row">
        Pour annuler une mission, contactez le service client de TkCare
      </div>
    </div>
  );
}
