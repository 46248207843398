import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, validateScroll } from "../../components/buttons/buttons";

type Props = {
  title: string;
  description: string;
  onClosed: () => void;
  onValidated: () => void;
};

export default function GcuPopup({
  title,
  description,
  onValidated,
  onClosed
}: Props) {
  const [canValidate, setCanValidate] = useState(false);
  const ref = useRef(null);

  function handleScroll(e) {
    const result = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
    if (result <= 1) { 
      setCanValidate(true)
    }
  }

  useEffect(() => {
    if(ref.current.clientHeight <= 700) {
      setCanValidate(true)
    }
  }, []);

  return (
    <Modal show={true} onHide={onClosed} className="popup">
    <div
      className="popup-body"
      style={{
        backgroundColor: 'white',
        width: '90vw',
        height: '90vh',
        padding: '80px',
      }}
    >
      <Modal.Header>
        <Modal.Title
          style={{
            color: "#221B43",
          }}
        >
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: '80%' }}>
        <div style={{width: '100%', height: '100%', overflowY: 'scroll'}} onScroll={handleScroll}>
          <div ref={ref} style={{maxWidth: '700px', color: "#221B43", marginTop: '56px'}}
            dangerouslySetInnerHTML={{
              __html: description
            }}></div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div style={{width: '', marginTop: '34.5px', marginRight: 'auto'}}>
          <Button
            buttonProps={validateScroll}
            onClicked={onValidated}
            disabled={!canValidate}
          />
        </div>
      </Modal.Footer>
    </div>
  </Modal>
);
}