import React, { useState, useContext } from "react";
import Toggle from "./Toggle";
import { Select2 } from "select2-react-component";
import { useGetInstitutionsForSelect } from "../api/institutions";
import { UserContext } from "../components/context/UserContext";
import { ContractType } from "../api/enums";

export default function EstablishmentSelect({ onSelected }) {
  const { data } = useGetInstitutionsForSelect();
  const {
    selectedInstitution,
    setSelectedInstitution,
    contractType,
    setContractType,
    isRh,
  } = useContext(UserContext);
  const [search, setSearch] = useState("");
  return (
    <div className="establishment-select">
      <div
        className="row mt-2 mb-2"
        style={{
          maxWidth: "1080px",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        {data && isRh() && (
          <h2 className="mr-15 bold nowrap">Sélectionnez un établissement</h2>
        )}
        <div className="ml-4">
          {data && isRh() && (
            <Select2
              data={
                data
                  ? search === ""
                    ? data
                    : data.filter((institution) =>
                        institution.label
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      )
                  : []
              }
              update={(value) => {
                setSelectedInstitution(value);
                onSelected?.();
              }}
              search={(value) => setSearch(value)}
              keepSearchText
              customSearchEnabled
              placeholder={"Sélectionnez un établissement"}
              value={selectedInstitution ?? data[0].value}
            ></Select2>
          )}
        </div>
        {false && (
          <Toggle
            items={["V", "I"]}
            small={true}
            className="ml-a"
            chooseToggle={(value) =>
              setContractType(
                value === 0 ? ContractType.FullTime : ContractType.PartTime
              )
            }
            selectedItemIdx={contractType !== ContractType.FullTime ? 1 : 0}
          />
        )}
      </div>
    </div>
  );
}
