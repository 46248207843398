import React from "react";
import { moods } from "../data/moods";

export default function StrengthIconCorrespondances({
  moodKey,
  containerStyle = {},
}) {
  const mood = moods.find((mood) => mood.key === moodKey);
  return (
    <div
      style={{
        fontSize: "2rem",
        borderRadius: "1.5rem",
        padding: "1rem",
        height: "2.5rem",
        ...containerStyle,
      }}
      className="row jc-fs mr-05 bg-lightgray"
    >
      <div
        style={{
          justifyContent: "flex-start",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          fontSize: "1rem",
        }}
      >
        <div style={{ marginRight: ".2rem" }}>{mood?.emoji} </div>
        <span style={{ fontSize: "1rem", whiteSpace: "nowrap" }}>
          {mood?.label}
        </span>
      </div>
    </div>
  );
}
