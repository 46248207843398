import React from "react";
import Popup from "./Popup";
import CheckImg from "../assets/icons/check/check.png";
import CancelMissionImg from "../assets/images/cancelMission.png";

export function PopUpCancelMission({ cancel, isPopupVisible, dismiss }) {
  return (
    isPopupVisible && (
      <Popup
        dismiss={dismiss}
        title="Attention ! ⚠️"
        image={
          <img
            src={CancelMissionImg}
            alt=""
            style={{
              borderRadius: ".5rem",
              marginBottom: "0 auto",
              width: "568px",
              height: "260px",
            }}
          />
        }
        question={
          <div
            style={{
              fontSize: "1.25rem",
              marginBottom: ".5rem",
              marginTop: "1rem",
            }}
          >
            Êtes-vous sûr de vouloir annuler la mission ? Toutes les données que
            vous avez saisies seront perdues 😢
          </div>
        }
        className="popup"
        button={
          <>
            <button onClick={() => cancel()} className="btn-main">
              Annuler la mission
              <img
                src={CheckImg}
                alt="check"
                style={{
                  width: "1.75rem",
                  marginLeft: "1rem",
                }}
              />
            </button>
          </>
        }
        centered
        bgColor="#221b43"
        color="white"
      />
    )
  );
}
