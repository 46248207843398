import React from "react";
import { Block } from "../Block";
import { Sun } from "../../../assets/icons/icons";
import AddButton from "./AddButton";
import {
  DailyRoutine,
  substituteCapacitations,
} from "../../../data/dailyRoutines";
import { HourRange } from "./HourRange";
import { Select } from "../../../components";
import BinButton from "./BinButton";

type Props = {
  dailyRoutines: DailyRoutine[];
  onChange: (dailyRoutines: DailyRoutine[]) => void;
};

const DailyRoutinesBlock = ({ dailyRoutines, onChange }: Props) => {
  return (
    <Block icon={Sun} label="Journée type" alt="daily routines">
      <div>
        {dailyRoutines.map((dailyRoutine, index) => (
          <div
            className="row"
            style={{ gap: "24px", marginTop: index !== 0 ? "8px" : "0px" }}
            key={index}
          >
            <HourRange
              startHourProps={{
                value: dailyRoutine.startHour,
                onChange: (startHour: string) => {
                  const updatedDailyRoutines = dailyRoutines.map(
                    (currentDailyRoutine, subIndex) => {
                      if (subIndex === index) {
                        currentDailyRoutine.startHour = startHour;
                      }
                      return currentDailyRoutine;
                    }
                  );
                  onChange(updatedDailyRoutines);
                },
              }}
              endHourProps={{
                value: dailyRoutine.endHour,
                onChange: (endHour: string) => {
                  const updatedDailyRoutines = dailyRoutines.map(
                    (currentDailyRoutine, subIndex) => {
                      if (subIndex === index) {
                        currentDailyRoutine.endHour = endHour;
                      }
                      return currentDailyRoutine;
                    }
                  );
                  onChange(updatedDailyRoutines);
                },
              }}
            />
            <Select
              selectedItem={dailyRoutine?.dailyTask}
              placeholder="IDE"
              items={substituteCapacitations}
              onItemSelected={(substituteCapacitation) => {
                const updatedDailyRoutines = dailyRoutines.map(
                  (currentDailyRoutine, subIndex) => {
                    if (subIndex === index) {
                      currentDailyRoutine.dailyTask = substituteCapacitation;
                    }
                    return currentDailyRoutine;
                  }
                );
                onChange(updatedDailyRoutines);
              }}
              style={{ width: "782px" }}
            />
            <BinButton
              onClick={() => {
                const updatedDailyRoutines = dailyRoutines;
                updatedDailyRoutines.pop();
                onChange(updatedDailyRoutines);
              }}
            />
          </div>
        ))}
        <AddButton
          label="Ajouter une plage horaire"
          onClick={() => {
            const updatedDailyRoutines = dailyRoutines;
            updatedDailyRoutines.push({
              startHour: "",
              endHour: "",
              dailyTask: null,
            });
            onChange(updatedDailyRoutines);
          }}
        />
      </div>
    </Block>
  );
};

export default DailyRoutinesBlock;
