import React, { useState } from "react";
import { MissionItem } from "../../components/MissionItem";
import Popup from "../../components/Popup";
import {
  ChevronRightCircle,
  Laboratory24,
  PCRTests,
  Clock24,
  Edit,
  GirlPlus24,
} from "../../assets/icons/icons.ts";

const Bookmarks = ({
  availableBookmarks,
  selectedBookmark,
  setSelectedBookmark,
}) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  return (
    <>
      {selectedBookmark && (
        <div
          style={{ maxWidth: "800px" }}
          className="bg-fff col p-1 pb-2 br-05"
        >
          <div className="row as-fs">
            <img src={Laboratory24} alt="" className="mr-05" />
            <div className="semibold" style={{ fontSize: "20px" }}>
              {selectedBookmark.bookmarkName}
            </div>
          </div>
          <div className="row as-fs mt-03">
            <img src={PCRTests} alt="" className="mr-05" />
            <div className="semibold">{selectedBookmark.serviceLabel}</div>
          </div>
          <div className="row mt-2 w-100">
            <div className="col flex-1">
              <div className="row as-fs">
                <img src={Clock24} alt="" className="mr-05 grey-icon" />
                <div>Horaires</div>
              </div>
              <div className="row as-fs mt-05">
                <div className="semibold">{selectedBookmark.hourRange}</div>
              </div>
            </div>
            <div className="col flex-1">
              <div className="row as-fs">
                <img src={GirlPlus24} alt="" className="mr-05 grey-icon" />
                <div>Candidat recherché</div>
              </div>
              <div className="row as-fs mt-03">
                <div className="semibold">
                  {selectedBookmark.occupationLabel}
                </div>
              </div>
            </div>
          </div>
          <button
            className="btn-green mr-a mt-2"
            style={{ maxWidth: "290px" }}
            onClick={() => setIsPopupVisible(true)}
            type="button"
          >
            <img src={Edit} alt="pen" className="mr-05" />
            Changer de modèle
          </button>
        </div>
      )}

      {availableBookmarks && !selectedBookmark && (
        <button
          className="btn-green"
          style={{ maxWidth: "290px" }}
          onClick={() => setIsPopupVisible(true)}
          type="button"
        >
          <img src={Edit} alt="pen" className="mr-05" />
          Pré-remplir avec un modèle
        </button>
      )}
      {isPopupVisible && (
        <div style={{ margin: "0 auto" }}>
          <Popup
            title="Sélectionnez un modèle de Welcome Board 📑"
            subtitle="Vos modèles favoris ⭐️"
            color="#221b43"
            style={{
              background: `linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #BAB7C8`,
            }}
            welcomeBoardList={availableBookmarks.map((bookmark, i) => {
              return (
                <div className="mb-1 mission-item" key={i}>
                  <MissionItem
                    bookmark={bookmark}
                    index={i}
                    warnParent={(i) => setSelectedIndex(i)}
                    selected={selectedIndex === i}
                  />
                </div>
              );
            })}
            dismiss={() => {
              setIsPopupVisible(false);
            }}
            button={
              <button
                className={`btn-main pl-1 mr-a ${
                  selectedIndex === null ? "btn-invalid" : ""
                }`}
                onClick={() => {
                  setIsPopupVisible(false);
                  setSelectedBookmark(availableBookmarks[selectedIndex]);
                }}
                disabled={selectedIndex === null}
                style={
                  selectedIndex === null
                    ? { backgroundColor: "#f8cad7", fontSize: 20 }
                    : { fontSize: 20 }
                }
              >
                Sélectionner ce modèle
                <img
                  src={ChevronRightCircle}
                  alt="check"
                  style={{ width: "1.75rem", marginLeft: "0.5rem" }}
                />
              </button>
            }
          />
        </div>
      )}
    </>
  );
};

export default Bookmarks;
