import * as Yup from "yup";
import { requiredField } from "../../../data/yup";

export default function validationSchema(isRh: boolean) {
  return Yup.object().shape({
    name: Yup.string().required(requiredField),
    phone: Yup.string().required(requiredField),
    email: Yup.string().required(requiredField),
    address: Yup.object().test("completedObject", requiredField, (value) => {
      return value.label !== "";
    }),
    employees: Yup.array().test("oneOfRequired", requiredField, (employees) => {
      return !isRh || employees.length > 0;
    }),
  });
}
