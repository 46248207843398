import React from "react";
import CircleInputBlock from "./CircleInputBlock";
import { HourlyRate32 } from "../../../assets/icons/icons";

type Props = {
  value: string;
  onChange: (value: string) => void;
};

const HourlyRateBlock = ({ value, onChange }: Props) => (
  <CircleInputBlock
    label="€/h"
    value={value}
    onChange={onChange}
    blockProps={{
      label: "Tarif horaire de la mission",
      alt: "hourly rate",
      icon: HourlyRate32,
    }}
  />
);

export default HourlyRateBlock;
