import { ErrorMessage } from "formik";
import React from "react";

type ManualTextErrorProps = {
  message: string;
  style?: React.CSSProperties;
};

const ManualTextError = ({ message, style }: ManualTextErrorProps) => (
  <div style={{ color: "red", margin: "8px 0 0 8px", ...style }}>{message}</div>
);

type Props = {
  name: string;
  style?: React.CSSProperties;
};

const TextError = ({ name, style }: Props) => (
  <ErrorMessage name={name}>
    {(message) => <ManualTextError message={message} style={style} />}
  </ErrorMessage>
);

export { ManualTextError, TextError };
