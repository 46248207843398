import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Stepper from "./Stepper";
import { CreateMissionContext } from "./context/CreateMissionContext";
import { TextError } from "./index";
import { Formik, Form } from "formik";
import { secondFormSchema } from "../pages/create-mission/validationSchemas";
import { DescriptionBlock } from "../pages/create-mission/step-two/DescriptionBlock";
import { CapacitationBlock } from "../pages/create-mission/step-two/CapacitationsBlock";
import StaffsBlock from "../pages/create-mission/step-two/StaffsBlock";
import BedBlock from "../pages/create-mission/step-two/BedBlock";
import BackButton from "../pages/create-mission/BackButton";
import CancelButton from "../pages/create-mission/CancelButton";
import HourlyRateBlock from "../pages/create-mission/step-two/HourlyRateBlock";
import CheckboxBlock from "../pages/create-mission/step-two/CheckboxBlock";
import { secondStepBlockProps } from "../pages/create-mission/step-two/blockProps";
import DateBlock from "../pages/create-mission/step-two/DateBlock";
import HourBlock from "../pages/create-mission/step-two/HourBlock";
import DesiredCandidateBlock from "../pages/create-mission/step-two/DesiredCandidateBlock";
import DailyRoutinesBlock from "../pages/create-mission/step-two/DailyRoutinesBlock";
import isDev from "../isDev";
import { occupations } from "../data/occupations";
import { substituteCapacitations } from "../data/dailyRoutines";
import { Button, next } from "./buttons/buttons";
import { asHour } from "../api/format";
import { Clock32 } from "../assets/icons/icons";
import FormSelectBlock from "./forms/FormSelectBlock";
import { Col, Row, Space } from "antd";

export default function AddMissionStepTwo() {
  const { firstStepValues, secondStepValues, setSecondStepValues } =
    useContext(CreateMissionContext);
  const { selectedBookmark } = firstStepValues;
  const navigate = useNavigate();
  const hospitalValues = firstStepValues.isHospital
    ? {
        bed: selectedBookmark?.bed ?? (isDev ? "20" : ""),
        dailyRoutines:
          selectedBookmark?.dailyRoutines.map((dailyRoutine) => {
            return {
              startHour: asHour(dailyRoutine.startAt, ":"),
              endHour: asHour(dailyRoutine.endAt, ":"),
              dailyTask: substituteCapacitations.find(
                (substituteCapacitation) =>
                  substituteCapacitation.key === dailyRoutine.dailyTask
              ),
            };
          }) ??
          (isDev
            ? [
                {
                  startHour: "08:00",
                  endHour: "10:00",
                  dailyTask: substituteCapacitations[0],
                },
              ]
            : [
                {
                  startHour: "",
                  endHour: "",
                  dailyTask: null,
                },
                {
                  startHour: "",
                  endHour: "",
                  dailyTask: null,
                },
                {
                  startHour: "",
                  endHour: "",
                  dailyTask: null,
                },
                {
                  startHour: "",
                  endHour: "",
                  dailyTask: null,
                },
                {
                  startHour: "",
                  endHour: "",
                  dailyTask: null,
                },
              ]),
      }
    : {};

  return (
    // <div>
    <Formik
      initialValues={
        secondStepValues ?? {
          isHospital: firstStepValues.isHospital,
          description:
            selectedBookmark?.description ?? (isDev ? "lorem ipsum" : ""),
          capacitations:
            selectedBookmark?.capacitations ??
            (isDev
              ? [
                  firstStepValues.service.capacitations[0],
                  firstStepValues.service.capacitations[1],
                  null,
                ]
              : [null, null, null]),
          startDate: null,
          endDate: null,
          welcomeBoardDates: [],
          break: 0,
          startHour: selectedBookmark?.startHour ?? (isDev ? "" : ""),
          endHour: selectedBookmark?.endHour ?? (isDev ? "12:00" : ""),
          hourlyRate: selectedBookmark?.hourlyRate ?? (isDev ? "24" : ""),
          provideOutfit: selectedBookmark?.provideOutfit ?? false,
          provideParking: selectedBookmark?.provideParking ?? false,
          provideCloakroom: selectedBookmark?.provideCloakroom ?? false,
          needDriveLicence: selectedBookmark?.needDriveLicence ?? false,
          searchJunior:
            selectedBookmark?.searchJunior ?? (isDev ? true : false),
          searchAverage: selectedBookmark?.searchAverage ?? false,
          searchSenior: selectedBookmark?.searchSenior ?? false,
          staffs:
            selectedBookmark?.staffs.map((staff) => {
              return {
                number: staff.number,
                occupation: occupations.find(
                  (occupation) => occupation.key === staff.occupation
                ),
              };
            }) ??
            (isDev
              ? [
                  { number: "3", occupation: occupations[0] },
                  { number: "5", occupation: occupations[1] },
                ]
              : [
                  { number: "", occupation: null },
                  { number: "", occupation: null },
                  { number: "", occupation: null },
                ]),
          ...hospitalValues,
        }
      }
      validationSchema={secondFormSchema}
      onSubmit={(values) => {
        setSecondStepValues(values);
        navigate("/missions/new/step-three");
      }}
    >
      {({
        values,
        setFieldValue,
        setFieldTouched,
        errors,
        isValid,
        setValues,
        touched,
      }) => {
        const onChange = (key, value) => {
          setFieldValue(key, value);
          setTimeout(() => setFieldTouched(key, true));
        };

        const handleSearch = (key) => {
          setValues({
            ...values,
            searchJunior: key === "searchJunior",
            searchAverage: key === "searchAverage",
            searchSenior: key === "searchSenior",
          });
        };

        console.log(errors);
        console.log({ touched });

        return (
          <Form>
            <Stepper currentStep={1} />
            <DescriptionBlock
              value={values.description}
              onChange={(value) => onChange("description", value)}
            />
            <TextError name="description" />
            <hr className="mt-2 bg-white2" />

            {/* CAPACITATION BLOCK */}
            <CapacitationBlock
              withCapacitation={firstStepValues.service}
              capacitations={values.capacitations}
              onChange={(value) => onChange("capacitations", value)}
            />
            <TextError name="capacitations" />

            <hr className="mt-2 bg-white2" />

            {/* DATE & TIME BLOCK */}
            <Row gap={"20px"} align={"top"} gutter={[0, 0]}>
              <Col flex="360px">
                <DateBlock
                  welcomeBoardDatesProps={{
                    value: values.welcomeBoardDates,
                    onChange: (value) => onChange("welcomeBoardDates", value),
                  }}
                  startDateProps={{
                    value: values.startDate,
                    onChange: (value) => onChange("startDate", value),
                  }}
                  endDateProps={{
                    value: values.endDate,
                    onChange: (value) => onChange("endDate", value),
                  }}
                />
                <TextError name="startDate" style={{ margin: "8px 0 0 0px" }} />
                {!errors.startDate && <TextError name="endDate" />}
              </Col>

              <Col flex="auto" style={{ marginTop: "50px" }}>
                <Row
                  gutter={[80, 60]}
                  align={"stretch"}
                  justify={"space-between"}
                >
                  {/* Hours block */}
                  <Col span={10} push={1}>
                    <div>
                      <HourBlock
                        startHourProps={{
                          value: values.startHour,
                          onChange: (value) => onChange("startHour", value),
                        }}
                        endHourProps={{
                          value: values.endHour,
                          onChange: (value) => onChange("endHour", value),
                        }}
                      />
                      <TextError
                        name="startHour"
                        style={{ margin: "8px 0 0 0px" }}
                      />
                      {!errors.startHour && <TextError name="endHour" />}
                    </div>
                  </Col>

                  {/* Break Time */}
                  <Col span={10} push={1}>
                    <FormSelectBlock
                      icon={Clock32}
                      label="Temps de pause"
                      alt={"break time"}
                      prefix="De"
                      value={values.break.value}
                      options={[
                        { value: 0, label: "0m" },
                        { value: 15, label: "15m" },
                        { value: 30, label: "30m" },
                        { value: 45, label: "45m" },
                        { value: 60, label: "1h" },
                        { value: 75, label: "1h15" },
                        { value: 90, label: "1h30" },
                        { value: 105, label: "1h45" },
                        { value: 120, label: "2h" },
                        { value: 135, label: "2h15" },
                        { value: 150, label: "2h30" },
                        { value: 165, label: "2h45" },
                        { value: 180, label: "3h" },
                        { value: 195, label: "3h15" },
                        { value: 210, label: "3h30" },
                        { value: 225, label: "3h45" },
                        { value: 240, label: "4h" },
                        { value: 255, label: "4h15" },
                        { value: 270, label: "4h30" },
                        { value: 285, label: "4h45" },
                        { value: 300, label: "5h" },
                      ]}
                      onChange={(value) => onChange("break", value)}
                    />
                    <TextError name="break" />
                  </Col>

                  {/* Hourly rate */}
                  <Col span={10} push={1}>
                    <div style={{ height: "158px" }}>
                      <HourlyRateBlock
                        value={values.hourlyRate}
                        onChange={(value) => onChange("hourlyRate", value)}
                      />
                      <TextError name="hourlyRate" />
                    </div>
                  </Col>
                </Row>
                <Space
                  direction="vertical"
                  block={true}
                  align="start"
                  size={"large"}
                >
                  <Space
                    size={"large"}
                    block={true}
                    align="start"
                    gap={2}
                  ></Space>

                  <div className="flex-1 w-33"></div>
                </Space>
              </Col>
            </Row>
            <div className="col">
              <div className="row" style={{ width: "100%" }}></div>
            </div>

            <hr className="mt-2 bg-white2" />

            <div className="row">
              <div className="flex-1 w-33">
                <CheckboxBlock
                  value={values.provideOutfit}
                  onChange={(value) => onChange("provideOutfit", value)}
                  blockProps={secondStepBlockProps.outfit}
                />
              </div>
              <div className="flex-1 w-33">
                <CheckboxBlock
                  value={values.provideCloakroom}
                  onChange={(value) => onChange("provideCloakroom", value)}
                  blockProps={secondStepBlockProps.cloakroom}
                />
              </div>
              <div className="jc-fe">
                <CheckboxBlock
                  value={values.provideParking}
                  onChange={(value) => onChange("provideParking", value)}
                  blockProps={secondStepBlockProps.parking}
                />
              </div>
            </div>
            <hr className="mt-2 bg-white2" />
            <StaffsBlock
              staffs={values.staffs}
              onChange={(value) => onChange("staffs", value)}
            />
            <TextError name="staffs" />
            {values.isHospital && (
              <>
                <BedBlock
                  value={values.bed}
                  onChange={(value) => onChange("bed", value)}
                  style={{ marginBottom: "2rem" }}
                />
                <TextError name="bed" />
                <hr className="mt-2 bg-white2" />
              </>
            )}
            {values.isHospital && (
              <>
                <DailyRoutinesBlock
                  dailyRoutines={values.dailyRoutines}
                  onChange={(value) => onChange("dailyRoutines", value)}
                />
                <TextError name="dailyRoutines" />
                <hr className="mt-2 bg-white2" />
              </>
            )}

            <DesiredCandidateBlock
              searchJuniorProps={{
                value: values.searchJunior,
                onChange: () => handleSearch("searchJunior"),
              }}
              searchAverageProps={{
                value: values.searchAverage,
                onChange: () => handleSearch("searchAverage"),
              }}
              searchSeniorProps={{
                value: values.searchSenior,
                onChange: () => handleSearch("searchSenior"),
              }}
              needDriveLicenceProps={{
                value: values.needDriveLicence,
                onChange: (value) => onChange("needDriveLicence", value),
              }}
            />
            <TextError name="searchJunior" />
            <div className="row mt-4 mb-3" style={{ gap: 24 }}>
              <CancelButton cancel={() => navigate("/board")} />
              <BackButton onClick={() => navigate("/missions/new/step-one")} />
              <Button buttonProps={next} />
            </div>
          </Form>
        );
      }}
    </Formik>
    // </div>
  );
}
