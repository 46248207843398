import { useRequest } from "..";
import { getWithAuth, deleteWithAuth } from "../requests";
import { occupations, services } from "../../data/occupations";
import moment from "moment";

const mapper = (raw: any) => {
  return raw.map((rawBookmark: any) => {
    const { welcomeBoard } = rawBookmark;
    const { institution } = welcomeBoard;
    const occupation = occupations.find(
      (occupation) => occupation.key === welcomeBoard.occupation
    );
    const service = Object.entries(services).find(
      ([_, service]) => service.key === welcomeBoard.service
    )?.[1];

    return {
      id: rawBookmark.id,
      welcomeBoardId: welcomeBoard.id,
      occupation: occupation,
      service: service,
      startDate: moment(welcomeBoard.startAt).format("YYYY-MM-DD"),
      endDate: moment(welcomeBoard.endAt).format("YYYY-MM-DD"),
      startHour: moment(welcomeBoard.startAt).format("HH:mm"),
      endHour: moment(welcomeBoard.endAt).format("HH:mm"),

      occupationLabel: occupation.label,
      serviceLabel: service.label,
      dateRange: `Du ${moment(welcomeBoard.startAt).format(
        "DD/MM/YYYY"
      )} au ${moment(welcomeBoard.endAt).format("DD/MM/YYYY")}`,
      hourRange: `${moment(welcomeBoard.startAt).format("HH[h]mm")} - ${moment(
        welcomeBoard.endAt
      ).format("HH[h]mm")}`,
      institution: institution?.name,
      isHospital: institution?.type === "hospital",
      bookmarkName: rawBookmark.name,
    };
  });
};

const useGetBookmarks = (
  validate?: boolean,
  setValidate?: (validate: boolean) => void,
  institutionId?: string
) => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}bookmark${
      institutionId ? `?institution=${institutionId}` : ""
    }`,
    getWithAuth(),
    mapper,
    validate ?? true,
    setValidate
  );
};

const deleteBookmarks = async (bookmarks: string[]): Promise<boolean> => {
  const requests = bookmarks.map((id) =>
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}bookmark/${id}`,
      deleteWithAuth()
    )
  );
  const responses = await Promise.all(requests);
  const errors = responses.filter((response) => !response.ok);
  return errors.length === 0;
};

export { useGetBookmarks, deleteBookmarks };
