import React from "react";

type Props = {
  value: string | null;
  onChange: (value: string) => void;
  placeholder: string;
  style?: React.CSSProperties;
};

const TextArea = ({ value, onChange, placeholder, style }: Props) => (
  <textarea
    placeholder={placeholder}
    value={value}
    onChange={(event: any) => {
      onChange(event.target.value);
    }}
    className="row w-100"
    style={{
      height: "89px",
      border: "1px solid #BAB7C8",
      borderRadius: "8px",
      padding: "16px",
      marginLeft: 0,
      marginRight: 0,
      ...style,
    }}
  />
);

export { TextArea };
