import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Cell,
  CartesianAxis,
} from "recharts";

function MyBarGraph({
  width = 1080,
  height = 400,
  barWidth = 55,
  cursorPointer = false,
  currentColor = "#EE7A9B",
  stats,
  secondStats = null,
}) {
  const months = [
    "Jan",
    "Fév",
    "Mar",
    "Avr",
    "Mai",
    "Jui",
    "Jul",
    "Aoû",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const currentMonth = new Date().getMonth();

  const mappedStats = months.map((month, index) => {
    return {
      name: month,
      value: stats.find((stat) => stat.month === index + 1)?.value ?? 0,
      secondValue:
        secondStats?.find((stat) => stat.month === index + 1)?.value ?? 0,
      current: currentMonth === index,
    };
  });

  const max = stats
    ? stats
        .map((stat) => Number(stat.value))
        .reduce((a, b) => Math.max(a, b), -Infinity) ?? 0
    : 0;

  const scale = max >= 10 ? Math.floor(max / 10) * 10 : 10;

  return (
    <BarChart
      width={width}
      height={height}
      data={mappedStats}
      margin={{
        top: 20,
        right: 20,
        bottom: 20,
        left: 0,
      }}
      cursor={cursorPointer ? "pointer" : "default"}
    >
      <CartesianAxis />
      <CartesianGrid vertical={false} stroke="#696E8B" />
      <XAxis
        dataKey="name"
        orientation="top"
        axisLine={false}
        tickLine={false}
        tickMargin={20}
        stroke="#BAB7C8"
      />
      <YAxis
        type="number"
        domain={[0, scale]}
        axisLine={false}
        tickLine={false}
        width={40}
        stroke="#BAB7C8"
      />
      <Bar dataKey="value" barSize={barWidth} fill="#EDEDED">
        {mappedStats.map((entry, i) => (
          <Cell key={i} fill={entry.current ? currentColor : "#EDEDED"} />
        ))}
      </Bar>
      {secondStats !== null && (
        <>
          coucou
          <Bar dataKey="secondValue" barSize={barWidth} fill="#4ea499">
            {mappedStats.map((entry, i) => (
              <Cell key={i} fill={entry.current ? "#1b7166" : "#4ea499"} />
            ))}
          </Bar>
        </>
      )}
    </BarChart>
  );
}

export default MyBarGraph;
