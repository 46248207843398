import { useRequest } from "..";
import { getWithAuth } from "../requests";

const useGetLastStatistics = (institutionId: string) => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}statistic/last?institution=${institutionId}`,
    getWithAuth(),
    (raw: any) => raw,
    true
  );
};

const useGetStatistics = (
  category: string | null,
  year: string,
  institutionId: string
) => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}statistic/monthly/${category}/${year}?institution=${institutionId}`,
    getWithAuth(),
    (raw: any) => raw,
    category !== null
  );
};

export { useGetLastStatistics, useGetStatistics };
