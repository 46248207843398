import React, { useContext, useEffect, useState } from 'react';
import { Selection, MissionDetails } from './MissionDetails';
import Navbar from './Navbar';
import MissionCard from '../pages/recruitment/MissionCard';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  useGetWelcomeBoardById,
  usePatchWelcomeBoardForValidation,
} from '../api/welcomeboards';
import {
  PopupDeclineMission,
  PopupValidateMissionProps,
} from './popup/popupsProps';
import { PopupInfo } from './PopupInfo';
import { useNavigate } from 'react-router-dom';
import Applicants from '../pages/recruitment/Applicants';
import { WelcomeBoardState } from '../api/enums';
import { useLocation } from 'react-router-dom';
import ContactCustomerService from './ContactCustomerService';
import { CommentPopup } from './CommentPopup';
import ApplicantNotation from '../pages/ApplicantNotation';
import HumanResourcesSection from '../pages/recruitment/HumanResourcesSection';
import { UserContext } from './context/UserContext';

export default function Validation() {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalDeclineVisible, setModalDeclineVisible] = useState(false);
  let { id } = useParams();
  const { data: mission } = useGetWelcomeBoardById(id ?? null);
  const [selection, setSelection] = useState(null);
  const [comment, setComment] = useState('');
  const [validate, setValidate] = useState(false);
  const { data: patchResult } = usePatchWelcomeBoardForValidation(
    id,
    selection,
    comment,
    validate
  );
  const navigate = useNavigate();
  const { isRh } = useContext(UserContext);
  const [seed, setSeed] = useState(0);

  useEffect(() => {
    if (patchResult) {
      navigate('/recruitments/dispatch');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchResult]);

  const location = useLocation();
  const pathname = location.pathname.substring(
    0,
    location.pathname.lastIndexOf('/')
  );
  const getTitle = () => {
    let title = '';
    if (pathname.endsWith('validation')) {
      title = 'à valider';
    } else if (pathname.endsWith('dispatch')) {
      title = 'à attribuer';
    } else if (pathname.endsWith('present')) {
      title = 'en cours';
    } else if (pathname.endsWith('future')) {
      title = 'à venir';
    } else if (pathname.endsWith('past')) {
      title = 'terminées';
    }
    return title;
  };

  return (
    <div>
      <Navbar />
      <div className="view">
        <Link to={pathname} className="as-fs">
          <div className="mt-3 title as-fs pointer blue">
            {'<'} Retour aux missions {getTitle()}
          </div>
        </Link>
        {mission && (
          <>
            <div className="mt-1 mb-3">
              <MissionCard mission={mission} />
            </div>
            {[WelcomeBoardState.Draft, WelcomeBoardState.Refused].includes(
              mission.status
            ) && (
              <MissionDetails
                mission={mission}
                onValidated={(selection) => {
                  setSelection(selection);
                  if (
                    selection === Selection.VALIDATE_INTERIM ||
                    selection === Selection.VALIDATE_INDIVIDUAL_CONTRACTOR
                  ) {
                    setModalVisible(true);
                  } else if (selection === Selection.REFUSE) {
                    setModalDeclineVisible(true);
                  }
                }}
              />
            )}
            {(mission.status === WelcomeBoardState.Planned ||
              mission.status === WelcomeBoardState.Applied ||
              mission.status === WelcomeBoardState.Ongoing) && (
              <>
                {isRh() && (
                  <HumanResourcesSection
                    welcomeBoardId={id}
                    mission={mission}
                    refresh={() => setSeed(seed + 1)}
                  />
                )}
                <Applicants mission={mission} key={seed} />
              </>
            )}
            {pathname.endsWith('future') && <ContactCustomerService />}
            {mission.status === WelcomeBoardState.Finished && (
              <ApplicantNotation mission={mission} />
            )}
          </>
        )}
      </div>
      {modalVisible && (
        <PopupInfo
          popupProps={{
            ...PopupValidateMissionProps,
            description: (
              <div>
                Vous êtes sur le point de valider une mission en{' '}
                <span className="pink">
                  {(selection === Selection.VALIDATE_INTERIM
                    ? 'Intérim'
                    : 'Vacataire'
                  ).toUpperCase()}
                </span>
                . Est-ce correct ? 😊
              </div>
            ),
          }}
          onClosed={() => setModalVisible(false)}
          onValidated={() => {
            setValidate(true);
          }}
        />
      )}
      {modalDeclineVisible && (
        <CommentPopup
          setVisibility={setModalDeclineVisible}
          onValidated={(comment) => {
            setComment(comment);
            setValidate(true);
          }}
          commentPopupProps={PopupDeclineMission}
        />
      )}
    </div>
  );
}
