import React, { useState } from "react";
import Popup from "./Popup";
import { PaperPlane } from "../assets/icons/icons";

type CommentPopupProps = {
  title: string;
  placeholder: string;
};

type Props = {
  setVisibility: (isVisible: boolean) => void;
  onValidated: (comment: string) => void;
  commentPopupProps: CommentPopupProps;
  defaultComment?: string;
};

const CommentPopup = ({
  setVisibility,
  onValidated,
  commentPopupProps,
  defaultComment,
}: Props) => {
  const [comment, setComment] = useState(defaultComment);
  return (
    <Popup
      title={commentPopupProps.title}
      textarea={
        <textarea
          style={{
            width: "568px",
            minHeight: "202px",
            borderRadius: ".5rem",
            padding: "1rem",
            border: "none",
            marginBottom: "1rem",
          }}
          placeholder={commentPopupProps.placeholder}
          className="textarea"
          value={comment}
          onChange={(event) => {
            setComment(event.target.value);
          }}
        ></textarea>
      }
      dismiss={() => {
        setVisibility(false);
      }}
      button={
        <button className="btn-main mr-a" onClick={() => onValidated(comment)}>
          Envoyer
          <img
            src={PaperPlane}
            alt="check"
            style={{
              width: "1.75rem",
              marginLeft: "1rem",
            }}
          />
        </button>
      }
      bgColor="#e9e8ec"
      color="black"
      subtitle={undefined}
      image={undefined}
      question={undefined}
      managers={undefined}
      welcomeBoardList={undefined}
      style={undefined}
    />
  );
};

export { CommentPopupProps, CommentPopup };
