import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as Loader } from '../../assets/icons/spinner.svg';
import Cross from '../../assets/icons/cross_white_32.svg';

type ButtonProps = {
  buttonClassName: string;
  label: string;
  labelClassName: string;
  icon: string;
  alt: string;
  type: 'submit' | 'reset' | 'button';
  iconPosition: 'left' | 'right';
  alternateButtonClassName?: string;
  alternateLabel?: string;
  alternateIcon?: string;
  alternateAlt?: string;
};

type Props = {
  buttonProps: ButtonProps;
  disabled?: boolean;
  onClicked?: () => void;
  alternate?: boolean;
  containerStyle?: object;
  loading: boolean;
  error?: boolean;
};

const ButtonLoader = ({
  buttonProps,
  disabled = false,
  onClicked,
  alternate = false,
  containerStyle,
  loading = false,
  error = false,
}: Props) => {
  const [hideIcon, setHideIcon] = useState(true);
  const [status, setStatus] = useState<
    'default' | 'loading' | 'valid' | 'error'
  >('default');
  const clicked = useRef(false);

  const Icon = ({ className }) => (
    <img
      style={{ color: 'yellow' }}
      src={buttonProps.icon}
      alt={!alternate ? buttonProps.alt : buttonProps.alternateAlt}
      className={className}
    />
  );

  const IconCross = ({ className }) => (
    <img
      src={Cross}
      alt={!alternate ? buttonProps.alt : buttonProps.alternateAlt}
      className={className}
    />
  );

  const onPress = () => {
    if (clicked.current === true) return false;
    clicked.current = true;
    onClicked?.();
  };

  useEffect(() => {
    if (loading === false && clicked.current === true) {
      setTimeout(() => {
        setStatus(error ? 'error' : 'valid');
        setTimeout(() => {
          setStatus('default');
          clicked.current = false;
        }, 1200);
      }, 300);

      console.log('> Is loading : ', loading);
    } else if (loading === true) {
      setStatus('loading');
    }
  }, [loading]);

  const getTitle = () => {
    return !alternate ? buttonProps.label : buttonProps.alternateLabel;
  };

  return (
    <button
      className={
        !alternate
          ? buttonProps.buttonClassName
          : buttonProps.alternateButtonClassName
      }
      type={buttonProps.type}
      disabled={disabled}
      onClick={onPress}
      style={{ ...containerStyle }}
    >
      {buttonProps.iconPosition === 'left' && (
        <>
          {status === 'valid' && <Icon className={''} />}
          {status === 'error' && <IconCross className={''} />}
        </>
      )}
      <div className={buttonProps.labelClassName}>
        {status === 'loading' && <Loader className="spinner" />}
        {status === 'default' && getTitle()}
      </div>
      {buttonProps.iconPosition === 'right' && (
        <>
          {status === 'valid' && <Icon className={''} />}
          {status === 'error' && <IconCross className={''} />}
        </>
      )}
    </button>
  );
};

export { ButtonProps, ButtonLoader };
