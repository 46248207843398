import React, { useState, useContext, useRef } from "react";
import { ProtectedImage } from "../../components/ProtectedImage";
import BinImg from "../../assets/icons/binPink/bin.svg";
import { ChangePhoto } from "../../assets/icons/icons";
import { UserContext } from "../../components/context/UserContext";
import { usePostMedia, useDeleteMedia } from "../../api/medias";
import { useEffect } from "react";
import { MediaFormat } from "../../data/medias";

const UploadAvatar = () => {
  const { user, refresh } = useContext(UserContext);
  const fileRef = useRef<HTMLInputElement>(null);
  const [imageUrl, setImageUrl] = useState({ url: "", overwrite: false });
  const [mediaToSync, setMediaToSync] = useState(null);
  const [validatePostMedia, setValidatePostMedia] = useState(false);
  const postMedia = usePostMedia(
    "user",
    user?.id,
    mediaToSync,
    validatePostMedia,
    setValidatePostMedia
  );

  const [validateDeleteMedia, setValidateDeleteMedia] = useState(false);
  const deleteMedia = useDeleteMedia(
    user?.profilePicture?.id,
    validateDeleteMedia,
    setValidateDeleteMedia
  );

  const handleFiles = async (files) => {
    if (files.length > 0) {
      const formData = new FormData();
      formData.append("profilePicture", files[0]);
      setMediaToSync(formData);
      setValidatePostMedia(true);
    }
  };

  useEffect(() => {
    if (postMedia.data || deleteMedia.data) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postMedia, deleteMedia]);

  return (
    user && (
      <div className="picture-area row mt-1">
        <ProtectedImage
          imageId={user.profilePicture?.id}
          alt="profile picture"
          className="mr-1 circle"
          style={{ width: "149px", height: "149px" }}
          imageUrl={imageUrl.url}
          setImageUrl={setImageUrl}
          format={MediaFormat.PROFILE}
        />
        <div
          className="col p-1"
          style={{
            height: "150px",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <div>
            <input
              ref={fileRef}
              type="file"
              style={{ display: "none" }}
              onChange={(e) => {
                handleFiles(e.target.files);
              }}
              // Needed in the case where user selects the same file as before after deletion
              value=""
            />
            <button
              className="btn-profile as-fs"
              onClick={() => fileRef.current?.click()}
            >
              Changer de photo
              <img src={ChangePhoto} alt="" className="ml-1" />
            </button>
          </div>
          <button
            className="btn-profile"
            onClick={() => setValidateDeleteMedia(true)}
          >
            Supprimer votre photo
            <img src={BinImg} alt="" className="ml-05" />
          </button>
        </div>
      </div>
    )
  );
};

export default UploadAvatar;
