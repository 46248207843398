import React, { useEffect, useState } from "react";
import Calendar from "../../../components/Calendar";
import { ArrayValueProps, StringValueProps } from "../valueProps";
import { asDate } from "../../../api/format";
import { Calendar32 } from "../../../assets/icons/icons";
import { Space } from "antd";

type Props = {
  startDateProps: StringValueProps;
  endDateProps: StringValueProps;
  welcomeBoardDatesProps: ArrayValueProps;
};

const DateRange = ({
  startDateProps,
  endDateProps,
  welcomeBoardDatesProps,
}: Props) => {
  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  const onPressCalendar = () => {
    setShowCalendar(true);
  };

  const [sldDates, setSldDates] = useState<Array<string>>(
    welcomeBoardDatesProps.value ?? []
  );

  useEffect(() => {
    if (sldDates.length > 0) {
      // Get startAt and endAt date
      let sorted = sldDates.sort();
      startDateProps.onChange(sorted?.[0]);
      endDateProps.onChange(sorted?.[sorted.length - 1]);
      welcomeBoardDatesProps.onChange(sorted);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sldDates]);

  return (
    <Space direction="vertical" align="center">
      <Space>
        <span className="ml-05 mr-05">Du</span>
        <input
          disabled={true}
          value={startDateProps.value ? asDate(startDateProps.value) : ""}
          type="text"
          placeholder="01/01/2023"
          className={`p-1 br-175 ${startDateProps.value ? "black" : "gray"}`}
          style={{
            fontSize: "1rem",
            height: 48,
            width: 128,
          }}
          readOnly
        />

        <span className="ml-05 mr-05">au </span>
        <input
          disabled={true}
          value={endDateProps.value ? asDate(endDateProps.value) : ""}
          type="text"
          placeholder="15/10/2023"
          className={`p-1 br-175 ${endDateProps.value ? "black" : "gray"}`}
          style={{ fontSize: "1rem", height: 48, width: 128 }}
          readOnly
        />
      </Space>
      <Calendar
        minDate={new Date()}
        type="selection"
        highlightedDates={sldDates}
        dismiss={() => setShowCalendar(false)}
        onDateSelected={(date) => {
          if (sldDates.indexOf(date) !== -1) {
            setSldDates([...sldDates.filter((item) => item !== date)]);
          } else {
            setSldDates([...sldDates, date]);
          }
        }}
      />
    </Space>
  );
};

export { DateRange };
