import { useRequest } from "..";
import { postWithAuth } from "../requests";

const usePostWelcomeBoard = (
  type: string,
  data: any,
  fire: boolean,
  setValidate: (validate: boolean) => void
) => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}welcome_board/${type}`,
    postWithAuth(JSON.stringify(data)),
    (raw: any) => raw,
    fire,
    setValidate
  );
};

export { usePostWelcomeBoard };
