import React from 'react';
import { institutionQualities } from '../../data/moods';
import { getTitleByCategory } from './getTitle';

function StatCard({ stat }) {
  const getQualityLabelByKey = (key) => {
    return (
      institutionQualities.find((quality) => quality.key === key)?.label ?? ''
    );
  };

  return (
    <div
      className="noDecoration shadowEffect bg-fff col br-05 jcc aic pt-3 pb-3"
      style={{
        position: 'relative',
      }}
    >
      <div className="semibold" style={{ fontSize: '24px', color: '#EE7A9B' }}>
        {stat.statCategory !== 'institution_quality_selected'
          ? stat.value
          : getQualityLabelByKey(stat.value)}
      </div>
      <div className="semibold">{getTitleByCategory(stat.statCategory)}</div>
    </div>
  );
}

export default StatCard;
