import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { CirclePinkCrossWhite as CloseImg } from '../assets/icons/icons.ts';

export default function Popup({
  title,
  subtitle,
  image,
  question,
  dismiss,
  textarea,
  button,
  bgColor,
  color,
  managers,
  welcomeBoardList,
  style,
  modalBodyStyle = {},
}) {
  const handleClose = () => {
    dismiss();
  };

  return (
    <Modal show={true} onHide={handleClose} className="popup">
      <div
        className="popup-body"
        style={{ backgroundColor: bgColor, ...style }}
      >
        <Modal.Header>
          <Modal.Title
            style={{
              margin: '.5rem',
              color: color,
              marginRight: 32,
            }}
          >
            {title}
          </Modal.Title>
          {/* <button className='btn-close' onClick={handleClose}> */}
          <img
            src={CloseImg}
            alt="close"
            onClick={handleClose}
            className="mr-05 pointer"
            style={{
              width: '32px',
              height: '32px',
              transform: 'rotate(45deg)',
            }}
          />
          {/* </button> */}
        </Modal.Header>
        <div className="blue ml-05 subtitle mb-05">{subtitle}</div>
        <Modal.Body style={modalBodyStyle}>
          {image}
          {textarea}
          {managers}
          {welcomeBoardList}
          <div className="question">{question}</div>
        </Modal.Body>
        <Modal.Footer>{button}</Modal.Footer>
      </div>
    </Modal>
  );
}
