import React, { useState } from "react";
import SelectRelationPopup from "./SelectRelationPopup";
import { ProtectedImage } from "../../components";
import { CirclePinkCrossWhite } from "../../assets/icons/icons";
import { MediaFormat } from "../../data/medias";
import { Relation } from "../../data/Relation";

type Props = {
  title: string;
  popupTitle: string;
  entries: Relation[];
  selectedEntries: Relation[];
  setSelectedEntries: (selectedEntries: Relation[]) => void;
  mediaFormat: MediaFormat;
  multiple?: boolean;
  increment: () => void;
};

export default function RelationSelector({
  title,
  popupTitle,
  entries,
  selectedEntries,
  setSelectedEntries,
  mediaFormat,
  multiple = true,
  increment,
}: Props) {
  const [showPopup, setShowPopup] = useState(false);
  return (
    <>
      <div className="mt-3 mb-1 h2 semibold">{title}</div>
      <div className="row flexWrap" style={{ gap: "24px", alignItems: "end" }}>
        {selectedEntries.map((entry) => (
          <div className="bg" style={{ alignItems: "flex-end" }} key={entry.id}>
            <div style={{ marginBottom: "4px" }}>{entry.label}</div>
            <ProtectedImage
              imageId={entry.imageId}
              alt="relation"
              format={mediaFormat}
              className="br-05"
              style={{ objectFit: "cover", width: "283px", height: "161px" }}
            />
          </div>
        ))}
        <div
          onClick={() => setShowPopup(!showPopup)}
          style={{
            display: "flex",
            height: "161px",
            width: "283px",
            backgroundColor: "#fff",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="br-05"
        >
          <img
            src={CirclePinkCrossWhite}
            alt="add"
            style={{
              width: "58px",
            }}
          />
        </div>
      </div>
      {showPopup && (
        <SelectRelationPopup
          setPopupVisible={(value: boolean) => setShowPopup(value)}
          title={popupTitle}
          entries={entries}
          selectedEntries={selectedEntries}
          setSelectedEntries={(entries: Relation[]) =>
            setSelectedEntries(entries)
          }
          multiple={multiple}
          increment={increment}
        />
      )}
    </>
  );
}
