import React, { useState, useEffect } from "react";
import CheckIcon from "../assets/icons/check/check.png";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useGetCustomer } from "../api/customer";
import { ProtectedImage } from "./ProtectedImage";
import { MediaFormat } from "../data/medias";
import { FormInput } from "../components/FormInput";
import { Formik, Form } from "formik";
import validationSchema from "../pages/profile/managers/validationSchema";
import { inputs } from "../pages/profile/managers/inputs";
import { TextError } from "../components/TextError/TextError";
import RelationSelector from "../pages/profile/RelationSelector";
import { useGetInstitutions } from "../api/institutions";
import { usePostOrPatchCustomer } from "../api/customer";
import { Role } from "../api/enums";
import { relationMapper } from "../data/Relation";

export default function ManagerProfile() {
  const { id } = useParams();
  const [form, setForm] = useState(null);
  const [validate, setValidate] = useState(false);
  const { data: submitResult } = usePostOrPatchCustomer(
    id,
    form,
    validate,
    setValidate
  );
  const { data: customer } = useGetCustomer(id ?? null);
  const [page, setPage] = useState(1);
  const [validateInsitutions, setValidateInstitutions] = useState(true);
  const { data: institutions } = useGetInstitutions(
    page,
    validateInsitutions,
    setValidateInstitutions
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (submitResult) {
      navigate("/profile/proximity-managers");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitResult]);

  return (
    (!id || customer) &&
    institutions && (
      <div
        className="ml-2 flex-2 pl-2"
        style={{ borderLeft: "1px solid lightgray" }}
      >
        <h1
          className="pointer"
          onClick={() => navigate("/profile/proximity-managers")}
        >
          {"<"} Retour à vos managers de proximité 🖇
        </h1>
        <Formik
          initialValues={{
            firstname: customer?.firstname ?? "",
            lastname: customer?.lastname ?? "",
            email: customer?.email ?? "",
            phone: customer?.phone ?? "",
            institutions: relationMapper(customer?.institutions ?? []),
          }}
          onSubmit={(values) => {
            const mappedForm = {
              ...values,
              institutions: values.institutions.map(
                (institution) => institution.id
              ),
              roles: [Role.MP],
            };
            setForm(mappedForm);
            setTimeout(() => setValidate(true));
          }}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, setFieldTouched, errors }) => {
            const onChange = (key, value) => {
              setFieldValue(key, value);
              setTimeout(() => setFieldTouched(key, true));
            };
            return (
              <Form>
                <h2 className=" h2 bold">Photo de profil</h2>
                <div className="picture-area row">
                  <div>
                    <ProtectedImage
                      imageId={customer?.profilePicture?.id}
                      alt="avatar"
                      className="mr-1 circle"
                      format={MediaFormat.PROFILE}
                      style={{ width: "149px", height: "149px" }}
                    />
                  </div>
                </div>
                <div
                  className="row"
                  style={{ gap: "28px", alignItems: "start" }}
                >
                  <div>
                    <FormInput
                      inputProps={inputs.firstname}
                      value={values.firstname}
                      setValue={(value) => onChange("firstname", value)}
                    />
                    <TextError name="firstname" />
                  </div>
                  <div>
                    <FormInput
                      inputProps={inputs.lastname}
                      value={values.lastname}
                      setValue={(value) => onChange("lastname", value)}
                    />
                    <TextError name="lastname" />
                  </div>
                </div>
                <FormInput
                  inputProps={inputs.email}
                  value={values.email}
                  setValue={(value) => onChange("email", value)}
                />
                <TextError name="email" />
                <FormInput
                  inputProps={inputs.phone}
                  value={values.phone}
                  setValue={(value) => onChange("phone", value)}
                />
                <TextError name="phone" />
                <RelationSelector
                  title="Son établissement d'affectation"
                  popupTitle="Sélectionnez son établissement d'affectation"
                  entries={institutions.map((institution) => {
                    return {
                      id: institution.id,
                      imageId: institution.photos?.find(() => true)?.id ?? null,
                      label: institution.name,
                    };
                  })}
                  selectedEntries={values.institutions}
                  setSelectedEntries={(value) => {
                    onChange("institutions", value);
                  }}
                  multiple={false}
                  increment={() => {
                    setPage(page + 1);
                    setValidateInstitutions(true);
                  }}
                  setPage={page}
                />
                <TextError name="institutions" />
                <div className="row mt-2 mb-2">
                  <div
                    className="mission-normal semibold pointer"
                    style={{ textDecoration: "underline" }}
                    onClick={() => navigate("/profile/proximity-managers")}
                  >
                    Annuler
                  </div>
                  <button type="submit" className="btn-main ml-2 mw-fc">
                    <div>Valider</div>
                    <img src={CheckIcon} alt="" className="ml-1" />
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    )
  );
}
