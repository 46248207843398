import React from "react";

function Stepper({ currentStep }) {
  return (
    <>
      <div className="row mt-2">
        <div className={currentStep === 0 ? "green bold flex-1" : "green flex-1"}>
          1. Informations générales
        </div>
        <div
          className={
            currentStep === 0
              ? "grayBar flex-1"
              : currentStep === 1
              ? "green bold flex-1"
              : "green3 flex-1"
          }
        >
          2. Détails de la mission
        </div>
        <div className={
          currentStep === 2 
          ? "green bold flex-1" 
          : "grayBar flex-1"
          }
        >
          3. Publier la mission
        </div>
      </div>
      <div className="row mt-1 br-025">
        <div
          id="stepper-stepOne"
          className={
            currentStep === 0 
            ? "bg-green" 
            : "bg-green3"
          }
          style={{
            width: "calc(100% / 3 )",
            height: ".625rem",
            borderRadius: ".5rem 0 0 .5rem ",
          }}
        ></div>
        <div
          id="stepper-stepTwo"
          className={
            currentStep === 0
              ? "bg-grayBar"
              : currentStep === 1
              ? "bg-green"
              : "bg-green3"
          }
          style={{ width: "calc(100% / 3 )", height: ".625rem" }}
        ></div>
        <div
          id="stepper-stepThree"
          className={currentStep === 2 ? "bg-green" : "bg-grayBar"}
          style={{
            width: "calc(100% / 3 )",
            height: ".625rem",
            borderRadius: " 0 .5rem .5rem 0",
          }}
        ></div>
      </div>
    </>
  );
}

export default Stepper;
