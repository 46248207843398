import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import ApplicantProfileNotation from '../components/ApplicantProfileNotation';
import CheckImg from '../assets/icons/check/check.png';
import { useGetApplicationsByWelcomeBoard } from '../api/applications';
import { postApplicationNote } from '../api/applications';
import { UserContext } from '../components/context/UserContext';

export default function ApplicantNotation({ mission }) {
  let { id } = useParams();
  const [validate, setValidate] = useState(true);
  const { data } = useGetApplicationsByWelcomeBoard(
    id,
    null,
    validate,
    setValidate
  );
  const [applicants, setApplicants] = useState(null);
  const [loading, setLoading] = useState(false);
  const { isRh } = useContext(UserContext);

  const filteredApplicants =
    applicants?.filter((applicant) => applicant.state !== 'canceled') ?? [];

  useEffect(() => {
    if (data) {
      setApplicants(data);
    }
  }, [data]);

  return (
    filteredApplicants && (
      <>
        <div className="mt-2 bold">
          {filteredApplicants.length} intérimaire
          {filteredApplicants.length > 1 && 's'} à {isRh() ? 'payer' : 'noter'}
        </div>
        {filteredApplicants.map((applicant, i) => {
          return (
            <ApplicantProfileNotation
              key={i}
              applicant={applicant}
              updateApplicant={(updatedApplicant) => {
                const updatedApplicants = [];
                filteredApplicants.forEach((applicant) => {
                  if (applicant.id === updatedApplicant.id) {
                    updatedApplicants.push(updatedApplicant);
                  } else {
                    updatedApplicants.push(applicant);
                  }
                });
                setApplicants(updatedApplicants);
              }}
              availableCapacitations={mission.capacitations}
              isRh={isRh()}
            />
          );
        })}
        {!isRh() && (
          <button
            className="btn-main  mw-fc"
            disabled={
              loading ||
              filteredApplicants.filter((applicant) => !applicant.noted)
                .length === 0
            }
            onClick={async () => {
              setLoading(true);
              await postApplicationNote(
                filteredApplicants.filter((applicant) => !applicant.noted)
              );
              setValidate(true);
              setLoading(false);
            }}
          >
            Valider
            <img src={CheckImg} alt="" className="ml-1" />
          </button>
        )}
      </>
    )
  );
}
