const useFetch = (endpoint: string, auth: boolean = false) => {
  let token: string | undefined;
  if (auth) {
    token = localStorage.getItem('tkcare_token');
    if (token === null) {
      token = sessionStorage.getItem('tkcare_token');
    }
  }

  const defaultHeader = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: auth && `Bearer ${token}`,
  };

  const customFetch = async (
    url,
    method = 'GET',
    body: boolean | any = false,
    headers = defaultHeader
  ) => {
    const options = {
      method,
      headers,
      body: null,
    };

    if (body) options.body = JSON.stringify(body);

    const response = await fetch(url, options);
    const data =
      defaultHeader['Content-Type'] === 'application/json'
        ? await response.json()
        : await response.text();

    return {
      status: response.status,
      data: data,
      error: {
        error: !response.ok && data,
      },
      path: response.url,
    };
  };
  const patch = async (body = false) => {
    return await customFetch(
      `${process.env.REACT_APP_API_BASE_URL}${endpoint}`,
      'PATCH',
      body
    );
  };

  // const post = (body = false) => {
  //   if (!body) throw new Error("to make a post you must provide a     body");
  //   return customFetch(endpoint, "POST", body);
  // };
  // const put = (id = false, body = false) => {
  //   if (!id || !body)
  //     throw new Error("to make a put you must provide the id and the   body");
  //   const url = `${endpoint}/${id}`;
  //   return customFetch(url, "PUT", body);
  // };
  // const put = (id = false, body = false) => {
  //   if (!id || !body)
  //     throw new Error("to make a put you must provide the id and the   body");
  //   const url = `${endpoint}/${id}`;
  //   return customFetch(url, "PUT", body);
  // };
  // const del = (id = false) => {
  //   if (!id)
  //     throw new Error("to make a delete you must provide the id and the body");
  //   const url = `${endpoint}/${id}`;
  //   return customFetch(url, "DELETE");
  // };
  return {
    patch,
  };
};
export default useFetch;
