import React from "react";
import CircleInputBlock from "./CircleInputBlock";
import { Bed } from "../../../assets/icons/icons";

type Props = {
  value: string;
  onChange: (value: string) => void;
};

const BedBlock = ({ value, onChange }: Props) => (
  <CircleInputBlock
    label="lits"
    value={value}
    onChange={onChange}
    blockProps={{
      label: "Nombre de lits",
      alt: "bed",
      icon: Bed,
    }}
  />
);

export default BedBlock;
