import { useRequest } from '..';
import { getWithAuth, patchWithAuth, postWithAuth } from '../requests';
import { toUppercase, toFullName } from '../format';
import { Relation } from '../../data/Relation';

const customerMapper = (raw: any) => {
  return {
    ...raw,
    firstname: toUppercase(raw.firstname),
    lastname: toUppercase(raw.lastname),
    fullname: toFullName(raw.firstname, raw.lastname),
  };
};

const useGetCustomer = (id) => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}customer/${id}`,
    getWithAuth(),
    customerMapper,
    id !== null
  );
};

const mapper = (raw: any) => {
  return {
    ...raw,
    fullname: toFullName(raw.firstname, raw.lastname),
  };
};

const useGetCustomers = (
  page: number = 1,
  validate: boolean = true,
  setValidate: (validate: boolean) => void = () => {}
) => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}customer?page=${page}`,
    getWithAuth(),
    (raw: any) => raw.map((customer) => mapper(customer)),
    validate,
    setValidate
  );
};

const mapperForInstitution = (raw: any): Relation => {
  return {
    id: raw.id,
    imageId: raw.profilePicture?.id ?? null,
    label: toFullName(raw.firstname, raw.lastname),
  };
};

const useGetCustomersForInstitution = (
  page: number,
  validate: boolean,
  setValidate: (validate: boolean) => void
) => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}customer?page=${page}`,
    getWithAuth(),
    (raw: any) => raw.map((customer) => mapperForInstitution(customer)),
    validate,
    setValidate
  );
};

const useGetCustomersCount = () => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}customer/count`,
    getWithAuth(),
    (raw: any) => raw,
    true
  );
};

const usePostOrPatchCustomer = (
  customerId,
  body: any,
  validate: boolean,
  setValidate: (validate: boolean) => void
) => {
  const serializedBody = JSON.stringify(body);
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}customer${
      customerId ? `/${customerId}` : ''
    }`,
    customerId ? patchWithAuth(serializedBody) : postWithAuth(serializedBody),
    (raw: any) => raw,
    validate,
    setValidate
  );
};

const useGetApplicantsByName = (
  search: string,
  page: number,
  validate: boolean,
  setValidate: (validate: boolean) => void
) => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}substitute?search=${search
      .replace(/\s/g, '')
      .toLowerCase()}&page=${page}`,
    getWithAuth(),
    (raw: any) => raw.map((candidate) => mapper(candidate)),
    validate,
    setValidate
  );
};

export {
  useGetCustomer,
  usePostOrPatchCustomer,
  useGetCustomers,
  useGetCustomersForInstitution,
  useGetCustomersCount,
  useGetApplicantsByName,
};
