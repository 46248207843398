import React, {
  createContext,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import { usePostWelcomeBoard } from "../../api/missions";
import { usePostMedia } from "../../api/medias";
import moment from "moment";
import { toBlob } from "../../api/format";
import { UserContext } from "./UserContext";
import { ContractType } from "../../api/enums";

export const CreateMissionContext = createContext({});

export const CreateMissionProvider = ({ children }) => {
  const { isRh } = useContext(UserContext);
  const [missionType, setMissionType] = useState();
  const [firstStepValues, setFirstStepValues] = useState();
  // Needed to deal with stale closure issue concerning submitForm arrow function
  const ref = useRef({}).current;
  ref.firstStepValues = firstStepValues;
  const [secondStepValues, setSecondStepValues] = useState();
  const [mergedValues, setMergesValues] = useState();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [validateW, setValidateW] = useState(false);
  const postWelcomeBoard = usePostWelcomeBoard(
    firstStepValues?.isHospital ? "hospital" : "laboratory",
    mergedValues,
    validateW,
    setValidateW
  );
  const [mediaToSync, setMediaToSync] = useState(null);
  const [validateM, setValidateM] = useState(false);
  const postMedia = usePostMedia(
    "welcome_board",
    postWelcomeBoard?.data?.id,
    mediaToSync,
    validateM,
    setValidateM
  );

  useEffect(() => {
    if (postWelcomeBoard.loading === true) {
      setLoading(true);
    } else if (postWelcomeBoard.error !== null) {
      setError(postWelcomeBoard.error);
    } else if (postWelcomeBoard.data !== null) {
      setValidateM(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postWelcomeBoard]);

  useEffect(() => {
    const handleMedia = async () => {
      if (postMedia.error !== null) {
        setError(postMedia.error);
      } else if (postMedia.data !== null) {
        if (
          firstStepValues.firstPicture.url !== "" &&
          firstStepValues.secondPicture.url !== "" &&
          mediaToSync.get("photos[2]") === null
        ) {
          const formData = new FormData();
          const blob = await toBlob(firstStepValues.secondPicture.url);
          formData.append("photos[2]", blob);
          setMediaToSync(formData);
          setTimeout(() => setValidateM(true));
        } else {
          setLoading(false);
          setData(postWelcomeBoard.data);
        }
      }
    };
    handleMedia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postMedia]);

  function clear() {
    setMissionType(null);
    setFirstStepValues(null);
    setSecondStepValues(null);
    setData(null);
  }

  const submitForm = async () => {
    const hospitalValues = firstStepValues.isHospital
      ? {
          dailyRoutines: secondStepValues.dailyRoutines.map((dailyRoutine) => {
            return {
              startAt: moment(dailyRoutine.startHour, "h:mm").toISOString(),
              endAt: moment(dailyRoutine.endHour, "h:mm").toISOString(),
              dailyTask: dailyRoutine.dailyTask.key,
            };
          }),
        }
      : {};
    const rhValues = isRh()
      ? {
          contractType: ContractType.FullTime,
        }
      : {};
    const mergedValues = {
      ...ref.firstStepValues,
      ...secondStepValues,
      service: firstStepValues.service.key,
      occupation: firstStepValues.occupation.key,
      startAt: moment(secondStepValues.startDate, "YYYY-MM-DD")
        .set({
          hour: secondStepValues.startHour.substring(0, 2),
          minute: secondStepValues.startHour.substring(3, 5),
          second: 0,
          millisecond: 0,
        })
        .toISOString(),
      endAt: moment(secondStepValues.endDate, "YYYY-MM-DD")
        .set({
          hour: secondStepValues.endHour.substring(0, 2),
          minute: secondStepValues.endHour.substring(3, 5),
          second: 0,
          millisecond: 0,
        })
        .toISOString(),
      capacitations: secondStepValues.capacitations
        .filter((capacitation) => capacitation)
        .map((capacitation) => capacitation.key),
      staffs: secondStepValues.staffs
        .filter((staff) => staff.occupation && staff.number)
        .map((staff) => {
          return { ...staff, occupation: staff.occupation.key };
        }),
      institution: firstStepValues.institution.id,
      ...hospitalValues,
      ...rhValues,
      welcomeBoardDates: secondStepValues.welcomeBoardDates.map((item) => {
        return {
          date: moment(item)
            .set({
              hour: secondStepValues.startHour.substring(0, 2),
              minute: secondStepValues.startHour.substring(3, 5),
              second: 0,
              millisecond: 0,
            })
            .toISOString(),
        };
      }),
    };

    delete mergedValues.firstPicture;
    delete mergedValues.secondPicture;
    delete mergedValues.startDate;
    delete mergedValues.endDate;
    delete mergedValues.startHour;
    delete mergedValues.endHour;
    delete mergedValues.selectedBookmark;
    delete mergedValues.isHospital;
    setMergesValues(mergedValues);
    setError(null);
    const formData = new FormData();
    const blob = await toBlob(
      firstStepValues.firstPicture.url
        ? firstStepValues.firstPicture.url
        : firstStepValues.secondPicture.url
    );
    formData.append("photos[1]", blob);
    setMediaToSync(formData);
    setTimeout(() => setValidateW(true));
  };

  return (
    <CreateMissionContext.Provider
      value={{
        missionType,
        setMissionType,
        firstStepValues,
        setFirstStepValues,
        secondStepValues,
        setSecondStepValues,
        clear,
        submitForm,
        data,
        loading,
        error,
      }}
    >
      {children}
    </CreateMissionContext.Provider>
  );
};
