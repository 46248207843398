import React, { useState } from "react";
import { PopUpCancelMission } from "../../components/PopupCancelMission";

type Props = {
  cancel: () => void;
};

const CancelButton = ({ cancel }: Props) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  return (
    <>
      <div
        className="mission-normal underlined semibold pointer"
        style={{ fontSize: "1.25rem" }}
        onClick={() => setIsPopupVisible(true)}
      >
        Annuler
      </div>
      <PopUpCancelMission
        cancel={cancel}
        isPopupVisible={isPopupVisible}
        dismiss={() => setIsPopupVisible(false)}
      />
    </>
  );
};

export default CancelButton;
