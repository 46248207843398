import React, { useContext, useEffect, useState } from "react";
import CheckIcon from "../assets/icons/check/check.png";
import { UserContext } from "../components/context/UserContext";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumb } from "./Breadcrumb";
import {
  useGetInstitution,
  usePostOrPatchInstitution,
} from "../api/institutions";
import { Formik, Form } from "formik";
import { UploadImage } from "./upload-image";
import { inputs } from "../pages/profile/institutions/inputs";
import { FormInput } from "./FormInput";
import RelationSelector from "../pages/profile/RelationSelector";
import validationSchema from "../pages/profile/institutions/validationSchema";
import { TextError, ManualTextError } from "./TextError/TextError";
import { postMedias, deleteMedias } from "../api/medias";
import { toBlob } from "../api/format";
import { useGetCustomersForInstitution } from "../api/customer";
import { Hospital24, Laboratory32 } from "../assets/icons/icons";
import { relationMapper } from "../data/Relation";

export default function Establishment() {
  const { user, isRh, isHospital } = useContext(UserContext);
  const { id } = useParams();
  const { data: institution } = useGetInstitution(id);
  const [validate, setValidate] = useState(false);
  const [rawForm, setRawForm] = useState(null);
  const [form, setForm] = useState(null);
  const { data: submitResult, error } = usePostOrPatchInstitution(
    id,
    form,
    validate,
    setValidate
  );
  const [page, setPage] = useState(1);
  const [validateCustomers, setValidateCustomers] = useState(isRh());
  const { data: customers } = useGetCustomersForInstitution(
    page,
    validateCustomers,
    setValidateCustomers
  );
  const navigate = useNavigate();
  // Global instead of usePostOrPatchInstitution one to handle medias
  const [loading, setLoading] = useState(false);

  const handleMedias = async () => {
    const promises = rawForm.photos.map(async (photo, index) => {
      if (photo.url !== "" && photo.overwrite) {
        const blob = await toBlob(photo.url);
        return {
          key: `photos[${index + 1}]`,
          value: blob,
        };
      }
      return null;
    });
    const mediasToDelete = rawForm.photos
      .map((photo, index) => {
        if (photo.overwrite && institution) {
          return institution.photos[index]?.id ?? null;
        }
        return null;
      })
      .filter((id) => id !== null);
    const blobs = await Promise.all(promises);
    const formData = new FormData();
    blobs.forEach((blob) => {
      if (blob !== null) {
        formData.append(blob.key, blob.value);
      }
    });
    await postMedias("institution", id ?? submitResult.id, [formData]);
    await deleteMedias(mediasToDelete);
    setLoading(false);
    navigate(`/profile/establishments`);
  };

  useEffect(() => {
    if (submitResult) {
      handleMedias();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitResult]);

  return (
    (institution || !id) &&
    user &&
    (customers || !isRh()) && (
      <Formik
        initialValues={{
          photos: [
            { url: "", overwrite: false },
            { url: "", overwrite: false },
          ],
          name: institution?.name ?? "",
          phone: institution?.phone ?? "",
          email: institution?.email ?? "",
          address: {
            label: institution?.address?.label ?? "",
          },
          employees: relationMapper(institution?.employees ?? []),
        }}
        validationSchema={validationSchema(isRh())}
        onSubmit={(values) => {
          setLoading(true);
          setRawForm(values);
          const mappedForm = {
            ...values,
            employees: [
              ...values.employees.map((employee) => employee.id),
              user.id,
            ],
          };
          if (id) {
            delete mappedForm.type;
          }
          delete mappedForm.photos;
          setForm(mappedForm);
          setTimeout(() => (isRh() ? setValidate(true) : handleMedias()));
        }}
      >
        {({ values, setFieldValue, setFieldTouched, errors }) => {
          const onChange = (key, value) => {
            setFieldValue(key, value);
            setTimeout(() => setFieldTouched(key, true));
          };
          return (
            <Form>
              <div
                className="ml-3 flex-2 pl-2"
                style={{ borderLeft: "1px solid lightgray" }}
              >
                {isRh() ? (
                  <Breadcrumb
                    onClick={() => navigate("/profile/establishments")}
                    breadcrumb="Retour à vos établissements"
                  />
                ) : (
                  <div className="h1 bold">Votre établissement ‍🔬</div>
                )}
                <div className="mt-15 h2 semibold">
                  {isRh()
                    ? "Photographies de l'établissement"
                    : "Vos photographies d'établissement"}
                </div>
                <div className="row mt-1 mr-1">
                  <UploadImage
                    imageId={institution?.photos?.[0]?.id ?? null}
                    imageUrl={values.photos[0]}
                    setImageUrl={(value) => {
                      onChange("photos", [value, values.photos[1]]);
                    }}
                    editable={!isRh() || !id}
                  />
                  <UploadImage
                    imageId={institution?.photos?.[1]?.id ?? null}
                    imageUrl={values.photos[1]}
                    setImageUrl={(value) => {
                      onChange("photos", [values.photos[0], value]);
                    }}
                    className="ml-1"
                    editable={!isRh() || !id}
                  />
                </div>
                <TextError name="photos" />
                <div className="h2 semibold mt-2">
                  {isRh()
                    ? "Informations générales"
                    : "Vos informations générales"}
                </div>
                <div>
                  <FormInput
                    inputProps={{
                      ...inputs.name,
                      icon: isHospital ? Hospital24 : Laboratory32,
                    }}
                    value={values.name}
                    setValue={(value) => onChange("name", value)}
                    disabled={!isRh()}
                  />
                  <TextError name="name" />
                  <FormInput
                    inputProps={inputs.address}
                    value={values.address.label}
                    setValue={(value) => onChange("address", { label: value })}
                    disabled={!isRh()}
                  />
                  <TextError name="address" />
                  <FormInput
                    inputProps={inputs.phone}
                    value={values.phone}
                    setValue={(value) => onChange("phone", value)}
                    disabled={!isRh()}
                  />
                  <TextError name="phone" />
                  <FormInput
                    inputProps={inputs.email}
                    value={values.email}
                    setValue={(value) => onChange("email", value)}
                    disabled={!isRh()}
                  />
                  <TextError name="email" />
                </div>
                {isRh() && (
                  <>
                    <RelationSelector
                      title="Vos managers de proximité"
                      popupTitle="Sélectionnez vos managers de proximité 🖇"
                      entries={customers}
                      selectedEntries={values.employees}
                      setSelectedEntries={(value) => {
                        onChange("employees", value);
                      }}
                      increment={() => {
                        setPage(page + 1);
                        setValidateCustomers(true);
                      }}
                      setPage={page}
                    />
                    <TextError name="employees" />
                  </>
                )}
                <div className="row mt-3">
                  <div
                    className="mission-normal pointer"
                    style={{ textDecoration: "underline" }}
                    onClick={() => navigate("/profile/establishments")}
                  >
                    Annuler
                  </div>
                  <button
                    type="submit"
                    className="btn-main ml-2 mw-fc"
                    disabled={loading}
                  >
                    <div>Valider</div>
                    <img src={CheckIcon} alt="" className="ml-1" />
                  </button>
                </div>
                <ManualTextError message={error} />
              </div>
            </Form>
          );
        }}
      </Formik>
    )
  );
}
