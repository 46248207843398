import React from "react";
import { Block } from "../Block";
import { Comment24 } from "../../../assets/icons/icons";
import { TextArea } from "../../../components";

type Props = {
  value: string;
  onChange: (value: string) => void;
};

const DescriptionBlock = ({ value, onChange }: Props) => (
  <Block icon={Comment24} label="Motif de remplacement" alt="comment">
    <TextArea placeholder="" value={value} onChange={onChange} />
  </Block>
);

export { DescriptionBlock };
