import React, { useContext } from "react";
import Navbar from "../components/Navbar";
import { MyBarGraph } from "./stats";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { UserContext } from "../components/context/UserContext";
import { useGetStatistics } from "../api/statistics";
import { getTitle } from "./stats/getTitle";

function BoardGraphDetail() {
  const { category } = useParams();
  const [searchParams] = useSearchParams();
  const secondCategory = searchParams.get("secondCategory");
  console.log(secondCategory);
  const { selectedInstitution } = useContext(UserContext);
  const { data: currentYear } = useGetStatistics(
    category,
    new Date().getFullYear().toString(),
    selectedInstitution
  );
  const { data: pastYear } = useGetStatistics(
    category,
    (new Date().getFullYear() - 1).toString(),
    selectedInstitution
  );
  const { data: secondCurrentYear } = useGetStatistics(
    secondCategory,
    new Date().getFullYear().toString(),
    selectedInstitution
  );
  const { data: secondPastYear } = useGetStatistics(
    secondCategory,
    (new Date().getFullYear() - 1).toString(),
    selectedInstitution
  );

  return (
    <div className="">
      <Navbar />
      <>
        <div className="view">
          <Link
            to="/board"
            className="noDecoration blue h1 mt-3 bold mr-a"
            style={{ marginRight: "auto" }}
            id="backToBoardLink"
          >
            {" < "}Retour à vos statistiques du mois 📈
          </Link>
          {currentYear && (
            <>
              <div className="bold h2 as-fs mt-3">
                {getTitle(currentYear, category)}
                {secondCategory
                  ? " / " + getTitle(secondCurrentYear, secondCategory)
                  : ""}
                {" ce mois-ci"}
              </div>
              <div className="mr-a semibold mt-4 mb-2">
                Année {new Date().getFullYear()}
              </div>
              <MyBarGraph stats={currentYear} secondStats={secondCurrentYear} />
            </>
          )}
          {pastYear && (
            <>
              <div className="mr-a semibold mt-4 mb-2">
                Année {new Date().getFullYear() - 1}
              </div>
              <MyBarGraph stats={pastYear} secondStats={secondPastYear} />
            </>
          )}
        </div>
      </>
    </div>
  );
}

export default BoardGraphDetail;
