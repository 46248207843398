import React from "react";
import { Block } from "../Block";
import { BlockProps } from "./blockProps";
import CircleInput from "./CircleInput";

type Props = {
  value: string;
  blockProps: BlockProps;
  label: string;
  onChange: (value: string) => void;
};

const CircleInputBlock = ({ value, blockProps, label, onChange }: Props) => (
  <Block icon={blockProps.icon} label={blockProps.label} alt={blockProps.alt}>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <CircleInput value={value} placeholder={"30"} onChange={onChange} />
      <div>{label}</div>
    </div>
  </Block>
);

export default CircleInputBlock;
