import React, { useEffect, useMemo, useRef, useState } from "react";
import ApplicantInfo from "./ApplicantInfo";
import { Calendar32, MagGlass, Pen } from "../assets/icons/icons";
import { CommentPopup } from "./CommentPopup";
import { PopupAbsenceComment, PopupDailyComment } from "./popup/popupsProps";
import BadgeInfo from "./BadgeInfo";
import {
  getCandidateArrivalProps,
  getCandidateDepartureProps,
} from "../pages/recruitment/badgeInfo";
import {
  usePatchApplicationDateWithDailyComment,
  usePatchApplicationHours,
  usePatchApplicationWithAbsenceComment,
} from "../api/applications";
import { asDate, asTinyDateSlash, asHour } from "../api/format";
import { ApplicationDateState, WelcomeBoardState } from "../api/enums";
import Calendar from "./Calendar";
import { Button, validateHours } from "./buttons/buttons";
import moment from "moment";
import { ButtonLoader } from "./buttons/ButtonLoader";

export default function SignInSystem({
  applicant,
  refreshApplicants,
  theoricalStartDate,
  theoricalEndDate,
}) {
  const today = moment();
  const endOnNextDay = useRef(0);
  const finishedAskedDates = applicant?.askedDates?.filter((e) =>
    [
      ApplicationDateState.Finished,
      ApplicationDateState.Accepted,
      ApplicationDateState.Ongoing,
    ].includes(e.state)
  );
  const [calendarVisibility, setCalendarVisibility] = useState(false);
  const [askedDate, setAskedDate] = useState(filterDefaultAskedDate());

  const [selectedDate, setSelectedDate] = useState(askedDate?.date);
  const arrivalDate =
    askedDate?.substituteCheckinDatetime ??
    askedDate?.customerCheckinDatetime ??
    theoricalStartDate;
  const departureDate =
    askedDate?.substituteCheckoutDatetime ??
    askedDate?.customerCheckoutDatetime ??
    theoricalEndDate;
  const [arrivalHour, setArrivalHour] = useState(null);
  const [departureHour, setDepartureHour] = useState(null);
  const [dailyComment, setDailyComment] = useState(null);
  const [absenceComment, setAbsenceComment] = useState(null);
  const [displayDailyCommentPopup, setDisplayDailyCommentPopup] =
    useState(false);
  const [displayAbsenceCommentPopup, setDisplayAbsenceCommentPopup] =
    useState(false);
  const [validateDailyCommentPatch, setValidateDailyCommentPatch] =
    useState(false);
  const [validateAbsenceCommentPatch, setValidateAbsenceCommentPatch] =
    useState(false);
  const [validatePatchHours, setValidatePatchHours] = useState(false);
  const { data: patchDailyCommentResult } =
    usePatchApplicationDateWithDailyComment(
      askedDate?.id,
      dailyComment,
      validateDailyCommentPatch,
      setValidateDailyCommentPatch
    );
  const { data: patchAbsenceCommentResult } =
    usePatchApplicationWithAbsenceComment(
      applicant.id,
      absenceComment,
      validateAbsenceCommentPatch,
      setValidateAbsenceCommentPatch
    );
  const {
    data: patchHoursResult,
    error: patchHoursError,
    loading: patchHoursLoading,
  } = usePatchApplicationHours(
    askedDate?.id,
    arrivalDate,
    departureDate,
    arrivalHour,
    departureHour,
    validatePatchHours,
    setValidatePatchHours
  );

  // ------------------------- LIFE CYCLE

  useEffect(() => {
    if (patchHoursResult) {
      refreshApplicants();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchHoursResult]);

  useEffect(() => {
    if (patchDailyCommentResult) {
      setDisplayDailyCommentPopup(false);
    }
  }, [patchDailyCommentResult]);

  useEffect(() => {
    if (patchAbsenceCommentResult) {
      setDisplayAbsenceCommentPopup(false);
    }
  }, [patchAbsenceCommentResult]);

  useEffect(() => {
    setSelectedDate(askedDate?.date);
    setArrivalHour(moment(arrivalDate).format("HH:mm"));
    setDepartureHour(moment(departureDate).format("HH:mm"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [askedDate]);

  // ------------------------- HELPERS

  /**
   * Find default askedDate to display
   * On page load :
   * - display the current day askedDate is there is any
   * - otherwise display the closest previous askedDate
   * @returns
   */
  function filterDefaultAskedDate() {
    // Find today askedDate
    let result = {};

    // ..get closest
    finishedAskedDates?.forEach((e) => {
      if (!result?.date) {
        result = e;
      } else {
        result =
          moment(e.date).isSameOrBefore(today, "day") &&
          moment(e.date).isAfter(result.date)
            ? e
            : result;
      }
    });

    if (!!result?.date) {
      // Check for next day end at
      const startAtTransform = moment(theoricalStartDate).set({
        year: moment(result.date).year(),
        month: moment(result.date).month(),
        date: moment(result.date).date(),
      });

      const endAtTransformed = moment(theoricalEndDate).set({
        year: moment(result.date).year(),
        month: moment(result.date).month(),
        date: moment(result.date).date(),
      });

      result = {
        ...result,
        startAt: startAtTransform.toISOString(true),
        endAt: endAtTransformed.toISOString(true),
      };

      // end at is the next day, add +1 day
      if (endAtTransformed.isBefore(startAtTransform)) {
        endOnNextDay.current = 1;
        console.log("> AskedDate finish the next day..");
      }
    }

    // if (moment(theoricalEndDate)) console.log({ result });

    // const checkAskedDateEnding = () => {
    //   console.log("> Start at : ", mission.startAt);
    //   console.log("> End at : ", mission.endAt);
    //   const endAtSameDay = moment(mission.endAt).set({
    //     y: moment(mission.startAt).year(),
    //     M: moment(mission.startAt).month(),
    //     d: moment(mission.startAt).date(),
    //   });

    //   console.log("> End at same day : ", endAtSameDay.toISOString());
    // };

    // console.log({ applicant });
    // const result =
    //   applicant.state === WelcomeBoardState.Finished ||
    //   asDate(item.date) === asDate(new Date().toDateString());
    // return result;

    return result;
  }

  /**
   * Condition defining whether or not VALIDER button should be display
   */
  const displayBtnValid = useMemo(
    () =>
      (applicant.state === WelcomeBoardState.Finished ||
        applicant.state === WelcomeBoardState.Ongoing) &&
      askedDate.state === ApplicationDateState.Finished,
    [applicant, askedDate]
  );

  const getAvailableAskedDateForCheckin = useMemo(() => {
    return applicant?.askedDates
      ?.filter((e) =>
        [
          ApplicationDateState.Finished,
          ApplicationDateState.Accepted,
          ApplicationDateState.Ongoing,
        ].includes(e.state)
      )
      ?.map((askedDate) => asDate(askedDate.date));
  }, [applicant]);

  // ------------------------- ACTIONS

  const onCalendarDateSelected = (date) => {
    setAskedDate(
      applicant.askedDates.find((askedDate) =>
        moment(askedDate.date).isSame(date, "day")
      )
    );

    setCalendarVisibility(false);
  };

  // ------------------------- RENDERS

  return askedDate ? (
    <ApplicantInfo applicant={applicant} patchHoursError={patchHoursError}>
      <>
        {/* ARRIVAL & DEPARTURE */}
        <div
          className="row"
          style={{
            gap: "2rem",
            flex: 1,
            alignItems: "center",
            justifyContent: "end",
            marginRight: "2rem",
          }}
        >
          {/* Arrival time */}
          <div className="col jcc ">
            {selectedDate && (
              <>
                <BadgeInfo
                  badgeInfoProps={getCandidateArrivalProps(askedDate)}
                />

                <div>
                  <div className="mt-05 row">
                    <input
                      value={arrivalHour ?? asHour(arrivalDate, ":")}
                      type="time"
                      className={`p-1 br-175 black`}
                      style={{ fontSize: "1rem", height: 48 }}
                      onChange={(e) => setArrivalHour(e.target.value)}
                      disabled={askedDate.state !== WelcomeBoardState.Finished}
                    />
                    <div className="gray ml-05">
                      {asTinyDateSlash(selectedDate)}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          {/* Departure time */}
          <div>
            {selectedDate && (
              <>
                <BadgeInfo
                  badgeInfoProps={getCandidateDepartureProps(askedDate)}
                />
                <div className="mt-05 row">
                  <input
                    value={departureHour ?? asHour(departureDate, ":")}
                    type="time"
                    className={`p-1 br-175 black`}
                    style={{ fontSize: "1rem", height: 48 }}
                    onChange={(e) => setDepartureHour(e.target.value)}
                    disabled={askedDate.state !== WelcomeBoardState.Finished}
                  />
                  <div className="gray ml-05">
                    {asTinyDateSlash(
                      moment(selectedDate).add(endOnNextDay.current, "day")
                    )}
                  </div>
                </div>
              </>
            )}
          </div>

          {/* Calendar */}
          <div>
            <button
              className="btn-square-calendar-md pinkBorder bg-pinkWhite br-05 "
              onClick={() => {
                setCalendarVisibility(!calendarVisibility);
              }}
            >
              <img src={Calendar32} alt="calendar" className="pink-icon" />
            </button>

            <div style={{ position: "absolute", zIndex: 100000 }}>
              {calendarVisibility && (
                <Calendar
                  type="checkin"
                  highlightedDates={getAvailableAskedDateForCheckin}
                  availableDates={getAvailableAskedDateForCheckin}
                  defaultDate={selectedDate}
                  dismiss={() => setCalendarVisibility(false)}
                  onDateSelected={onCalendarDateSelected}
                />
              )}
            </div>
          </div>
        </div>

        {/* BUTTONS */}
        <div className="col" style={{ gap: "0.5rem", width: "11rem" }}>
          {askedDate.state === WelcomeBoardState.Ongoing && (
            <div className="row" style={{ gap: "8px" }}>
              {/* Comment btn */}

              <button
                className={
                  !applicant.absent
                    ? "btn-profile mr-1 mt-05"
                    : "btn-main mr-1 mt-05"
                }
                style={{ margin: 0, fontSize: "0.875rem", height: "2.5rem" }}
                onClick={() => {
                  setDisplayAbsenceCommentPopup(true);
                }}
              >
                <div className="pink mr-05">Absence</div>
                <img
                  src={MagGlass}
                  alt="magnifying_glass"
                  style={{ width: "1.5rem", height: "1.5rem" }}
                />
              </button>

              {/* Comment edit */}

              <button
                style={{ width: "3rem", height: "2.5rem" }}
                className="pinkBorder bg-pinkWhite br-05"
                onClick={() => {
                  setDisplayDailyCommentPopup(true);
                }}
              >
                {!askedDate.dailyComment && !dailyComment && (
                  <img src={Pen} alt="edit" />
                )}
                {(askedDate.dailyComment || dailyComment) && (
                  <div className="semibold pink2">1</div>
                )}
              </button>
            </div>
          )}

          {/* Valid btn */}
          {displayBtnValid && (
            <div
              className="mt-2"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "end",
                justifyContent: "end",
                marginTop: 0,
                width: "100%",
              }}
            >
              <ButtonLoader
                error={!!patchHoursError}
                loading={patchHoursLoading}
                buttonProps={{
                  ...validateHours,
                  buttonClassName:
                    validateHours.buttonClassName + " inline  m-0 p-0 w-100",
                }}
                containerStyle={{ height: "38px" }}
                onClicked={() => {
                  setValidatePatchHours(true);
                }}
              />
            </div>
          )}
        </div>
        {/* Comment popup */}
        {displayDailyCommentPopup && (
          <CommentPopup
            commentPopupProps={PopupDailyComment}
            setVisibility={(isVisible) =>
              setDisplayDailyCommentPopup(isVisible)
            }
            onValidated={(comment) => {
              setDailyComment(comment);
              setValidateDailyCommentPatch(true);
            }}
            defaultComment={dailyComment ?? askedDate.dailyComment ?? ""}
          />
        )}
        {/* Absent popup */}
        {displayAbsenceCommentPopup && (
          <CommentPopup
            commentPopupProps={PopupAbsenceComment}
            setVisibility={(isVisible) =>
              setDisplayAbsenceCommentPopup(isVisible)
            }
            onValidated={(comment) => {
              setAbsenceComment(comment);
              setValidateAbsenceCommentPatch(true);
            }}
            defaultComment={absenceComment ?? applicant.absenceComment ?? ""}
          />
        )}
      </>
    </ApplicantInfo>
  ) : (
    <div></div>
  );
}
