import React from "react";
import { BooleanValueProps } from "./valueProps";

type Props = {
  valueProps: BooleanValueProps;
  label: string;
  marginBottom: string;
};

export default function SingleCheckbox({ valueProps, label, marginBottom }: Props) {
  return (
    <div className={`row ${marginBottom}`}>
      <div
        className="row as-fe pointer"
        onClick={() => valueProps.onChange(!valueProps.value)}
      >
        <input
          type="radio"
          checked={valueProps.value}
          onChange={() => valueProps.onChange(!valueProps.value)}
          style={{ height: "20px", width: "20px" }}
        />
        <div className="ml-05 " style={{ fontSize: "16px", whiteSpace: "nowrap" }}>
          {label}
        </div>
      </div>
    </div>
  );
}
