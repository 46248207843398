import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import BarGraph from "./MyBarGraph";
import PlusImg from "../../assets/icons/redAndWhitePlus/red_and_white_plus.svg";
import { useGetStatistics } from "../../api/statistics";
import { UserContext } from "../../components/context/UserContext";
import { getTitle } from "./getTitle";

function BoardOverlay({ firstCategory, secondCategory = null }) {
  const { selectedInstitution } = useContext(UserContext);
  const navigate = useNavigate();
  const { data: firstCategoryResult } = useGetStatistics(
    firstCategory,
    new Date().getFullYear().toString(),
    selectedInstitution
  );
  const { data: secondCategoryResult } = useGetStatistics(
    secondCategory,
    new Date().getFullYear().toString(),
    selectedInstitution
  );

  const isClickable = (category) => {
    return category !== "institution_wb_created";
  };

  const navigateTo = () => {
    if (isClickable(firstCategory)) {
      navigate(
        `/board/stats/${firstCategory}${
          secondCategory ? `?secondCategory=${secondCategory}` : ""
        }`
      );
    }
  };

  return (
    firstCategoryResult &&
    firstCategoryResult.length > 0 &&
    (secondCategory === null || secondCategoryResult) && (
      <div
        onClick={navigateTo}
        className="noDecoration boardGraph shadowEffect bg-fff col br-05"
        style={{
          position: "relative",
          cursor: isClickable(firstCategory) ? "pointer" : "normal",
        }}
        key={firstCategory}
      >
        <span
          className="semibold as-fs mr-a ml-125 mt-1 mb-1"
          style={{ fontSize: "20px" }}
        >
          {getTitle(firstCategoryResult, firstCategory)}
          {secondCategory
            ? " / " + getTitle(secondCategoryResult, secondCategory)
            : ""}
        </span>
        <BarGraph
          width={500}
          height={200}
          barWidth={25}
          cursorPointer={isClickable(firstCategory)}
          currentColor="#696E8B"
          stats={firstCategoryResult}
          secondStats={secondCategoryResult}
        />
        {isClickable(firstCategory) && (
          <img
            src={PlusImg}
            alt="plus"
            style={{ position: "absolute", bottom: "3px", right: "15px" }}
          />
        )}
      </div>
    )
  );
}

export default BoardOverlay;
