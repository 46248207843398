import React from "react";

type Props = {
  label: string;
};

export default function Subtitle({ label }: Props) {
  return (
    <div className="mt-2 semibold" style={{ fontSize: "1.25rem" }}>
      {label}
    </div>
  );
}
