import { InputProps } from "../../../components/FormInput";
import { IdentityCard, Phone32, Email32 } from "../../../assets/icons/icons";

const firstname: InputProps = {
  title: "Prénom",
  type: "text",
  placeholder: "",
  icon: IdentityCard,
  alt: "firstname",
};

const lastname: InputProps = {
  title: "Nom",
  type: "text",
  placeholder: "",
  icon: IdentityCard,
  alt: "lastname",
};

const phone: InputProps = {
  title: "Téléphone",
  type: "number",
  placeholder: "",
  icon: Phone32,
  alt: "phone",
};

const email: InputProps = {
  title: "Adresse e-mail",
  type: "email",
  placeholder: "",
  icon: Email32,
  alt: "email",
};

const inputs = {
  firstname,
  lastname,
  phone,
  email,
};

export { inputs };
