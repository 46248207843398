import React from "react";
import { Back } from "../../assets/icons/icons";

type Props = {
  onClick: () => void;
};

const BackButton = ({ onClick }: Props) => (
  <button
    type="button"
    className="btn-main inverted pink mw-fc"
    style={{
      backgroundColor: "#fef8fa",
      border: "2px solid #ee7a9b",
      color: "#ee7a9b",
    }}
    onClick={() => onClick()}
  >
    <img src={Back} alt="back" className="mr-1" />
    <div>Etape précédente</div>
  </button>
);

export default BackButton;
