enum MediaFormat {
  PROFILE = "web_profile_thumb",
  HORIZONTAL = "web_horizontal_thumb",
  VERTICAL = "web_vertical_thumb",
  PDF = "web_pdf_thumb",
}

type Media = {
  url: string;
  overwrite: boolean;
};

export { MediaFormat, Media };
