import React, { useState } from "react";
import { OutsideClickHandler } from "../utils/OutsideClickHandler";
import { ChevronDown } from "../../assets/icons/icons";

import { SelectItem } from "../../data/SelectItem";

type Props = {
  isClickable?: boolean;
  selectedItem: SelectItem | null;
  placeholder: string;
  items: SelectItem[] | null;
  onItemSelected: (selectedItem: SelectItem) => void;
  index?: number;
  style?: React.CSSProperties;
};

const Select = ({
  isClickable = true,
  selectedItem,
  placeholder,
  items,
  onItemSelected,
  index,
  style,
}: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  type LabelWithIconProps = {
    item: SelectItem | null;
    placeholder?: string;
  };

  const LabelWithIcon = ({ item, placeholder = "" }: LabelWithIconProps) => (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      {item?.icon && (
        <div
          style={{
            width: "32px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={item.icon} alt="icon" />
        </div>
      )}
      <div
        style={{
          marginLeft: "8px",
          color: item?.label ? "#221B43" : "#BAB7C8",
        }}
      >
        {item?.label ?? placeholder}
      </div>
    </div>
  );

  return (
    <OutsideClickHandler
      onClickedOutside={() => {
        setIsDropdownOpen(false);
      }}
    >
      <div className="row w-100" style={{ alignItems: "center" }}>
        {index && (
          <div className="mr-1" style={{ alignSelf: "center" }}>
            {index}.
          </div>
        )}

        <div
          className="row bg-fff p-1 br-15 jc-sb pointer w-100"
          style={{ border: "1px solid lightgray", height: 48, ...style }}
          onClick={() => isClickable && setIsDropdownOpen((d) => !d)}
        >
          <div className="ml-1">
            <LabelWithIcon item={selectedItem} placeholder={placeholder} />
          </div>
          <img
            src={ChevronDown}
            alt="open"
            className={isDropdownOpen ? "reverse mr-05" : "mr-05"}
          />
        </div>
      </div>
      {isDropdownOpen && (
        <div
          className="col bg-fff p-1 br-15"
          style={{ border: "1px solid lightgray", marginTop: "2px" }}
        >
          {items &&
            items.map((item) => {
              return (
                <div
                  key={item.key}
                  className="ml-1 row custom-option w-100 p-05 pointer"
                  id={item.key}
                  onClick={() => {
                    if (item !== selectedItem) {
                      onItemSelected(item);
                    }
                    setIsDropdownOpen(false);
                  }}
                >
                  <LabelWithIcon item={item} />
                </div>
              );
            })}
        </div>
      )}
    </OutsideClickHandler>
  );
};

export { Select };
