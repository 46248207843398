import { useRequest } from "..";
import { post, getWithAuth, patchWithAuth } from "../requests";
import { toUppercase, toFullName } from "../format";

type Media = {
  id: string;
  type: string;
};

type User = {
  id: string;
  email: string;
  firstname: string;
  lastname: string;
  phone: string | null;
  profilePicture: Media | null;
  roles: string[];
  institutions: any[];
  lastNotificationAt: string | null;
  cgvAccepted: boolean;
};

const usePostLogin = (
  username: string,
  password: string,
  fire: boolean,
  setValidate: (validate: boolean) => void
) => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}login_check`,
    post(JSON.stringify({ username: username, password: password })),
    (raw: any) => raw,
    fire,
    setValidate
  );
};

const usePostResetPassword = (email, fire, setValidate) => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}reset_password`,
    post(JSON.stringify({ email: email, platform: "web" })),
    (raw: any) => raw,
    fire && email,
    setValidate
  );
};

const usePostSendPassword = (
  token: string | null,
  password: string,
  fire: boolean,
  setValidate: (validate: boolean) => void
) => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}reset_password/reset/${token}`,
    post(
      JSON.stringify({ plainPassword: { first: password, second: password } })
    ),
    (raw: any) => raw,
    fire && token !== null,
    setValidate
  );
};

const useGetCurrentUser = (fire, setValidate) => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}user`,
    getWithAuth(),
    (raw: User) => {
      return {
        ...raw,
        firstname: toUppercase(raw.firstname),
        lastname: toUppercase(raw.lastname),
        institutions: raw.institutions.filter(
          (institution) => institution.activated
        ),
        nickname: toFullName(raw.firstname, raw.lastname),
      };
    },
    fire,
    setValidate
  );
};

const usePatchCurrentUser = (body, fire, setValidate) => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}user`,
    patchWithAuth(JSON.stringify(body)),
    (raw: any) => raw,
    fire,
    setValidate
  );
};

export {
  usePostLogin,
  usePostResetPassword,
  useGetCurrentUser,
  usePatchCurrentUser,
  usePostSendPassword,
  User,
};
