import React, { createContext, useState, useEffect } from "react";
import { useGetCurrentUser, usePatchCurrentUser, User } from "../../api/user";
import { ContractType } from "../../api/enums";
import { Role } from "../../api/enums";

const TOKEN_KEY = "tkcare_token";
const INSTITUTION_KEY = "institution";
const CONTRACT_TYPE_KEY = "contract_type";

type UserProps = {
  user: User | null;
  token: string | null | undefined;
  saveToken: (token: string, shouldPersist: boolean) => void;
  logout: () => boolean;
  refresh: () => void;
  isRh: () => boolean;
  getStatus: () => string;
  setSelectedInstitution: (id: string) => void;
  selectedInstitution: string | null;
  setContractType: (contractType: ContractType) => void;
  contractType: ContractType | null;
  isHospital: boolean;
  isMp: boolean;
  updateGcu: () => void;
};

export const UserContext = createContext<UserProps>({
  user: null,
  token: undefined,
  saveToken: (_: string, __: boolean) => {},
  logout: () => false,
  refresh: () => {},
  isRh: () => false,
  getStatus: () => "",
  setSelectedInstitution: (id: string) => {},
  selectedInstitution: null,
  setContractType: (_: ContractType) => {},
  contractType: null,
  isHospital: true,
  isMp: false,
  updateGcu: () => {},
});

export const UserProvider = ({ children }) => {
  const [token, setToken] = useState<string>();
  const [validate, setValidate] = useState(false);
  const { data: user } = useGetCurrentUser(validate, setValidate);
  const [selectedInstitution, setSelectedInstitution] = useState(null);
  const [contractType, setContractType] = useState<ContractType>(
    ContractType.FullTime
  );
  const [validateGcu, setValidateGcu] = useState(false);
 const {data: patchUserResult } = usePatchCurrentUser({ cgvAccepted: true }, validateGcu, setValidateGcu)

  useEffect(() => {
    if (!token) {
      let storedToken = localStorage.getItem(TOKEN_KEY);
      if (storedToken === null) {
        storedToken = sessionStorage.getItem(TOKEN_KEY);
      }
      setToken(storedToken);
      setTimeout(() => setValidate(true));
    }
    if (!selectedInstitution) {
      const institution = localStorage.getItem(INSTITUTION_KEY);
      if (institution) {
        setSelectedInstitution(institution);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if(patchUserResult) {
      setValidate(true);
    }
  }, [patchUserResult])

  useEffect(() => {
    const storedSelectedInstitution = localStorage.getItem(INSTITUTION_KEY);
    if (
      storedSelectedInstitution !== selectedInstitution &&
      selectedInstitution !== null
    ) {
      localStorage.setItem(INSTITUTION_KEY, selectedInstitution);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInstitution]);

  useEffect(() => {
    const storedContractType = localStorage.getItem(CONTRACT_TYPE_KEY);
    if (storedContractType !== contractType && contractType !== null) {
      localStorage.setItem(CONTRACT_TYPE_KEY, contractType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractType]);

  useEffect(() => {
    if (user && selectedInstitution === null) {
      setSelectedInstitution(user.institutions?.[0]?.id ?? null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
    sessionStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(INSTITUTION_KEY);
    localStorage.removeItem(CONTRACT_TYPE_KEY);
    setToken(null);
    setValidate(false);
    setSelectedInstitution(null);
    return true;
  };

  const saveToken = (token: string, shouldPersist: boolean) => {
    if (shouldPersist) {
      localStorage.setItem(TOKEN_KEY, token);
    } else {
      sessionStorage.setItem(TOKEN_KEY, token);
    }
    setToken(token);
    setTimeout(() => setValidate(true));
  };

  const updateGcu = () => {
    setValidateGcu(true)
  }

  const refresh = () => {
    setValidate(true);
  };

  const isRh = () => {
    return (
      user?.roles.includes(Role.RH) || user?.roles.includes(Role.AdminClient)
    );
  };

  const isMp = user?.roles.includes(Role.MP);

  const isHospital = user?.institutions?.[0]?.type === "hospital";

  const getStatus = () => {
    return isRh() ? "RH" : "Manager de proximité";
  };

  return (
    <UserContext.Provider
      value={{
        user,
        token,
        saveToken,
        logout,
        refresh,
        isRh,
        getStatus,
        setSelectedInstitution,
        selectedInstitution,
        setContractType,
        contractType,
        isHospital,
        isMp,
        updateGcu
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
