import React, { useContext } from "react";
import { CreateMissionContext } from "./context/CreateMissionContext";
import Stepper from "./Stepper";
import { PopUpPublishMission } from "../components/PopupPublishMission";
import { useNavigate } from "react-router-dom";
import { MissionDetails } from "./MissionDetails";
import UploadImagesBlock from "../pages/create-mission/UploadImagesBlock";
import { Formik, Form } from "formik";
import { firstFormSchema } from "../pages/create-mission/validationSchemas";
import { TextError, ManualTextError } from "./index";
import CancelButton from "../pages/create-mission/CancelButton";
import SingleCheckbox from "../pages/create-mission/SingleCheckbox";
import BackButton from "../pages/create-mission/BackButton";
import moment from "moment";
import { Button, publishMission } from "./buttons/buttons";

export default function AddMissionStepThree() {
  const {
    firstStepValues,
    setFirstStepValues,
    secondStepValues,
    submitForm,
    data,
    clear,
    error,
  } = useContext(CreateMissionContext);

  const navigate = useNavigate();
  return (
    <Formik
      initialValues={firstStepValues}
      validationSchema={firstFormSchema}
      onSubmit={async (values) => {
        setFirstStepValues(values);
        submitForm();
      }}
    >
      {({ values, setFieldValue, setFieldTouched, errors, setValues }) => {
        const onChange = (key, value) => {
          setFieldValue(key, value);
          setTimeout(() => setFieldTouched(key, true));
        };
        return (
          <Form>
            <Stepper currentStep={2} />
            <UploadImagesBlock
              firstUploadImageProps={{
                imageId: null,

                imageUrl: values.firstPicture,
                setImageUrl: (value) => onChange("firstPicture", value),
              }}
              secondUploadImageProps={{
                imageId: null,

                imageUrl: values.secondPicture,
                setImageUrl: (value) => onChange("secondPicture", value),
              }}
            />
            <TextError name="firstPicture" />
            {!errors.firstPicture ? <TextError name="secondPicture" /> : null}
            <div className="mt-2" />
            <MissionDetails
              mission={{
                ...firstStepValues,
                ...secondStepValues,
                institution: firstStepValues.institution?.name,
                address: firstStepValues.institution?.address?.label,
                service: firstStepValues.service,
                occupation: firstStepValues.occupation,
                dateRange: `Du ${moment(secondStepValues.startDate).format(
                  "DD/MM/YYYY"
                )} au ${moment(secondStepValues.endDate).format("DD/MM/YYYY")}`,
                hourRange: `${moment(secondStepValues.startHour, "h:mm").format(
                  "HH[h]mm"
                )} - ${moment(secondStepValues.endHour, "h:mm").format(
                  "HH[h]mm"
                )}`,
                capacitations: secondStepValues.capacitations
                  .filter((capacitation) => capacitation)
                  .map((capacitation) => capacitation.label),
                staffs: secondStepValues.staffs
                  .filter((staff) => staff.occupation && staff.number)
                  .map((staff) => {
                    return {
                      number: staff.number,
                      occupation: staff.occupation.label,
                    };
                  }),
              }}
              firstStepValues={firstStepValues}
              secondStepValues={secondStepValues}
            />
            {error && <ManualTextError message={error} />}
            <div className="row mt-4 mb-3" style={{ gap: 24 }}>
              <CancelButton cancel={() => navigate("/board")} />
              <BackButton onClick={() => navigate("/missions/new/step-two")} />
              <Button buttonProps={publishMission} />
              <div className="col">
                <SingleCheckbox
                  marginBottom=""
                  valueProps={{
                    value: values.bookmark,
                    onChange: (value) =>
                      setValues({
                        ...values,
                        bookmark: value,
                        bookmarkName: value ? values.bookmarkName : "",
                      }),
                  }}
                  label="Ajouter comme modèle favori"
                />
                <input
                  placeholder="Nom du modèle favori"
                  type="text"
                  className="textInput bg-fff"
                  style={{
                    border: "1px solid #BAB7C8",
                    fontSize: "14px",
                  }}
                  value={values.bookmarkName}
                  onChange={(event) =>
                    onChange("bookmarkName", event.target.value)
                  }
                />
                <TextError name="bookmarkName" />
              </div>
            </div>
            {data && <PopUpPublishMission clear={clear} />}
          </Form>
        );
      }}
    </Formik>
  );
}
