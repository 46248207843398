import React, { useContext } from 'react';
import {
  Hospital48,
  laboratory48,
  GirlPlus24,
  Calendar24,
  Clock24,
  HourlyRate24,
  Outfit24,
  Parking24,
  Staffs24,
  Comment24,
  Capacitations24,
  Check,
  CrossRed,
  Cloakroom24,
} from '../assets/icons/icons';
import BadgeInfo from './BadgeInfo';
import { UserContext } from './context/UserContext';
import { getBadgeInfoProps } from '../pages/recruitment/badgeInfo';
import { useLocation } from 'react-router-dom';

enum Selection {
  VALIDATE_INTERIM,
  VALIDATE_INDIVIDUAL_CONTRACTOR,
  REFUSE,
}

type Props = {
  mission: any;
  onValidated?: (selection: Selection) => void;
};

const MissionDetails = ({ mission, onValidated }: Props) => {
  const { isRh } = useContext(UserContext);
  const isValidationState = isRh() && mission.status === 'draft';
  const location = useLocation();

  return (
    <div className="mission-details shadowEffect">
      <div className="top">
        <div className="left" style={{ maxWidth: '400px' }}>
          <div className="row">
            <img src={mission.isHospital ? Hospital48 : laboratory48} alt="" />
            <div className="col ml-1">
              <div className="semibold as-fs mb-025">{mission.institution}</div>
              <div>{mission.address}</div>
            </div>
          </div>
        </div>
        <div className="center ml-35">
          {mission.service?.icon && (
            <img src={mission.service?.icon} alt="service" />
          )}
          <div className="task semibold ml-05">
            <span>
              {mission.service?.label} ({mission.servicePrecision})
            </span>
          </div>
        </div>
        {mission.status === 'draft' && (
          <div className="right">
            <BadgeInfo
              badgeInfoProps={getBadgeInfoProps(mission, location.pathname)}
            />
          </div>
        )}
      </div>
      <div className="bottom">
        <div className="left">
          <div className="section">
            <div>
              <img src={GirlPlus24} alt="icon candidat" className="grey-icon" />
              <div className="gray">Candidat recherché</div>
            </div>
            <div>
              <span className="semibold">{mission.occupation?.label}</span>
            </div>
          </div>
          <div className="section">
            <div>
              <img src={Calendar24} alt="icon candidat" className="grey-icon" />
              <span className="gray">Dates</span>
            </div>
            <div>
              <span className="semibold">{mission.dateRange}</span>
            </div>
          </div>
          <div className="section">
            <div>
              <img src={Clock24} alt="icon candidat" className="grey-icon" />
              <span className="gray">Horaires</span>
            </div>
            <div>
              <span className="semibold">{mission.hourRange}</span>
            </div>
          </div>
          <div className="section">
            <div>
              <img
                src={HourlyRate24}
                alt="icon candidat"
                className="grey-icon"
              />
              <span className="gray">Tarif horaire</span>
            </div>
            <div>
              <span className="semibold">{mission.hourlyRate}€/h</span>
            </div>
          </div>
        </div>
        <div className="center">
          <div
            style={{
              marginBottom:
                !mission.provideOutfit &&
                !mission.provideCloakroom &&
                !mission.provideParking
                  ? ''
                  : '1.5rem',
            }}
          >
            {mission.provideOutfit && (
              <div className="section">
                <div>
                  <img
                    src={Outfit24}
                    alt="icon outfit"
                    className="blue-icon icon-img"
                  />
                  <span>Tenue à disposition</span>
                </div>
              </div>
            )}

            {mission.provideCloakroom && (
              <div className="section">
                <div>
                  <img
                    src={Cloakroom24}
                    alt="icon locker"
                    className="blue-icon icon-img"
                  />
                  <span>Local et vestiaire à disposition</span>
                </div>
              </div>
            )}
            {mission.provideParking && (
              <div className="section">
                <div>
                  <img
                    src={Parking24}
                    alt="icon candidat"
                    className="blue-icon icon-img"
                  />
                  <span>Parking à disposition</span>
                </div>
              </div>
            )}
          </div>
          <div className="section">
            <div>
              <img
                src={Staffs24}
                alt="icon staff"
                className="blue-icon icon-img"
              />
              <span>
                {mission.staffs.map((staff, index) => (
                  <span key={index}>
                    {index > 0 ? ' / ' : ''}
                    {staff.number} {staff.occupation}
                  </span>
                ))}
              </span>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="section">
            <div>
              <img
                src={Comment24}
                alt="icon candidat"
                className="grey-icon mr-05"
              />
              <div className="gray">Principales activités de la mission</div>
            </div>
          </div>
          <div className="section">
            <div>
              <span className="semibold">{mission.description}</span>
            </div>
          </div>
          <div className="section">
            <img
              src={Capacitations24}
              alt="icon rocket"
              className="grey-icon mr-05"
            />
            <span className="gray">Compétences requises</span>
          </div>
          {mission.capacitations.map((capacitation, index) => (
            <div className="section" key={index}>
              <div>
                <span className="semibold">{capacitation.label}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      {isValidationState && (
        <div
          style={{
            columnGap: '1.5rem',
          }}
          className="buttons-zone row mt-1"
        >
          <div>
            <button
              className="btn-green"
              onClick={() => onValidated?.(Selection.VALIDATE_INTERIM)}
            >
              <img
                src={Check}
                alt="validate"
                style={{ marginRight: '.3rem' }}
              />
              Valider cette mission
            </button>
          </div>
          {/*           <div
          // className="flex-1"
          // style={{ width: "31%" }}
          >
            <button
              className="btn-green"
              onClick={() =>
                onValidated?.(Selection.VALIDATE_INDIVIDUAL_CONTRACTOR)
              }
            >
              <img
                src={Check}
                alt="validate"
                style={{ marginRight: ".3rem" }}
              />
              Valider cette mission en VACATION
            </button>
          </div> */}
          <div>
            <button
              className="btn-mission p-1"
              onClick={() => onValidated?.(Selection.REFUSE)}
            >
              <img
                src={CrossRed}
                alt="validate"
                style={{ marginRight: '.3rem' }}
              />
              Refuser
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export { Selection, MissionDetails };
