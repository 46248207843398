import React, { useState, useEffect } from "react";
import { Button } from "../../components/buttons/Button";
import {
  addApplicant,
  validateApplicant,
} from "../../components/buttons/buttons";
import { useGetApplicantsByName } from "../../api/customer";
import SelectRelationPopup from "../profile/SelectRelationPopup";
import { Relation, relationMapper } from "../../data/Relation";
import moment from "moment";
import Calendar from "../../components/Calendar";
import BinButton from "../create-mission/step-two/BinButton";
import { usePostAddApplicant } from "../../api/welcomeboards";

export default function HumanResourcesSection({
  welcomeBoardId,
  mission,
  refresh,
}) {
  const [selectedApplicant, setSelectedApplicant] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [askedDates, setAskedDates] = useState([]);
  const [page, setPage] = useState(1);
  const [validateGetApplicantsByName, setValidateGetApplicantsByName] =
    useState(true);
  const [search, setSearch] = useState("");
  const [timer, setTimer] = useState(null);
  const { data: applicants } = useGetApplicantsByName(
    search,
    page,
    validateGetApplicantsByName,
    setValidateGetApplicantsByName
  );
  const [validateAddApplicant, setValidateAddApplicant] = useState(false);
  const { loading, data } = usePostAddApplicant(
    welcomeBoardId,
    mission.startAt,
    selectedApplicant.length > 0 ? selectedApplicant[0].id : 0,
    askedDates,
    validateAddApplicant,
    setValidateAddApplicant
  );

  const setSearchDelay = (newSearch) => {
    setSearch(newSearch);
    setPage(1);
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        setPage(page);
        setValidateGetApplicantsByName(true);
      }, 500)
    );
  };

  useEffect(() => {
    if (!loading && data) {
      setTimeout(() => {
        refresh();
        setSelectedApplicant([]);
        setAskedDates([]);
      }, 1000);
    }
  }, [loading, data]);

  return (
    <div>
      {selectedApplicant.length === 0 ? (
        <Button
          buttonProps={addApplicant}
          onClicked={() => setShowPopup(true)}
        />
      ) : (
        <div className="row ai-fs">
          <div className="w-100">
            <div className="row jc-sb shadowEffect mb-1 bg-fff p-1 br-05 mr-05">
              <div className="h3 tal flex-1 semibold">
                {selectedApplicant[0].fullname}
              </div>
              <div className="h3 tal flex-1 semibold">
                {selectedApplicant[0]?.diplomas &&
                  selectedApplicant[0].diplomas[0]}
              </div>
              <div className="h3 tal flex-1 semibold">
                {selectedApplicant[0]?.permissions?.join(" - ")}
              </div>
              <div className="h3 tal flex-1 semibold">
                {selectedApplicant[0]?.availableAt?.[0]?.concat(
                  " au ",
                  selectedApplicant[0].availableAt?.[
                    selectedApplicant[0].availableAt.length - 1
                  ]
                )}
              </div>
            </div>
            {!loading && (
              <div className="row">
                <BinButton
                  onClick={() => {
                    setSelectedApplicant([]);
                    setAskedDates([]);
                  }}
                />
                <Button
                  buttonProps={validateApplicant}
                  onClicked={() => {
                    setValidateAddApplicant(true);
                  }}
                  disabled={askedDates.length === 0}
                />
              </div>
            )}
          </div>
          <Calendar
            minDate={moment().toDate() || null}
            type="selection"
            highlightedDates={askedDates}
            availableDates={mission.datesList}
            finishedDates={[]}
            onDateSelected={(selected) => {
              if (!askedDates.includes(selected)) {
                setAskedDates([selected, ...askedDates]);
              } else
                setAskedDates(
                  askedDates.filter((askedDate) => askedDate !== selected)
                );
            }}
            dismiss={() => {}}
          />
        </div>
      )}
      {showPopup && (
        <SelectRelationPopup
          setPopupVisible={(value: boolean) => setShowPopup(value)}
          title={"Sélectionner un Applicant"}
          entries={relationMapper(applicants)}
          selectedEntries={relationMapper(selectedApplicant)}
          setSelectedEntries={(entries: Relation[]) => {
            const findResult = applicants.find(
              (Applicant) =>
                Applicant.id === (entries.length > 0 ? entries[0].id : null)
            );
            setSelectedApplicant(findResult ? [findResult] : []);
          }}
          multiple={false}
          increment={() => {
            setPage(page + 1);
            setValidateGetApplicantsByName(true);
          }}
          search={search}
          setSearch={setSearchDelay}
        />
      )}
    </div>
  );
}
