import React, { useEffect, useState } from "react";
import { useGetMedia, getMediaSource } from "../api/medias";
import { MediaFormat, Media } from "../data/medias";

type Props = {
  imageId: string | undefined;
  setImageUrl?: (imageUrl: Media) => void;
  imageUrl?: string;
  alt: string;
  className?: string;
  format?: MediaFormat | null;
  style?: React.CSSProperties;
  allowDownload?: boolean;
};

const ProtectedImage = ({
  imageId,
  setImageUrl,
  imageUrl = "",
  alt,
  className,
  format = null,
  style,
  allowDownload,
}: Props) => {
  const [lastImageId, setLastImageId] = useState(imageId);
  const [localImageUrl, setLocalImageUrl] = useState("");
  const { data } = useGetMedia(
    (imageUrl === "" && localImageUrl === "") || imageId !== lastImageId
      ? imageId
      : null,
    format
  );
  useEffect(() => {
    if (data !== null) {
      if (setImageUrl) {
        setImageUrl({
          url: data,
          overwrite: false,
        });
      } else {
        setLocalImageUrl(data);
      }
      setLastImageId(imageId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div
      onClick={
        allowDownload
          ? async () => {
              const url = await getMediaSource(imageId);
              const link = document.createElement("a");
              link.href = url;
              link.download =
                format !== MediaFormat.PDF ? "image.png" : "pdf.pdf";
              link.click();
            }
          : undefined
      }
    >
      {(imageUrl !== "" || localImageUrl !== "") && imageId !== undefined ? (
        <img
          src={imageUrl !== "" ? imageUrl : localImageUrl}
          alt={alt}
          style={{ ...style, display: "block" }}
          className={className}
        />
      ) : (
        <div
          style={{ ...style, backgroundColor: "#EDEDED" }}
          className={className}
        />
      )}
    </div>
  );
};

export { ProtectedImage };
