import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, Navigate, Outlet, useLocation } from "react-router-dom";
import Login from "./pages/Login";
import ForgottenPassword from "./pages/ForgottenPassword";
import Board from "./pages/Board";
import BoardGraphDetail from "./pages/BoardGraphDetail";
import Recruitments from "./pages/Recruitments";
import Validation from "./components/Validation";
import ApplicantFull from "./pages/ApplicantFull";
import Documents from "./pages/Documents";
import AddMission from "./pages/AddMission";
import Profile from "./pages/Profile";
import "./App.scss";
import ApplicantNotation from "./pages/ApplicantNotation";
import ProfileAccount from "./components/ProfileAccount";
import ProximityManagers from "./components/ProximityManagers";
import EstablishmentList from "./components/EstablishmentList";
import WelcomeBoards from "./components/WelcomeBoards";
import ManagerProfile from "./components/ManagerProfile";
import Establishment from "./components/Establishment";
import AddMissionStepOne from "./components/AddMissionStepOne";
import AddMissionStepTwo from "./components/AddMissionStepTwo";
import AddMissionStepThree from "./components/AddMissionStepThree";
import { UserContext } from "./components/context/UserContext";
import { CreateMissionContext } from "./components/context/CreateMissionContext";
import SendPassword from "./pages/forgotten-password/SendPassword";
import { useGetCurrentUser } from "./api/user";
import Notifications from "./pages/notifications/Notifications";
import Gcu from "./pages/profile/Gcu";
import PrivacyPolicy from "./pages/profile/PrivacyPolicy";
import GcuValidation from "./pages/gcu-validation/GcuValidation";

const ProtectedRoute = ({ isAllowed, redirectPath = "login", children }) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

function App() {
  // Needed to avoid initial state logout in useRequest through UserContext
  const [validate, setValidate] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const { _ } = useGetCurrentUser(validate, setValidate);
  const { token, user } = useContext(UserContext);
  const { firstStepValues, secondStepValues, clear } =
    useContext(CreateMissionContext);
  const isConnected = token !== null;
  const areGcuAccepted = user ? user.cgvAccepted : true;
  const isCreateMissionStepTwoReachable = firstStepValues !== undefined;
  const isCreateMissionStepThreeReachable =
    firstStepValues !== undefined && secondStepValues !== undefined;
  const location = useLocation();
  const [lastLocation, setLastLocation] = useState("");
  const [isChatLoaded, setIsChatLoaded] = useState(false);
  useEffect(() => {
    if (
      !location.pathname.startsWith("/missions/new") &&
      lastLocation.startsWith("/missions/new")
    ) {
      clear();
    }
    setLastLocation(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (!isChatLoaded) {
      window.$crisp = [];
      window.CRISP_TOKEN_ID = null;
      window.CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_WEBSITE_ID;
      const d = document;
      const s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
      window.CRISP_READY_TRIGGER = () => {
        setIsChatLoaded(true);
      };
    }
  }, [isChatLoaded]);

  useEffect(() => {
    if (isChatLoaded) {
      if (user && token) {
        window.CRISP_TOKEN_ID = user.id;
        window.$crisp.push(["do", "session:reset"]);
        window.$crisp.push(["do", "chat:show"]);
        window.$crisp.push(["set", "user:email", user.email]);
        window.$crisp.push(["set", "user:nickname", user.nickname]);
      }
      if (!token) {
        window.CRISP_TOKEN_ID = null;
        window.$crisp.push(["do", "session:reset"]);
        window.$crisp.push(["do", "chat:hide"]);
      }
    }
  }, [isChatLoaded, user, token]);

  return (
    token !== undefined && (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          element={
            <ProtectedRoute
              redirectPath="login"
              isAllowed={!areGcuAccepted && isConnected}
            />
          }
        >
          <Route path="cgu-validation" element={<GcuValidation />} />
        </Route>
        <Route
          element={
            <ProtectedRoute redirectPath="board" isAllowed={!isConnected} />
          }
        >
          <Route path="login" element={<Login />} />
          <Route path="forgotten-password" element={<ForgottenPassword />} />
          <Route
            path="forgotten-password-confirmation/:token"
            element={<SendPassword />}
          />
        </Route>
        <Route
          element={
            <ProtectedRoute redirectPath="login" isAllowed={isConnected} />
          }
        >
          <Route
            element={
              <ProtectedRoute
                redirectPath="cgu-validation"
                isAllowed={areGcuAccepted}
              />
            }
          >
            <Route path="board" element={<Board />} />
            <Route
              path="board/stats/:category"
              element={<BoardGraphDetail />}
            />
            <Route path="missions/:time" element={<Recruitments />} />
            <Route
              path="recruitments/mission/:missionId/applicant/:applicantId"
              element={<ApplicantFull />}
            />
            <Route path="missions/past/:id" element={<Validation />} />
            <Route path="missions/future/:id" element={<Validation />} />
            <Route path="missions/present/:id" element={<Validation />} />
            <Route path="missions/new" element={<AddMission />}>
              <Route path="step-one" element={<AddMissionStepOne />} />
              <Route
                element={
                  <ProtectedRoute
                    redirectPath="step-one"
                    isAllowed={isCreateMissionStepTwoReachable}
                  />
                }
              >
                <Route path="step-two" element={<AddMissionStepTwo />} />
              </Route>
              <Route
                element={
                  <ProtectedRoute
                    redirectPath="step-one"
                    isAllowed={isCreateMissionStepThreeReachable}
                  />
                }
              >
                <Route path="step-three" element={<AddMissionStepThree />} />
              </Route>
            </Route>
            <Route
              path="mission/:id/notation"
              element={<ApplicantNotation />}
            />
            <Route path="mission/:id/recruitments" element={<Recruitments />} />
            <Route path="recruitments/validation" element={<Recruitments />} />
            <Route
              path="recruitments/validation/:id"
              element={<Validation />}
            />
            <Route path="recruitments/dispatch" element={<Recruitments />} />
            <Route path="recruitments/dispatch/:id" element={<Validation />} />
            <Route path="applicant/:id" element={<ApplicantFull />} />
            <Route path="profile" element={<Profile />}>
              <Route path="account" element={<ProfileAccount />} />
              <Route
                path="proximity-managers"
                element={<ProximityManagers />}
              />
              <Route
                path="proximity-managers/:id"
                element={<ManagerProfile />}
              />
              <Route
                path="proximity-managers/add"
                element={<ManagerProfile />}
              />
              <Route path="establishment" element={<Establishment />} />
              <Route path="establishments" element={<EstablishmentList />} />
              <Route path="establishments/:id" element={<Establishment />} />
              <Route path="establishments/add" element={<Establishment />} />
              <Route path="welcome-boards" element={<WelcomeBoards />} />
              <Route path="gcu" element={<Gcu />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
            </Route>
            <Route
              path="recruitments/applicant/:id/documents"
              element={<Documents />}
            />
            <Route path="notifications" element={<Notifications />} />
          </Route>
        </Route>
      </Routes>
    )
  );
}

export default App;
