import moment from 'moment';
import 'moment/locale/fr';

const unknownValue = 'N/A';

const check = (
  value: any,
  transform: (v: any) => any = (v) => v
): any | string => {
  return value !== null && value !== 'undefined'
    ? transform(value)
    : unknownValue;
};

const enumCheck = (
  value: any,
  includedIn: any,
  transform: (v: any) => any = (v) => v
): any | string => {
  return Object.entries(includedIn).includes(value)
    ? transform(value)
    : unknownValue;
};

const isBefore = (
  firstDate: string,
  secondDate: string,
  strictly: boolean = true
) => {
  return strictly
    ? moment(firstDate).isBefore(secondDate)
    : moment(firstDate).isSameOrBefore(secondDate);
};

const isAfter = (
  firstDate: string,
  secondDate: string,
  strictly: boolean = true
) => {
  return strictly
    ? moment(firstDate).isAfter(secondDate)
    : moment(firstDate).isSameOrAfter(secondDate);
};

const asDate = (rawDate: string, rawFormat: string = null) => {
  return !rawFormat
    ? moment(rawDate).format('DD/MM/YYYY')
    : moment(rawDate, rawFormat).format('DD/MM/YYYY');
};

const asDay = (rawDate: string) => {
  return moment(rawDate).locale('fr').format('dddd');
};

const asHour = (rawDate: string, separator: string = 'h') => {
  return moment(rawDate).format(`HH[${separator}]mm`);
};

const asHourAndDate = (rawDate: string) => {
  return moment(rawDate).format('HH[h]mm[ : ]DD-MM');
};

const asTinyDate = (rawDate: string) => {
  return moment(rawDate).format('DD-MM');
};

const asTinyDateSlash = (rawDate: string) => {
  return moment(rawDate).format('DD/MM');
};

const plusOneSecond = (rawDate: string): string => {
  const date = moment(rawDate);
  date.add(1, 'seconds');
  return date.toISOString();
};

const getDatesBetween = (
  rawStartDate: string,
  rawEndDate: string
): string[] => {
  const endDate = moment(rawEndDate).startOf('day');
  const dates = [asDate(rawStartDate)];
  const incrementalDate = moment(rawStartDate);
  while (incrementalDate.isBefore(endDate)) {
    incrementalDate.add(1, 'days');
    dates.push(incrementalDate.format('DD/MM/YYYY'));
  }
  return dates;
};

const getNumberOfDaysBetweenDates = (
  rawFirstDate: string,
  rawSecondDate: string = new Date().toDateString()
): number => {
  const firstDate = moment(rawFirstDate);
  const secondDate = moment(rawSecondDate);
  return firstDate.diff(secondDate, 'days');
};

const toUppercase = (str: string | null) => str[0].toUpperCase() + str.slice(1);

const toFullName = (firstname: string | null, lastname: string | null) => {
  return `${toUppercase(firstname)} ${toUppercase(lastname)}`;
};

const toBlob = async (objectUrl) => {
  if (objectUrl === '') {
    return objectUrl;
  }
  const blob = await fetch(objectUrl).then((r) => r.blob());
  return blob;
};

export {
  unknownValue,
  enumCheck,
  check,
  asDate,
  asHour,
  toUppercase,
  toFullName,
  toBlob,
  asDay,
  getDatesBetween,
  getNumberOfDaysBetweenDates,
  asHourAndDate,
  asTinyDate,
  asTinyDateSlash,
  isBefore,
  isAfter,
  plusOneSecond,
};
