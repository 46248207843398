import { useRequest } from "..";
import { asDate } from "../format";
import { getWithAuth, patchWithAuth } from "../requests";

type useGetNotificationResponse = {
  nb_notifications: number;
};

const useGetNotificationCount = (
  validate: boolean,
  setValidate: (validate: boolean) => void,
  startAt: string | null = null
) => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}notification/count${
      startAt ? `?start_at=${encodeURIComponent(startAt)}` : ""
    }`,
    getWithAuth(),
    (raw: useGetNotificationResponse) => raw.nb_notifications,
    validate,
    setValidate
  );
};

const usePatchLastFetchDate = (date: string) => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}user`,
    patchWithAuth(
      JSON.stringify({
        lastNotificationAt: date,
      })
    ),
    (raw) => raw,
    date !== null
  );
};

type GetNotificationsResponse = {
  createdAt: string;
  id: string;
  message: string;
  title: string;
  updatedAt: string;
};

const useGetNotifications = (
  withLimit: boolean,
  validate: boolean,
  setValidate: (validate: boolean) => void,
  page?: number
) => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}notification?page=${page ?? 1}${
      withLimit ? "&limit=3" : ""
    }`,
    getWithAuth(),
    (raw: GetNotificationsResponse[]) => {
      return raw.map((notification) => {
        return {
          ...notification,
          updatedAtLabel: asDate(notification.updatedAt),
        };
      });
    },
    validate,
    setValidate
  );
};

export {
  useGetNotificationCount,
  useGetNotifications,
  usePatchLastFetchDate,
  GetNotificationsResponse,
};
