import React from "react";

type InputProps = {
  title: string;
  type: string;
  placeholder: string;
  icon: any;
  alt: string;
};

type Props = {
  inputProps: InputProps;
  value: any;
  setValue?: (value: any) => void;
  disabled?: boolean;
};

const FormInput = ({
  inputProps,
  value,
  setValue,
  disabled = false,
}: Props) => {
  const { title, type, placeholder, icon, alt } = inputProps;
  return (
    <>
      <div className="row mt-1">
        {icon && (
          <img
            src={icon}
            alt={alt}
            className="blue-icon"
            style={{ width: "32px" }}
          />
        )}
        <div className="ml-05 semibold">{title}</div>
      </div>
      <input
        placeholder={placeholder}
        type={type}
        className={!disabled ? "textInput bg-fff" : "textInput bg-lightgray"}
        style={{ border: "1px solid #BAB7C8", fontSize: "14px" }}
        value={value}
        onChange={(event) => setValue?.(event.target.value)}
        disabled={disabled}
      />
    </>
  );
};

export { InputProps, FormInput };
