import React, { useState } from "react";
import { ArrowsUpDown } from "../assets/icons/icons";

function DisplayChoice({ passInfoToParent }) {
	const [selectedIndex, setSelectedIndex] = useState(1);

    const selectItem = (selectedIndex) => {
        setSelectedIndex(selectedIndex);
        passInfoToParent(selectedIndex);
    }

	return (
        <div className='row ml-a'>
            <h2 className="ml-a mr-1 semibold" style={{fontSize: "14px"}}>
				Mode d'affichage
            </h2>
			<div
				className='displayChoice shadowEffect'
				style={{ marginRight: ".5rem" }}
                onClick={() => selectItem(1)}
			>
				{[...new Array(3)].map((a, i) => {
					return (
						<div
							className={
								selectedIndex === 1
									? "displayChoice-item-1-selected"
									: "displayChoice-item-1"
							}
							key={i}
						></div>
					);
				})}
			</div>
            <div className='displayChoice shadowEffect' onClick={() => selectItem(2)}>
				{[...new Array(7)].map((a, i) => {
					return (
						<div
							className={
								selectedIndex === 2
									? "displayChoice-item-2-selected"
									: "displayChoice-item-2"
							}
							key={i}
						></div>
					);
				})}
			</div>
			<img
				src={ArrowsUpDown}
				height={18}
				style={{ marginLeft: ".5rem" }}
				alt=''
			/>
		</div>
	);
}

export default DisplayChoice;
