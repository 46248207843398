import React, { useState } from "react";
import {
  Portrait24,
  Briefcase24,
  Strong,
  Capacitations24,
  Calendar24,
} from "../assets/icons/icons";
import { ProtectedImage } from "./ProtectedImage";
import { MediaFormat } from "../data/medias";
import { moods } from "../data/moods";
import CrownImg from "../assets/icons/crown/crown.png";
import { usePatchApplicationPay } from "../api/applications";
import CheckImg from "../assets/icons/check/check.png";
import { capacitations } from "../data/occupations";

export default function ApplicantProfileNotation({
  applicant,
  updateApplicant,
  availableCapacitations,
  isRh,
}) {
  const { candidate } = applicant;
  const [validate, setValidate] = useState(false);
  const [pay, setPay] = useState(applicant.pay);
  const { data: patchResult, loading } = usePatchApplicationPay(
    applicant?.id,
    pay,
    validate,
    setValidate
  );

  function addOrDeleteEntry(array, entry) {
    const filteredEntries = array.filter(
      (filteredEntry) => filteredEntry !== entry
    );
    if (array.length === filteredEntries.length || array.length === 0) {
      filteredEntries.push(entry);
    }
    return filteredEntries;
  }

  return (
    <div className="applicant-full applicant-profile-notation">
      <div className="container" style={{ margin: 0 }}>
        <div className="row mt-2">
          <ProtectedImage
            imageId={candidate.profilePicture?.id}
            alt="candidate picture"
            format={MediaFormat.PROFILE}
            className="image"
            style={{
              borderRadius: ".5rem",
              width: "130px",
              height: "130px",
              objectFit: "cover",
            }}
          />
          <div
            className="col"
            style={{
              height: "130px",
              padding: ".5rem",
              alignItems: "flex-start",
            }}
          >
            <div className="row mb-05  as-fs  fs-125 semibold">
              <img src={Portrait24} alt="profile" className="blue-icon mr-05" />
              <div>{candidate.fullname}</div>
            </div>
            <div className="row mt-05">
              <img
                src={Briefcase24}
                alt="profile"
                className="blue-icon mr-05"
              />
              {candidate.occupationLabel}
            </div>
          </div>
        </div>
        <h2 className="mb-2 mt-2 as-fs semibold">Informations générales</h2>
        <div className="row">
          <div className="col flex-1 ai-fs">
            <div className="row gray mb-05 as-fs">
              <img
                src={Briefcase24}
                alt="profile"
                className="mr-05 grey-icon"
              />
              Profession
            </div>
            <div className="row semibold">{candidate.occupationLabel}</div>
          </div>
          <div className="col flex-1 ai-fs">
            <div className="row gray mb-05 as-fs">
              <img
                src={Capacitations24}
                alt="profile"
                className="mr-05 grey-icon"
              />
              <div>Habilitations</div>
            </div>
            <div className="row semibold">
              {applicant.countOpinions.substituteCapacitation &&
                Object.entries(
                  applicant.candidate.countOpinions.substituteCapacitation
                ).map((substituteCapacitation, key) => (
                  <span key={key}>
                    <span className="underlined">
                      {
                        Object.entries(capacitations).find((capacitation) => {
                          return (
                            capacitation[1].key === substituteCapacitation[0]
                          );
                        })?.[1].label
                      }{" "}
                      ({substituteCapacitation[1]})
                    </span>
                    &nbsp;&nbsp;
                  </span>
                ))}
            </div>
          </div>
        </div>
        <div className="column">
          <div className="row gray mt-1 mb-05">
            <img src={Calendar24} alt="" className="mr-05 grey-icon" />
            Dates de sa mission
          </div>
          <div className="row semibold">
            {applicant.askedDatesLabels
              .map((askedDateLabel) => askedDateLabel.date)
              .join(" ")}
          </div>
          {!isRh && (
            <>
              <h2 className="row semibold as-fs mt-3">
                Votre avis sur cet intérimaire
              </h2>
              <div className="row as-fs mt-2">
                <img src={Strong} alt="" className="mr-05 blue-icon" />
                Quels sont les points forts de cet intérimaire ?
              </div>
              <div
                className="row mt-1"
                style={{
                  justifyContent: "flex-start",
                  paddingBottom: "1rem",
                }}
              >
                {moods.map((mood, i) => {
                  return (
                    <div
                      className={
                        (
                          applicant.noted
                            ? Object.entries(
                                applicant.countOpinions.substituteMood
                              ).find(
                                (substituteMood) =>
                                  substituteMood[0] === mood.key
                              )
                            : applicant.candidate.substituteMoods.find(
                                (substituteMood) => substituteMood === mood
                              )
                        )
                          ? "mr-05 jc-fs row bg-white greenBorder-2 pointer"
                          : "mr-05 jc-fs row bg-white pointer"
                      }
                      style={{
                        fontSize: "2rem",
                        borderRadius: "1.2rem",
                        padding: ".65rem .5rem",
                      }}
                      key={i}
                      onClick={() => {
                        if (!applicant.noted) {
                          const updatedApplicant = applicant;
                          updatedApplicant.candidate.substituteMoods =
                            addOrDeleteEntry(
                              updatedApplicant.candidate.substituteMoods,
                              mood
                            );
                          updateApplicant(updatedApplicant);
                        }
                      }}
                    >
                      <div
                        style={{
                          justifyContent: "flex-start",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          fontSize: "1rem",
                        }}
                      >
                        <div style={{ marginRight: ".2rem" }}>
                          {mood.emoji}{" "}
                        </div>
                        <span
                          style={{ fontSize: "1rem", whiteSpace: "nowrap" }}
                        >
                          {mood.label}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="row as-fs mt-1">
                <img src={Capacitations24} alt="" className="blue-icon mr-05" />
                Souhaitez-vous habiliter cet intérimaire à une de ces
                compétences ?
              </div>
              <div className="row mt-1 mb-3">
                {availableCapacitations.map((capacitation, index) => (
                  <div
                    className={index === 0 ? "row pointer" : "ml-3 row pointer"}
                    onClick={() => {
                      const updatedApplicant = applicant;
                      updatedApplicant.candidate.substituteCapacitations =
                        addOrDeleteEntry(
                          updatedApplicant.candidate.substituteCapacitations,
                          capacitation
                        );
                      updateApplicant(updatedApplicant);
                    }}
                    disabled={applicant.noted}
                  >
                    <div
                      className="circle d-flex jcc aic mr-1"
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "10px",
                        border: "1px solid lightgray",
                      }}
                    >
                      <div
                        className={
                          (
                            applicant.noted
                              ? Object.entries(
                                  applicant.countOpinions.substituteCapacitation
                                ).find(
                                  (substituteCapacitation) =>
                                    substituteCapacitation[0] ===
                                    capacitation.key
                                )
                              : applicant.candidate.substituteCapacitations.find(
                                  (substituteCapacitation) =>
                                    substituteCapacitation === capacitation
                                )
                          )
                            ? "bg-green circle"
                            : "circle"
                        }
                        style={{
                          width: "15px",
                          height: "15px",
                        }}
                      />
                    </div>
                    <div className="bold">{capacitation.label}</div>
                  </div>
                ))}
              </div>
            </>
          )}
          {isRh && (
            <>
              <div className="row as-fs mt-2 bold">
                <img src={CrownImg} alt="" className="mr-1" />
                Montant total à verser à l'intérimaire (net)
              </div>
              <input
                className="p-1 br-15 blackBorder  mb-1 mt-1"
                type="number"
                value={pay || ""}
                onChange={(e) => {
                  setPay(e.target.value);
                }}
                style={{ maxWidth: "9rem" }}
                disabled={patchResult || applicant.pay}
              />
              <button
                className="btn-main  mw-fc"
                disabled={loading || patchResult || applicant.pay}
                onClick={() => {
                  setValidate(true);
                }}
              >
                Valider
                <img src={CheckImg} alt="" className="ml-1" />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
