import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Navbar from "../components/Navbar";
import { useGetApplicationById } from "../api/applications";
import { ProtectedImage } from "../components";
import { MediaFormat } from "../data/medias";

export default function Documents() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [applicant, setApplicant] = useState(null);
  const [validate, setValidate] = useState(false);
  const { id } = useParams();
  const { data: applicantResult } = useGetApplicationById(
    id,
    validate,
    setValidate
  );
  const candidate = applicant?.candidate;

  useEffect(() => {
    if (!applicant) {
      if (state && state.applicant) {
        setApplicant(state.applicant);
      } else if (!applicantResult) {
        setValidate(true);
      } else {
        setApplicant(applicantResult);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicant, applicantResult]);

  return (
    false && (
      <>
        <Navbar />
        <div className="view">
          <div
            className="title as-fs mt-3 bold pointer"
            onClick={() => navigate(-1)}
          >
            {"<"} Retour à la fiche profil
          </div>
          <div className="as-fs mt-2 mb-2 semibold">
            Documents de {candidate.fullname}
          </div>
          <div className="row flexWrap">
            {[
              ...candidate.adelis,
              ...candidate.cpams,
              ...candidate.criminalRecords,
              ...candidate.diplomas,
              ...candidate.drivingLicences,
              ...candidate.ibans,
              ...candidate.idCards,
              ...candidate.vaccinationCertificates,
            ].map((media) => {
              return (
                <ProtectedImage
                  format={
                    media.type.includes("pdf")
                      ? MediaFormat.PDF
                      : MediaFormat.VERTICAL
                  }
                  imageId={media.id}
                  alt="diploma"
                  className="mr-2 mb-2 pointer"
                  allowDownload
                />
              );
            })}
          </div>
        </div>
      </>
    )
  );
}
