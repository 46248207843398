import React from 'react';
import { Block } from '../../pages/create-mission/Block';
import { ConfigProvider, Select, Space } from 'antd';
import { ChevronDown } from '../../assets/icons/icons';

const FormSelectBlock = ({
  icon,
  label,
  alt,
  prefix,
  onChange,
  value,
  options,
}: {
  icon: string;
  label: string;
  alt: string;
  prefix?: string;
  onChange?: (value: string) => void;
  value: string;
  options: (
    | {
        value: number;
        label: string;
      }
    | {
        value: string;
        label: string;
      }
    | {
        value: string;
        label: string;
        disabled: boolean;
      }
  )[];
}) => {
  const handleChange = (value: string) => {
    onChange(value);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            borderRadius: 40,
            controlHeight: 48,
            fontSize: 16,
            fontFamily: '"Open Sans", sans-serif',
            colorPrimaryHover: 'none',
            paddingSM: 20,
          },
        },
      }}
    >
      <Block icon={icon} label={label} alt={alt}>
        <Space wrap>
          {prefix && 'De'}
          <Select
            suffixIcon={<img src={ChevronDown} alt="open" />}
            defaultValue={options?.[0]?.value}
            style={{ width: 100, borderRadius: '30px' }}
            onChange={handleChange}
            rootClassName={'test'}
            options={options}
          />
        </Space>
      </Block>
    </ConfigProvider>
  );
};

export default FormSelectBlock;
