import React from "react";
export function MyCheckbox({
    setRememberMe,
    rememberMe,
    label = "Se souvenir de moi"
}) {
    return <div className="row mt-2 mb-15">
        <div onClick={() => setRememberMe(rememberMe => !rememberMe)} className="circle d-flex jcc aic mr-1" style={{
            width: '1.25rem',
            height: '1.25rem',
            borderRadius: '50%',
            border: '1px solid lightgray'
        }}>
            <div className={rememberMe ? "bg-green circle" : "circle"} style={{
                width: '15px',
                height: '15px'
            }}></div>
        </div>
        <span>{label}</span>
    </div>;
}
