import React from "react";

export default function MissionSmall({
  mission,
  isSelected = false,
  onClicked = () => {},
  isBookmark,
}) {
  return (
    <div className=" row jc-sb">
      <div
        className="row jc-sb bg-fff p-1 br-05 w-100 shadowEffect pointer"
        style={{
          backgroundColor: !isSelected ? "#fff" : "#EE7A9B",
        }}
        onClick={() => onClicked()}
      >
        <div
          style={{ width: "36%", color: !isSelected ? "#221B43" : "#fff" }}
          className="h3 tal semibold"
        >
          {isBookmark ? mission.bookmarkName : mission.institution}
        </div>
        <div
          style={{ width: "30%", color: !isSelected ? "#221B43" : "#fff" }}
          className="h3 tal semibold"
        >
          {mission.serviceLabel}
        </div>
        <div
          style={{ width: "34%", color: !isSelected ? "#221B43" : "#fff" }}
          className="h3 tal semibold"
        >
          {isBookmark ? mission.hourRange : mission.dateRange}
        </div>
      </div>
    </div>
  );
}
