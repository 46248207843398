import React from "react";

type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
};

const Checkbox = ({ value, onChange }: Props) => {
  return (
    <div className="row mr-a as-fs">
      <div className="pointer" onClick={() => onChange(!value)}>
        <input checked={value} type="radio" onChange={() => {}} />
        <span className="ml-1">Oui</span>
      </div>
      <div className="pointer" onClick={() => onChange(!value)}>
        <input
          className="ml-1"
          checked={!value}
          type="radio"
          onChange={() => {}}
        />
        <span className="ml-1">Non</span>
      </div>
    </div>
  );
};

export default Checkbox;
