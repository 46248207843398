import React from "react";
import { Block } from "../Block";
import { GirlPlus32 } from "../../../assets/icons/icons";
import { BooleanValueProps } from "../valueProps";
import SingleCheckbox from "../SingleCheckbox";

type ExperienceProps = {
  valueProps: BooleanValueProps;
  label: string;
};

const Experience = ({ valueProps, label }: ExperienceProps) => (
  <div
    className={
      valueProps.value
        ? "row bg-green p-1 br-125 pointer fff"
        : "row bg-fff p-1 br-125 pointer"
    }
    style={{
      height: "2.5rem",
      width: "fit-content",
      alignSelf: "flex-start",
      border: "1px solid lightgray",
    }}
    onClick={() => valueProps.onChange(!valueProps.value)}
  >
    <div className="mr-1">👍</div>
    <div className={valueProps.value ? "fff" : "gray"}> {label}</div>
  </div>
);

type Props = {
  searchJuniorProps: BooleanValueProps;
  searchAverageProps: BooleanValueProps;
  searchSeniorProps: BooleanValueProps;
  needDriveLicenceProps: BooleanValueProps;
};

const DesiredCandidateBlock = ({
  searchJuniorProps,
  searchAverageProps,
  searchSeniorProps,
  needDriveLicenceProps,
}: Props) => (
  <Block icon={GirlPlus32} label={"Candidat recherché"} alt="desired candidate">
    <div
      className="row w-100"
      style={{
        display: "grid",
        gridTemplateColumns: "max-content max-content max-content",
        columnGap: "11%",
        justifyContent: "space-between",
        alignItems: "start",
        marginTop: 0,
      }}
    >
      <div className="col">
        <span className="mb-05 as-fs">Expérience du candidat recherché</span>
        <div className="row flex-1" style={{ gap: "8px" }}>
          <Experience label="Faible" valueProps={searchJuniorProps} />
          <Experience label="Moyenne" valueProps={searchAverageProps} />
          <Experience label="Élevée" valueProps={searchSeniorProps} />
        </div>
      </div>
      <div className="col ai-fs">
        <SingleCheckbox
          marginBottom="mb-05"
          valueProps={needDriveLicenceProps}
          label="Avec permis de conduire"
        />
      </div>
    </div>
  </Block>
);

export default DesiredCandidateBlock;
