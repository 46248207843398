import {
  Outfit32,
  Cloakroom32,
  Parking32,
  Briefcase32,
  RocketBig,
} from "../../../assets/icons/icons";

type BlockProps = {
  label: string;
  alt: string;
  icon: string;
};

const firstStepBlockProps = {
  occupation: {
    label: "Métier",
    alt: "occupation",
    icon: Briefcase32,
  },
  service: {
    label: "Type de mission",
    alt: "service",
    icon: RocketBig,
  },
  nbSubstitute: {
    label: "Nombre d'intérimaires",
    alt: "number substitute",
    icon: RocketBig,
  },
};

const secondStepBlockProps = {
  outfit: {
    label: "Tenues mises à disposition des intérimaires",
    alt: "outfit",
    icon: Outfit32,
  },
  cloakroom: {
    label: "Vestiaires mis à disposition",
    alt: "cloakroom",
    icon: Cloakroom32,
  },
  parking: {
    label: "Parking mis à disposition",
    alt: "parking",
    icon: Parking32,
  },
};

export { BlockProps, firstStepBlockProps, secondStepBlockProps };
