import React from "react";
import { Block } from "../Block";
import { HourRange } from "./HourRange";
import { Clock32 } from "../../../assets/icons/icons";
import { StringValueProps } from "../valueProps";

type Props = {
  startHourProps: StringValueProps;
  endHourProps: StringValueProps;
};

const HourBlock = ({ startHourProps, endHourProps }: Props) => (
  <Block icon={Clock32} label={"Horaires de la mission"} alt="clock">
    <HourRange startHourProps={startHourProps} endHourProps={endHourProps} />
  </Block>
);

export default HourBlock;
