import React from "react";
import { BadgeInfoProps } from "../pages/recruitment/badgeInfo";

type Props = {
  badgeInfoProps: BadgeInfoProps;
};

function BadgeInfo({ badgeInfoProps }: Props) {
  return (
    <div className={badgeInfoProps.className}>
      <img src={badgeInfoProps.icon} alt="status" />
      <span className="badge-status">{badgeInfoProps.label}</span>
    </div>
  );
}

export default BadgeInfo;
