import React, { useState } from "react";
import { EditButton } from "../../assets/icons/icons.ts";
import { DeleteButton } from "../../assets/icons/icons.ts";
import { CirclePinkCrossWhite as UploadIcon } from "../../assets/icons/icons.ts";
import { ProtectedImage } from "../ProtectedImage";

const UploadImage = ({
  imageId,
  imageUrl,
  setImageUrl,
  className = "",
  editable = true,
}) => {
  const [hasBeenAlreadyUpdated, setHasBeenAlreadyUpdated] = useState(false);

  const handleFiles = (files) => {
    if (files.length > 0) {
      setHasBeenAlreadyUpdated(true);
      setImageUrl({
        url: URL.createObjectURL(files[0]),
        overwrite: true,
      });
    }
  };

  return (
    <div
      className={`pos-r ${className}`}
      style={{
        maxWidth: "344px",
        height: "188px",
        borderRadius: ".5rem",
      }}
    >
      {((!hasBeenAlreadyUpdated && imageId && !imageUrl.overwrite) ||
        imageUrl.url) && (
        <>
          <ProtectedImage
            imageId={imageId}
            setImageUrl={setImageUrl}
            imageUrl={imageUrl.url ?? ""}
            alt="uploaded"
            style={{
              borderRadius: ".5rem",
              height: "188px",
              objectFit: "cover",
            }}
          />
          {editable && (
            <img
              src={DeleteButton}
              alt="delete"
              style={{
                position: "absolute",
                top: "8px",
                right: "48px",
                width: "32px",
              }}
              onClick={() => {
                setHasBeenAlreadyUpdated(true);
                setImageUrl({ url: "", overwrite: true });
              }}
            />
          )}
        </>
      )}
      <label>
        {editable && imageUrl.url && (
          <img
            src={EditButton}
            alt="upload"
            style={{ top: "8px", right: "8px" }}
            className="bg-pink circle pos-a"
          />
        )}
        {!imageUrl.url &&
          (!imageId || hasBeenAlreadyUpdated || imageUrl.overwrite) && (
            <>
              <div
                style={{
                  borderRadius: ".5rem",
                  height: "100%",
                  width: "344px",
                  backgroundColor: editable ? "#EDEDED" : "",
                }}
              />
              {editable && (
                <img
                  src={UploadIcon}
                  alt="upload"
                  style={{
                    width: "58px",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    left: "0",
                    bottom: "0",
                    margin: "auto",
                  }}
                />
              )}
            </>
          )}
        {editable && (
          <input
            type="file"
            name="upload"
            style={{ display: "none" }}
            onChange={(e) => {
              handleFiles(e.target.files);
            }}
            // Needed in the case where user selects the same file as before after deletion
            value=""
          />
        )}
      </label>
    </div>
  );
};

export { UploadImage };
