import React from "react";

type Props = {
  length: number;
  label: string;
  suffix?: string;
};

const Plural = ({ length, label, suffix }: Props) => (
<>
{length > 1 || length === 0 ? "Vos" : "Votre"} {length > 1 || length === 0 ? length : null} {label}
  {length > 1 || length === 0 ? "s" : ""}{suffix ? " " + suffix : null}
</>
);

export { Plural };
