import React from "react";
import { StringValueProps } from "../valueProps";

type Props = {
  startHourProps: StringValueProps;
  endHourProps: StringValueProps;
};

const HourRange = ({ startHourProps, endHourProps }: Props) => (
  <div className="row as-fs">
    <div className="mr-05">De</div>
    <input
      value={startHourProps.value}
      type="time"
      className={`p-1 br-175 ${startHourProps.value ? 'black' : 'gray'}`}
      style={{ fontSize: "1rem", height: 48 }}
      onChange={(e) => startHourProps.onChange(e.target.value)}
    />
    <div className="ml-05 mr-05">à </div>
    <input
      value={endHourProps.value}
      type="time"
      className={`p-1 br-175 ${endHourProps.value ? 'black' : 'gray'}`}
      style={{ fontSize: "1rem", height: 48 }}
      onChange={(e) => endHourProps.onChange(e.target.value)}
    />
  </div>
);

export { HourRange };
