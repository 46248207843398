import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  useGetInstitutions,
  useGetInstitutionsCount,
} from "../api/institutions";
import { ProtectedImage } from "./ProtectedImage";

import { Executive24, Laboratory24, Location24 } from "../assets/icons/icons";
import { addInstitution, Button } from "./buttons/buttons";
import InfiniteScroll from "react-infinite-scroll-component";
import { Plural } from "./Plural";

export default function EstablishmentList({ blurParent, changeCpt }) {
  const [page, setPage] = useState(1);
  const [validate, setValidate] = useState(true);
  const { data } = useGetInstitutions(page, validate, setValidate);
  const { data: count } = useGetInstitutionsCount();
  const navigate = useNavigate();
  return (
    data &&
    count && (
      <div
        className="ml-2 flex-2 pl-2"
        style={{ borderLeft: "1px solid lightgray" }}
      >
        <h1 className="mb-2">
          <Plural length={count} label="établissement" />
        </h1>
        <InfiniteScroll
          dataLength={data.length}
          next={() => {
            setPage(page + 1);
            setValidate(true);
          }}
          hasMore={data.length % 25 === 0}
        >
          {data.map((institution, i) => {
            return (
              <Link
                to={`/profile/establishments/${institution.id}`}
                key={i}
                className="row br-05 bg-fff mb-1"
              >
                <ProtectedImage
                  imageId={data[i].photos[0]?.id}
                  alt="preview"
                  style={{
                    width: "190px",
                    height: "100px",
                    borderRadius: ".5rem 0 0 .5rem",
                    objectFit: "cover",
                  }}
                />
                <div
                  className="col jc-sb"
                  style={{ minHeight: "100px", padding: ".5rem 1rem" }}
                >
                  <div className="row " style={{ alignSelf: "flex-start" }}>
                    <img src={Laboratory24} alt="lab" />
                    <div className="h2 bold ml-05">{institution.name}</div>
                  </div>
                  <div className="row as-fs gray">
                    <img src={Location24} alt="lab" />
                    <div className="gray  ml-05">
                      {institution.address.label}
                    </div>
                  </div>
                  <div className="row as-fs gray">
                    <img src={Executive24} alt="lab" />
                    <div className="bold  ml-05">
                      {institution.employees
                        .map((employee) => employee.fullname)
                        .join(", ")}
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </InfiniteScroll>
        <Button
          buttonProps={addInstitution}
          onClicked={() => navigate("/profile/establishments/add")}
        />
      </div>
    )
  );
}
