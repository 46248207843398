import React, { useEffect, useState } from "react";
import { Block } from "../Block";
import Bookmarks from "../Bookmarks";
import { Files } from "../../../assets/icons/icons";
import { useGetBookmarks } from "../../../api/bookmarks";
import { useGetWelcomeBoardById } from "../../../api/welcomeboards";

type Props = {
  institutionId: string;
  selectedBookmark: any | null;
  setSelectedBookmark: (selectedBookmark: any) => void;
};

const BookmarkBlock = ({
  institutionId,
  selectedBookmark,
  setSelectedBookmark,
}: Props) => {
  const { data, loading, error } = useGetBookmarks(
    true,
    () => {},
    institutionId
  );
  const [validate, setValidate] = useState(false);
  const [bookmark, setBookmark] = useState(null);
  const { data: welcomeBoard } = useGetWelcomeBoardById(
    bookmark?.welcomeBoardId,
    validate,
    setValidate
  );

  useEffect(() => {
    if (welcomeBoard) {
      setSelectedBookmark({
        ...welcomeBoard,
        ...bookmark,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [welcomeBoard]);

  return (
    <Block icon={Files} label={"Modèle de missions"} alt="bookmarks">
      <Bookmarks
        availableBookmarks={data}
        selectedBookmark={selectedBookmark}
        setSelectedBookmark={(selectedBookmark: any) => {
          setBookmark(selectedBookmark);
          setValidate(true);
        }}
      />
    </Block>
  );
};

export default BookmarkBlock;
