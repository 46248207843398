import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/buttons/Button";
import { navigateToNotificationsPage } from "../../components/buttons/buttons";

type Props = {
  notifications;
};

export default function Notifications({ notifications }: Props) {
  const navigate = useNavigate();
  return (
    <div style={{ zIndex: 10 }}>
      <div
        className="bg-fff blue shadowEffect p-1 br-05"
        style={{
          position: "absolute",
          top: "56px",
          width: "416px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "-18px",
            left: "50%",
            display: "inline-block",
            width: 0,
            height: 0,
            borderStyle: "solid",
            borderWidth: "0 14px 18px 14px",
            borderColor: "transparent transparent white transparent",
            transform: "translateX(-50%)",
          }}
        />
        {notifications.map((notification: any, index: number) => (
          <div className="row pointer" key={index}>
            <div className="col ">
              <div className="as-fs bold">{notification.title}</div>
              <div className="as-fs">{notification.message}</div>
            </div>
            <div className="gray ml-a" style={{ fontSize: "12px" }}>
              {notification.updatedAtLabel}
            </div>
          </div>
        ))}
        <Button
          buttonProps={navigateToNotificationsPage}
          onClicked={() => {
            navigate("/notifications");
          }}
        />
      </div>
    </div>
  );
}
