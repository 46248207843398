import React, { useState, useEffect } from 'react';
import { useGetApplicationsByWelcomeBoard } from '../../api/applications';
import { WelcomeBoardState } from '../../api/enums';
import SignInSystem from '../../components/SignInSystem';

type Props = {
  mission: any;
};

export default function PointSystem({ mission }: Props) {
  const [validate, setValidate] = useState(true);
  const [lastMissionId, setLastMissionId] = useState(mission.id);
  // TODO
  const { data: applicants } = useGetApplicationsByWelcomeBoard(
    mission.id,
    [
      WelcomeBoardState.Ongoing,
      WelcomeBoardState.Absent,
      WelcomeBoardState.Finished,
    ].join(','),
    validate,
    setValidate
  );

  useEffect(() => {
    if (mission.id !== lastMissionId) {
      setLastMissionId(mission.id);
      setValidate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mission]);

  const filteredApplicants = applicants?.filter((applicant: any) =>
    applicant.askedDates.some((askedDate: any) => askedDate.state !== 'blocked')
  );
  return (
    <>
      {filteredApplicants &&
        filteredApplicants.map((applicant: any, i: number) => {
          return (
            <SignInSystem
              key={i}
              applicant={applicant}
              refreshApplicants={() => setValidate(true)}
              theoricalStartDate={mission.startAt}
              theoricalEndDate={mission.endAt}
            />
          );
        })}
    </>
  );
}
