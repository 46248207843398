import { Hey, GoodJob } from "../../assets/popup/popup";
import { PopupProps } from "../PopupInfo";
import { Logout24, Check, Eye } from "../../assets/icons/icons";
import { CommentPopupProps } from "../CommentPopup";

const PopupLogoutProps: PopupProps = {
  title: "Hey ! ✋🏻️",
  image: Hey,
  description:
    "Êtes-vous sûr de vouloir vous déconnecter ? Vous devrez entrer votre e-mail et mot de passe lors de votre prochaine connexion 😕",
  buttonLabel: "Se déconnecter",
  buttonIcon: Logout24,
};

const PopupValidateMissionProps: PopupProps = {
  title: "Hey ! ✋🏻️",
  image: Hey,
  description: "",
  buttonLabel: "Confirmer",
  buttonIcon: Check,
};

const PopupResetPassword: PopupProps = {
  title: "Hey ! ✋🏻️",
  image: Hey,
  description: "Un email vient de vous être envoyer.",
  buttonLabel: "Confirmer",
  buttonIcon: Check,
};

const PopupSendPassword: PopupProps = {
  title: "Hey ! ✋🏻️",
  image: Hey,
  description: "Votre mot de passe à été modifier avec succès.",
  buttonLabel: "Retourner à l'écran de connexion",
  buttonIcon: Check,
};

const PopupAssignCandidatesCompleted: PopupProps = {
  title: "Félicitations ! 🎉️",
  image: GoodJob,
  description:
    "Toutes les dates de la mission ont été confiées à un/des candidat(s) ! 😁",
  buttonLabel: "Voir mes missions",
  buttonIcon: Eye,
};

const PopupAssignCandidatesUncompleted: PopupProps = {
  title: "Félicitations ! 🎉️",
  image: GoodJob,
  description:
    "Une partie des dates de la mission ont été confiées à un/des candidat(s) ! 😁",
  buttonLabel: "Voir mes missions",
  buttonIcon: Eye,
};

const PopupAssignCandidateError: PopupProps = {
  title: "Hey ! ✋🏻️",
  image: Hey,
  buttonLabel: "Voir mes missions",
  buttonIcon: Eye,
  description: null
};

const PopupDeclineMission: CommentPopupProps = {
  title: "Refus de mission ! ⚠",
  placeholder: "Expliquez ici les raisons de votre refus...",
};

const PopupDailyComment: CommentPopupProps = {
  title: "Votre commentaire 💬",
  placeholder:
    "Un problème avec le candidat ? Une remarque ? Informez votre RH ici...",
};

const PopupAbsenceComment: CommentPopupProps = {
  title: "Votre commentaire 💬",
  placeholder: "Commentaire absence",
};

export {
  PopupLogoutProps,
  PopupValidateMissionProps,
  PopupResetPassword,
  PopupSendPassword,
  PopupDeclineMission,
  PopupDailyComment,
  PopupAbsenceComment,
  PopupAssignCandidatesCompleted,
  PopupAssignCandidatesUncompleted,
  PopupAssignCandidateError
};
