import { ButtonProps, Button } from './Button';
import {
  Next,
  HandSelect,
  CheckGreen,
  Add,
  Check,
  Calendar32,
  EyeWhite,
} from '../../assets/icons/icons';

const pinkProps: ButtonProps = {
  buttonClassName: 'btn-main',
  labelClassName: 'fff',
  alt: '',
  type: 'submit',
  iconPosition: 'right',
  label: '',
  icon: '',
};

const greenSelected: ButtonProps = {
  buttonClassName: 'btn-green-light btn-selected-candidate',
  labelClassName: 'fff',
  alt: '',
  type: 'submit',
  iconPosition: 'right',
  label: '',
  icon: '',
};

const greenProps: ButtonProps = {
  buttonClassName: 'btn-green',
  labelClassName: 'fff',
  alt: '',
  type: 'submit',
  iconPosition: 'right',
  label: '',
  icon: '',
};

const next: ButtonProps = {
  ...pinkProps,
  label: 'Etape suivante',
  icon: Next,
};

const publishMission: ButtonProps = {
  ...pinkProps,
  label: 'Publier la mission',
  icon: Next,
};

const connect: ButtonProps = {
  ...pinkProps,
  type: 'button',
  buttonClassName: 'btn-main w-100',
  label: 'Se connecter',
  icon: Next,
};

const gcu: ButtonProps = {
  ...pinkProps,
  type: 'button',
  buttonClassName: 'btn-main w-100',
  label: 'Accéder au site',
  icon: Next,
};

const validateScroll: ButtonProps = {
  ...pinkProps,
  type: 'button',
  buttonClassName: 'btn-main no-footer',
  label: 'Valider',
  icon: Check,
};

const validateHours: ButtonProps = {
  ...pinkProps,
  type: 'button',
  buttonClassName: 'btn-main w-100',
  label: 'Valider',
  icon: Check,
};

const calendar: ButtonProps = {
  ...pinkProps,
  type: 'button',
  buttonClassName: 'btn-main',
  label: '',
  icon: Calendar32,
};

const validate: ButtonProps = {
  ...pinkProps,
  type: 'submit',
  buttonClassName: 'btn-main w-100',
  icon: Next,
  label: 'Valider',
};

const selectApplicant: ButtonProps = {
  ...pinkProps,
  icon: HandSelect,
  label: 'Sélectionner le candidat',
  type: 'button',
  alternateLabel: 'Candidat sélectionné',
  alternateButtonClassName: 'btn-main-alternate',
  alternateIcon: CheckGreen,
  alternateAlt: 'unselect applicant',
};

const seeProfile: ButtonProps = {
  ...greenProps,
  icon: EyeWhite,
  iconPosition: 'left',
  label: 'Voir le profil',
  type: 'button',
};

const selectedCandidate: ButtonProps = {
  ...greenSelected,
  icon: CheckGreen,
  iconPosition: 'left',
  label: 'Sélectionné(e)',
  type: 'button',
  alt: 'checked',
};

const addProps: ButtonProps = {
  ...pinkProps,
  icon: Add,
  buttonClassName: 'btn-main mt-3',
  type: 'button',
};

const addManager: ButtonProps = {
  ...addProps,
  label: 'Ajouter un manager de proximité',
};

const addInstitution: ButtonProps = {
  ...addProps,
  label: 'Ajouter un établissement',
};

const createMission: ButtonProps = {
  ...addProps,
  buttonClassName: 'btn-main',
  label: 'Publier une mission',
};

const navigateToNotificationsPage: ButtonProps = {
  ...pinkProps,
  buttonClassName: 'btn-main w-100 mt-1',
  label: 'Voir plus',
  type: 'button',
  icon: Next,
};

const addApplicant: ButtonProps = {
  ...addProps,
  label: 'Ajouter un candidat',
};

const validateApplicant: ButtonProps = {
  ...pinkProps,
  label: 'Valider le candidat',
  icon: Next,
};

const CancelRefused: ButtonProps = {
  buttonClassName: 'btn-candidat',
  labelClassName: 'fff',
  alt: '',
  iconPosition: 'right',
  label: 'Annuler le refus',
  type: 'button',
  icon: Next,
};

export {
  Button,
  next,
  publishMission,
  connect,
  validate,
  selectApplicant,
  selectedCandidate,
  seeProfile,
  addManager,
  addInstitution,
  createMission,
  navigateToNotificationsPage,
  gcu,
  validateScroll,
  validateHours,
  calendar,
  CancelRefused,
  addApplicant,
  validateApplicant,
};
