import React, { createContext, useEffect, useState } from "react";
import { ApplicationDateState, WelcomeBoardState } from "../../api/enums";
import { usePatchWelcomeBoardApplications } from "../../api/welcomeboards";

type SelectedDate = {
  id: string;
  date: string;
  state: string;
};

type SelectedCandidate = {
  id: string;
  selectedDates: SelectedDate[];
};

type CandidateApplication = {
  idCandidate: string;
  idApplicant: string;
  idAskedDate: string;
};

type ApplicationInfo = {
  dateLabel: string;
  nbSubstitute: number;
  applicantsList: CandidateApplication[];
  completed: boolean;
};

type ApplicantProps = {
  applicants: any;
  setApplicants: (applicants: any) => void;
  setMission: (mission: any) => void;
  selectedCandidates: SelectedCandidate[];
  selectedInterim: SelectedCandidate;
  selectableDates: string[];
  setSelectableDates: (selectableDate: string[]) => void;
  addOrRemoveCandidate: (triggeredId: string) => void;
  addOrRemoveApplicationDates: (
    candidateApplication: CandidateApplication,
    dateLabel: string
  ) => void;
  getAvailableDates: () => any;
  mission: any;

  applicationsInfos: Record<string, ApplicationInfo>;
};

export const ApplicantContext = createContext<ApplicantProps>({
  applicants: null,
  setApplicants: (_: any) => {},
  setMission: (_: any) => {},
  selectedCandidates: null,
  addOrRemoveCandidate: (_: string) => {},
  getAvailableDates: () => {},
  mission: null,

  addOrRemoveApplicationDates: () => {},
  selectedInterim: null,
  selectableDates: [],
  setSelectableDates: (_: string[]) => {},
  applicationsInfos: null,
});

export const ApplicantProvider = ({ children }) => {
  const [applicants, setApplicants] = useState(null);
  const [mission, setMission] = useState(null);
  const [selectedCandidates, setSelectedCandidates] =
    useState<SelectedCandidate[]>(null);
  const [selectedInterim, setSelectedInterim] =
    useState<SelectedCandidate>(null);
  const [selectableDates, setSelectableDates] = useState<Array<string>>(
    getAvailableDates()
  );

  const [applicationsInfos, setApplicationInfos] = useState<
    Record<string, ApplicationInfo>
  >({});

  useEffect(() => {
    if (applicants && mission) {
      const updatedSelectedCandidates: SelectedCandidate[] = [];
      applicants.forEach((applicant: any) => {
        const selectedDates = applicant.askedDatesLabels.filter(
          (askedDate: any) => askedDate.state === "accepted"
        );
        if (selectedDates.length > 0) {
          updatedSelectedCandidates.push({
            id: applicant.id,
            selectedDates: selectedDates,
          });
        }
      });
      setSelectedCandidates(updatedSelectedCandidates);
    }

    // Will update applicationsInfos

    if (!!applicationsInfos && !!applicants && !!mission) {
      console.log("> Call once here");
      const nbSubstitute = mission.nbSubstitute || 0;

      const getApplicantsListForDateLabel = (dateLabel) => {
        // Get related applicant
        const applicationWithThisDateInAskedDate = applicants
          .filter((e) =>
            e.askedDatesLabels?.some(
              (e) =>
                e.date === dateLabel &&
                e.state === ApplicationDateState.Accepted
            )
          )
          ?.map((e) => {
            return {
              idCandidate: e.candidate.id,
              idApplicant: e.id,
              idAskedDate: e.askedDatesLabels?.find(
                (adl) =>
                  adl.date === dateLabel &&
                  adl.state === ApplicationDateState.Accepted
              )?.id,
            };
          });

        // console.log({ applicationWithThisDateInAskedDate });
        // const idApplicant =
        // const idCandidate = ''
        // const idAskedDate = ''

        return applicationWithThisDateInAskedDate;
      };

      let result = Object.entries(mission.datesList)?.reduce(
        (acc, [key, value]) => ({
          ...acc,
          [value as string]: {
            applicantsList: getApplicantsListForDateLabel(value),
            nbSubstitute:
              nbSubstitute - getApplicantsListForDateLabel(value)?.length,
            completed:
              nbSubstitute - getApplicantsListForDateLabel(value)?.length <= 0,
            // completed: mission.welcomeBoardDatesLabels?.find(
            //   (e) => e.dateLabel === value
            // )?.completed,
          },
        }),
        {}
      );

      setApplicationInfos(result);

      console.log({ result });
    }
  }, [applicants]);

  /**
   * Do auto checking to select all dates possibles taking into account the completed state
   * @deprecated
   * @param triggeredId
   */
  function addOrRemoveCandidate(triggeredId: string) {
    let updatedSelectedCandidates: SelectedCandidate[];
    const alreadySelected = selectedCandidates.some(
      (selectedCandidate) => selectedCandidate.id === triggeredId
    );
    if (!alreadySelected || mission.status !== WelcomeBoardState.Ongoing) {
      if (alreadySelected) {
        updatedSelectedCandidates = selectedCandidates.filter(
          (selectedCandidate: SelectedCandidate) =>
            selectedCandidate.id !== triggeredId
        );
      } else {
        updatedSelectedCandidates = [
          ...selectedCandidates,
          { id: triggeredId, selectedDates: [] },
        ];
      }
      updatedSelectedCandidates = updatedSelectedCandidates.map(
        (selectedCandidate: SelectedCandidate) => {
          return { id: selectedCandidate.id, selectedDates: [] };
        }
      );
      mission.datesBetween.forEach((date: string) => {
        const maxSubstitute = mission.nbSubstitute;
        let currentNumber = 0;
        updatedSelectedCandidates = updatedSelectedCandidates.map(
          (selectedCandidate: SelectedCandidate) => {
            const applicant = applicants.find(
              (applicant: any) => applicant.id === selectedCandidate.id
            );
            const completeDate = applicant?.askedDatesLabels.find(
              (askedDateLabel: any) => askedDateLabel.date === date
            );
            const selectedDates = selectedCandidate.selectedDates;
            if (completeDate && currentNumber < maxSubstitute) {
              currentNumber = currentNumber + 1;
              selectedDates.push(completeDate);
            }
            return {
              id: selectedCandidate.id,
              selectedDates: selectedDates,
            };
          }
        );
      });
      setSelectedCandidates(updatedSelectedCandidates);
    }
  }

  function addOrRemoveApplicationDates(
    candidateApplication: CandidateApplication,
    dateLabel: string
  ) {
    console.log(
      ">> Add applicant ",
      candidateApplication,
      " for date : ",
      dateLabel
    );

    // Add applicant
    if (
      applicationsInfos[dateLabel].applicantsList.some(
        (e) => e.idApplicant === candidateApplication.idApplicant
      )
    ) {
      applicationsInfos[dateLabel].applicantsList = applicationsInfos[
        dateLabel
      ].applicantsList.filter(
        (e) => e.idApplicant !== candidateApplication.idApplicant
      );

      if (
        applicationsInfos[dateLabel].nbSubstitute + 1 <=
        mission.nbSubstitute
      ) {
        applicationsInfos[dateLabel].nbSubstitute += 1;
      }
    } else if (applicationsInfos[dateLabel].completed === false) {
      applicationsInfos[dateLabel].applicantsList.push(candidateApplication);
      applicationsInfos[dateLabel].nbSubstitute -= 1;
    }

    // Update infos

    applicationsInfos[dateLabel].completed =
      applicationsInfos[dateLabel].nbSubstitute <= 0;
    setApplicationInfos({ ...applicationsInfos });
  }

  function getAvailableDates() {
    let availableDates = [];
    mission?.datesBetween?.forEach((date: string) => {
      const maxSubstitute = mission.nbSubstitute;
      let currentNumber = 0;
      selectedCandidates?.forEach((selectedCandidate: SelectedCandidate) => {
        const applicant = applicants.find(
          (applicant: any) => applicant.id === selectedCandidate.id
        );
        const completeDate = applicant?.askedDatesLabels.find(
          (askedDateLabel: any) => askedDateLabel.date === date
        );
        if (completeDate && currentNumber < maxSubstitute) {
          currentNumber = currentNumber + 1;
        }
      });
      if (currentNumber < maxSubstitute) {
        availableDates.push(date);
      }
    });
    return availableDates || [];
  }

  return (
    <ApplicantContext.Provider
      value={{
        applicants,
        setApplicants,
        selectedCandidates,
        setMission,
        addOrRemoveCandidate,
        getAvailableDates,
        mission,

        addOrRemoveApplicationDates,
        selectedInterim,
        selectableDates,
        setSelectableDates,
        applicationsInfos,
      }}
    >
      {children}
    </ApplicantContext.Provider>
  );
};
