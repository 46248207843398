import React from "react";
import { Block } from "../Block";
import { Select } from "../../../components";
import { SelectItem } from "../../../data/SelectItem";
import { BlockProps } from "../step-two/blockProps";

type Props = {
  selectedItem: SelectItem;
  items: SelectItem[];
  onItemSelected: (value: SelectItem) => void;
  placeholder: string;
  blockProps: BlockProps;
  isClickable?: boolean;
};

const SelectBlock = ({
  selectedItem,
  items,
  onItemSelected,
  placeholder,
  blockProps,
  isClickable,
}: Props) => (
  <Block icon={blockProps.icon} label={blockProps.label} alt={blockProps.alt}>
    <Select
      selectedItem={selectedItem}
      placeholder={placeholder}
      items={items}
      onItemSelected={onItemSelected}
      isClickable={isClickable}
    />
  </Block>
);

export default SelectBlock;
