import React, { useContext, useEffect, useMemo, useState } from 'react';
import StrengthIconCorrespondances from './StrengthIconCorrespondances';
import { ProtectedImage } from './ProtectedImage';
import { MediaFormat } from '../data/medias';
import { useNavigate } from 'react-router-dom';
import {
  Briefcase32,
  Portrait32,
  Calendar32,
  CrossRed,
} from '../assets/icons/icons';

import { Calendar24 as CalendarIcon } from '../assets/icons/icons';
import { asDate } from '../api/format';
import Calendar from './Calendar';
// import styles from '../assets/styles/applicant.module.css';
import { Col, Space, Tooltip, Tag, Button as AndButton } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { Button } from './buttons/Button';
import moment from 'moment';
import { ApplicationDateState } from '../api/enums';
import { AskedDate } from '../types/ApplicationTypes';
import { ApplicantContext } from './context/ApplicantContext';
import { seeProfile, selectedCandidate } from './buttons/buttons';
import useFetch from '../api/factory';

export default function Applicant({
  applicant,
  setIsSelected,
  isSelected,
  selectedDates,
  selectedInterim,
  mission,
  assign,
  addOrRemoveApplicationDates,
}) {
  // ------------------------- PROPERTIES
  const navigate = useNavigate();
  const { candidate } = applicant;
  const { welcomeBoardDatesLabels } = mission;
  const [calendarVisible, setCalendarVisible] = useState(false);

  const askedDates = applicant.askedDates.map((askedDate) =>
    asDate(askedDate.date)
  );

  const moodList = applicant.candidate.countOpinions.substituteMood;
  const [completedDates, setCompletedDates] = useState<string[]>([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [sldDates, setSldDates] = useState([]);

  const { applicationsInfos } = useContext(ApplicantContext);

  // ------------------------- LIFE CYCLE

  const { patch: patchCancelRefused } = useFetch(
    `application/${applicant.id}/requeue`,
    true
  );
  useEffect(() => {
    console.log({ [candidate.fullname]: applicant });
    // call.get(2);
    // console.log({ askedDates });
    // console.log({ assign });
    // console.log({ mission });
    // console.log({ selectedDates });
    // console.log({ completedDates });

    // Find all available date

    // Are disable
    // in "selection" type
    // • past start mission datetime
    // • welcomeBoardDate is completed
    // • live selected another candidate
    setSldDates(
      applicant.askedDates
        ?.filter((e) => e.state === ApplicationDateState.Accepted)
        ?.map((e) => moment(e.date).format('YYYY-MM-DD'))
    );
    // • past start mission datetime && askedDate state != blocked
    // let result = removeBlockedDates(applicant.askedDates);
    // result = removeCompletedDates(result);
    // result = removePastDates(result);
    // • welcomeBoardDate is completed
    // .filter((item: AskedDate) => {
    //   return !completedDates
    //     ?.map((e) => e.dateLabel)
    //     .includes(item.dateLabel);
    // })
    // ?.map((e) => e.dateLabel);
    // console.log({ result });
    // setAvailableDates(result?.map((e) => e.dateLabel));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('> updated sldDate : ', sldDates);
  }, [sldDates]);

  useEffect(() => {
    console.log('> Application infos has changed : ', {
      applicationsInfos,
    });

    configureAvailableDates();

    // Update completed

    // completedDates = (
    // welcomeBoardDatesLabels as WelcomeBoardDatesLabels[]
    // )?.filter((item) => item.completed === true);

    configureCompletedDates();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationsInfos]);

  // ------------------------- METHODS

  const configureAvailableDates = async () => {
    let result = keepOnlySelectableStatus(applicant.askedDates);
    // console.log({ 1: result });
    result = removeCompletedDates(result);
    // console.log({ 2: result });
    result = removePastDates(result);
    // console.log({ 3: result });
    setAvailableDates(result?.map((e) => e.dateLabel));
  };

  const configureCompletedDates = async () => {
    setCompletedDates(
      Object.keys(applicationsInfos)
        .filter(
          (e) =>
            applicationsInfos[e].completed === true &&
            !!applicationsInfos[e].applicantsList?.find(
              (e) =>
                e.idApplicant !== applicant.id && e.idCandidate !== candidate.id
            )
        )
        ?.map((e) => moment(e, 'DD/MM/YYYY').format('YYYY-MM-DD'))
    );
  };

  // ------------------------- HELPERS

  const keepOnlySelectableStatus = (askedD: AskedDate[]) => {
    return askedD?.filter(
      (item) =>
        moment(item.date).isAfter(moment(mission.todayStartAt)) &&
        (item.state === ApplicationDateState.Pending ||
          item.state === ApplicationDateState.Accepted)
    );
  };

  const removeCompletedDates = (askedD: AskedDate[]) => {
    // Remove only if applicant has not select it yet
    const selectableDates = Object.keys(applicationsInfos)?.filter(
      (e) =>
        applicationsInfos[e].completed === false ||
        (applicationsInfos[e].completed === true &&
          applicationsInfos[e].applicantsList.some(
            (e) =>
              e.idApplicant === applicant.id && e.idCandidate === candidate.id
          ))
    );
    return askedD.filter((item: AskedDate) =>
      selectableDates.includes(item.dateLabel)
    );
  };

  const removePastDates = (askedD: AskedDate[]) => {
    return askedD.filter((e) =>
      moment().isBefore(moment(e.date).add(-1, 'minute'))
    );
  };

  const hasAcceptedDate = useMemo<boolean>(() => {
    return applicant?.askedDates.some(
      (e) => e.state === ApplicationDateState.Accepted
    );
  }, [applicant]);

  // ------------------------- ACTIONS

  const onDateSelected = (date) => {
    if (sldDates.indexOf(date) !== -1) {
      setSldDates([...sldDates.filter((item) => item !== date)]);
    } else {
      setSldDates([...sldDates, date]);
    }
    const dateLabel = moment(date).format('DD/MM/YYYY');

    addOrRemoveApplicationDates(
      {
        idApplicant: applicant.id,
        idCandidate: candidate.id,
        idAskedDate: applicant.askedDatesLabels?.filter(
          (e) => e.date === dateLabel
        )?.[0]?.id,
      },
      dateLabel
    );
  };

  // ------------------------- COMPONENTS

  const ApplicantCardInfoLine = ({
    icon,
    alt,
    title,
    titleWeight = 'medium',
    showSatus = false,
  }) => {
    return (
      <>
        <div className='row'>
          <img
            src={icon}
            alt={alt}
            style={{
              marginRight: '.5rem',
              width: '2rem',
              height: '2rem',
            }}
          />
          <span className={`${titleWeight} subtitle`}>
            {title}
            {applicant?.isRefused && showSatus ? (
              <>
                {' - '}
                <Tag
                  bordered={false}
                  color='error'
                  style={{ marginBottom: 10 }}
                >
                  Refusé
                </Tag>
              </>
            ) : null}
          </span>
        </div>
      </>
    );
  };

  // ------------------------- RENDER

  const CalendarButton = () => {
    return (
      <div
        style={{
          position: 'absolute',
          top: '1.063rem',
          right: '1.063rem',
          alignItems: 'end',
        }}
      >
        <button
          type='button'
          className='btn-square-calendar-md pinkBorder bg-pinkWhite br-05 '
          onClick={() => {
            setCalendarVisible(!calendarVisible);
          }}
        >
          <img src={Calendar32} alt='calendar' className='pink-icon' />
        </button>
        {calendarVisible && (
          <div style={{ position: 'absolute', right: 0, zIndex: 1 }}>
            <Calendar
              minDate={moment(askedDates?.[0], 'DD/MM/YYYY').toDate() || null}
              type='selection'
              highlightedDates={sldDates}
              availableDates={availableDates}
              completedDates={completedDates}
              finishedDates={applicant.askedDatesLabels
                ?.filter((e) => e.state === ApplicationDateState.Finished)
                ?.map((e) => e.date)}
              defaultAvailableDates={applicant.askedDatesLabels?.map(
                (e) => e.date
              )}
              defaultDate={applicant.askedDates[0].date}
              onDateSelected={onDateSelected}
              dismiss={() => setCalendarVisible(false)}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {candidate ? (
        <Col flex={'100%'}>
          {/* <Col flex={1 / 2} style={{ maxWidth: "50%" }}></Col> */}
          <div className='shadowEffect applicant-card'>
            <Col
              flex={'auto'}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              {/* <Space direction="vertical" style={{ height: "100%", flex: "" }}> */}
              {/* <ProtectedImage
                    imageId={candidate.profilePicture?.id}
                    alt="candidate picture"
                    format={MediaFormat.PROFILE}
                    className="image"
                    style={{
                      objectFit: "cover",
                      width: "175px",
                      height: "175px",
                    }}
                  /> */}

              {/* <Space direction="vertical" style={{ flex: "auto" }}> */}
              {/* User infos */}
              <div style={{ flex: 1, height: 'auto' }}>
                <ApplicantCardInfoLine
                  icon={Portrait32}
                  alt={'name'}
                  title={candidate.fullname}
                  titleWeight={'semibold'}
                  showSatus={true}
                />
                <ApplicantCardInfoLine
                  icon={Briefcase32}
                  alt={'name'}
                  title={candidate.occupationLabel}
                />
                {applicant?.isRefused ? (
                  <AndButton
                    icon={<ReloadOutlined />}
                    style={{ marginTop: '0.5rem' }}
                    danger
                    onClick={async () => {
                      const response = await patchCancelRefused();
                      window.location.reload();
                    }}
                  >
                    Annuler le refus
                  </AndButton>
                ) : null}

                {/* TODO : REMOVED IN @ +3 VERSION */}
                {/* <ApplicantCardInfoLine
                        icon={Capacitations32}
                        alt={"name"}
                        title={candidate.serviceLabels.join(" - ")}
                      /> */}
              </div>
              {/* </Space> */}
              {/* Buttons block */}
              <Space size={8} direction='vertical'>
                {assign && hasAcceptedDate && (
                  <Button buttonProps={selectedCandidate} />
                )}
                <Button
                  buttonProps={seeProfile}
                  onClicked={() => {
                    navigate(
                      `/recruitments/mission/${mission?.id}/applicant/${applicant.id}`
                    );
                  }}
                />
              </Space>
              {/* </Space> */}
            </Col>
            {assign ? (
              <Col flex={'100px'}>
                <Calendar
                  minDate={
                    moment(askedDates?.[0], 'DD/MM/YYYY').toDate() || null
                  }
                  type='selection'
                  highlightedDates={sldDates}
                  availableDates={availableDates}
                  completedDates={completedDates}
                  finishedDates={applicant.askedDatesLabels
                    ?.filter((e) => e.state === ApplicationDateState.Finished)
                    ?.map((e) => e.date)}
                  defaultAvailableDates={applicant.askedDatesLabels?.map(
                    (e) => e.date
                  )}
                  defaultDate={applicant.askedDates[0].date}
                  onDateSelected={onDateSelected}
                  dismiss={() => setCalendarVisible(false)}
                  tiny
                />
              </Col>
            ) : (
              <Col span={12}>
                <div style={{ height: '100%' }}>
                  {!assign && (
                    <>
                      <div className='row mb-03'>
                        <img
                          src={CalendarIcon}
                          alt='name'
                          className='grey-icon'
                          style={{ marginRight: '.5rem' }}
                        />

                        <div className='gray'>Disponibilités</div>
                      </div>

                      <div className='row mb-1 pos-r'>
                        <div>
                          {applicant.askedDatesLabels
                            ?.filter((e) =>
                              [
                                ApplicationDateState.Accepted,
                                ApplicationDateState.Finished,
                              ].includes(e.state)
                            )
                            // .slice(0, 4)
                            .map((availability, i) => (
                              <span key={i} className='mr-05 semibold'>
                                <span
                                  style={{
                                    textDecoration:
                                      availability?.state ===
                                      ApplicationDateState.Finished
                                        ? 'line-through'
                                        : 'none',
                                  }}
                                >
                                  {moment(
                                    availability.date,
                                    'DD/MM/YYYY'
                                  ).format('ddd DD/MM')}
                                </span>
                              </span>
                            ))}
                        </div>
                        {/* {applicant.askedDates.length > 4 && (
                          <div
                            className="ml-1 underlined pointer"
                            onClick={() => setCalendarVisible((v) => !v)}
                          >
                            Voir plus
                          </div>
                        )} */}
                      </div>
                    </>
                  )}
                </div>
              </Col>
            )}

            {/* Availability & Capacitation */}
            {/* TODO: Removed in +3 version */}
            {/* <Col span={12}>
              <div style={{ height: "100%" }}>
               
                {!assign && (
                  <>
                    <div className="row mb-03">
                      <img
                        src={CalendarIcon}
                        alt="name"
                        className="grey-icon"
                        style={{ marginRight: ".5rem" }}
                      />

                      <div className="gray">Disponibilités</div>
                    </div>

                    <div className="row mb-1 pos-r">
                      <div>
                        {applicant.askedDatesLabels
                          .slice(0, 4)
                          .map((availability, i) => (
                            <span key={i} className="mr-05 semibold">
                              <span
                                style={{
                                  textDecoration:
                                    !selectedDates.includes(availability) &&
                                    !selectedDates.includes(availability.date)
                                      ? "line-through"
                                      : "none",
                                }}
                              >
                                {moment(availability.date, "DD/MM/YYYY").format(
                                  "ddd DD/MM"
                                )}
                              </span>
                            </span>
                          ))}
                      </div>
                      {applicant.askedDates.length > 4 && (
                        <div
                          className="ml-1 underlined pointer"
                          onClick={() => setCalendarVisible((v) => !v)}
                        >
                          Voir plus
                        </div>
                      )}
                    </div>
                  </>
                )}


                <div className="row">
                  <img
                    src={Strong}
                    alt="name"
                    className="grey-icon"
                    style={{ marginRight: ".5rem" }}
                  />
                  <div className="gray">Points forts</div>
                </div>
                <div
                  className="row"
                  style={{
                    flexFlow: "wrap",
                    justifyContent: "flex-start",
                    paddingRight: "4rem",
                    marginTop: "2rem",
                    gap: "0.50rem",
                  }}
                >
                  {Object.keys(moodList)
                    .sort((a, b) => {
                      return moodList[b] - moodList[a];
                    })
                    .slice(0, 5)
                    .map((substituteMood, key) => {
                      return (
                        <StrengthIconCorrespondances
                          moodKey={substituteMood}
                          containerStyle={{ height: "1rem" }}
                        />
                      );
                    })}
                </div>
              </div>
            </Col> */}
            {/* {assign && <CalendarButton />} */}
          </div>
        </Col>
      ) : (
        <div
          className={
            isSelected
              ? 'row jc-sb shadowEffect mb-1 bg-fff p-1 br-05 greenBorder '
              : 'row jc-sb shadowEffect mb-1 bg-fff p-1 br-05'
          }
          onClick={() => setIsSelected()}
        >
          <div className='h3 tal flex-1 semibold'>{candidate.fullname}</div>
          <div className='h3 tal flex-1 semibold'>
            {applicant?.diplomas && applicant?.diplomas[0]}
          </div>
          <div className='h3 tal flex-1 semibold'>
            {applicant?.permissions?.join(' - ')}
          </div>
          <div className='h3 tal flex-1 semibold'>
            {applicant?.availableAt?.[0]?.concat(
              ' au ',
              applicant?.availableAt?.[applicant?.availableAt.length - 1]
            )}
          </div>
        </div>
      )}
    </>
  );
}
