import React, { useState, useContext, useEffect } from "react";
import { Bell } from "../assets/icons/icons";
import { Link } from "react-router-dom";
import { UserContext } from "./context/UserContext";
import { ProtectedImage } from "./ProtectedImage";
import { MediaFormat } from "../data/medias";
import Notifications from "../pages/navbar/Notifications";
import { OutsideClickHandler } from "./utils/OutsideClickHandler";
import { TkCare } from "../assets/logo/logo";
import { Button, createMission } from "./buttons/buttons";
import { NotificationContext } from "./context/NotificationContext";
import { useGetNotifications } from "../api/notifications";

function Navbar() {
  let page = window.location.pathname;
  const [notificationsVisibility, setNotificationsVisibility] = useState(false);
  const { user } = useContext(UserContext);
  const { notificationCount, updateLastCheckDate } =
    useContext(NotificationContext);
  const [validateGetNotifications, setValidateGetNotifications] =
    useState(false);
  const { data: notifications } = useGetNotifications(
    true,
    validateGetNotifications,
    setValidateGetNotifications
  );

  useEffect(() => {
    if (notificationCount <= 3 && notifications?.length > 0) {
      updateLastCheckDate(notifications[0].createdAt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  return (
    <>
      <div className="navbar bg-blue row">
        <div className="navbar-logo">
          <img src={TkCare} alt="logo" />
        </div>
        <div className="links" style={{ height: "30px" }}>
          <Link
            to="/board"
            className={`navbar-link ${
              page.includes("board") ? "underlined-navbar" : ""
            }`}
            id="navbar-button-board"
          >
            <div className="col white">BOARD</div>
          </Link>
          <Link
            to="/recruitments/validation"
            className={`navbar-link ${
              page.includes("recruitments") ? "underlined-navbar" : ""
            }`}
            id="navbar-button-recruitments"
          >
            <div className="col white">RECRUTEMENTS</div>
          </Link>
          <Link
            to="/missions/future"
            className={`navbar-link ${
              page.includes("missions") && !page.includes("new")
                ? "underlined-navbar no-break"
                : "no-break"
            }`}
            id="navbar-button-missions"
          >
            <div className="col white">SUIVI DE MISSION</div>
          </Link>
          <div className="links-col-right">
            <Link to="/missions/new/step-one" className="publish-button">
              <Button buttonProps={createMission} />
            </Link>
            <OutsideClickHandler
              onClickedOutside={() => setNotificationsVisibility(false)}
            >
              <div
                className="col notifications"
                style={{ width: "32px", position: "relative" }}
              >
                <div
                  className="pos-r pointer "
                  onClick={() => {
                    setNotificationsVisibility(!notificationsVisibility);
                    setValidateGetNotifications(true);
                  }}
                >
                  <img src={Bell} className="" alt="notifications" />
                  <div
                    className="bg-pink circle white pos-a aic jcc d-flex pointer"
                    style={{
                      width: "20px",
                      height: "20px",
                      left: "48.02%",
                      right: "10.52%",
                      top: "9.38%",
                      bottom: "49.16%",
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  >
                    {notificationCount > 3 ? "3+" : notificationCount}
                  </div>
                </div>
                {notificationsVisibility && notifications && (
                  <Notifications notifications={notifications} />
                )}
              </div>
            </OutsideClickHandler>
          </div>
        </div>
        <Link
          to="/profile/account"
          className="row navbar-profile ml-a"
          id="navbar-button-profile"
        >
          <ProtectedImage
            imageId={user?.profilePicture?.id}
            alt="profile picture"
            className="mr-1 circle"
            style={{ width: "48px", height: "48px" }}
            format={MediaFormat.PROFILE}
          />
          <div>
            <p className="white bold" id="userInfos">
              {user?.nickname}
            </p>
            {/* <p className="white nowrap">({data?.roles.join(' ')})</p> */}
            <p className="white nowrap">{user?.institutions?.[0].name}</p>
          </div>
        </Link>
      </div>
    </>
  );
}

export default Navbar;
