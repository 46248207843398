import React, { createContext, useContext, useEffect, useState } from "react";
import { plusOneSecond } from "../../api/format";
import {
  useGetNotificationCount,
  usePatchLastFetchDate,
} from "../../api/notifications";
import { UserContext } from "./UserContext";

type NotificationProps = {
  notificationCount: number;
  updateLastCheckDate: (date: string) => void;
};

export const NotificationContext = createContext<NotificationProps>({
  notificationCount: 0,
  updateLastCheckDate: (_: string) => {},
});

export const NotificationProvider = ({ children }) => {
  const [validateGetNotificationCount, setValidateGetNotificationCount] =
    useState(true);
  const { user } = useContext(UserContext);
  const [lastFetchDate, setLastFetchDate] = useState(null);
  usePatchLastFetchDate(lastFetchDate);
  const { data: countResult } = useGetNotificationCount(
    user ? validateGetNotificationCount : false,
    setValidateGetNotificationCount,
    lastFetchDate ?? user?.lastNotificationAt
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setValidateGetNotificationCount(true);
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const updateLastCheckDate = (date: string) => {
    setLastFetchDate(plusOneSecond(date));
  };

  return (
    <NotificationContext.Provider
      value={{
        notificationCount: countResult,
        updateLastCheckDate,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
