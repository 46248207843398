import React from "react";
import Navbar from "../components/Navbar";

import { Outlet } from "react-router-dom";

export default function AddMission() {
  return (
    <div>
      <Navbar />
      <div className="container" style={{ maxWidth: "1096px" }}>
        <h1 className="mt-3 mb-2">Publier une mission 🚀</h1>
        <div
          style={{
            lineHeight: "1.375rem",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          Remplir ce formulaire permet de créer une nouvelle mission ainsi que
          son Welcome Board, une fiche informative de la mission mise à
          disposition des intérimaires lors de leurs recherches. Une Welcome
          Board bien écrite rend votre mission plus attractive et vous permettra
          ainsi d’obtenir plus de candidats.
        </div>
        <Outlet />
      </div>
    </div>
  );
}
