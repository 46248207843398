import { MyCheckbox } from "./../components/MyCheckbox";
import React, { useContext, useState, useEffect } from "react";
import Background from "../components/Background";
import { TkCare } from "../assets/logo/logo";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../components/context/UserContext";
import { Link } from "react-router-dom";
import { usePostLogin } from "../api/user";
import { ManualTextError } from "../components";
import { Email32, Lock, EyeCrossed, Eye } from "../assets/icons/icons.ts";
import isDev from "../isDev";

import { Button, connect } from "../components/buttons/buttons";

const Login = () => {
  const { token, user, saveToken } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [rememberMe, setRememberMe] = useState(isDev);
  const [isVisible, setIsVisible] = useState(false);
  const [pwd, setPwd] = useState(undefined);
  const canValidate = user !== undefined && pwd !== undefined;
  const [validate, setValidate] = useState(false);
  const { data, loading, error } = usePostLogin(
    email,
    pwd,
    validate,
    setValidate
  );
  const [loaderVisible, setLoaderVisible] = useState(false);

  const navigate = useNavigate();

  function login() {
    setValidate(true);
  }

  useEffect(() => {
    if (token != null && user !== null) {
      navigate("board");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user]);

  useEffect(() => {
    if (data?.token) {
      saveToken(data.token, rememberMe);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    loading ? setLoaderVisible(true) : setLoaderVisible(false);
  }, [loading]);

  return (
    <Background>
      <div className="login">
        <div className="login-left">
          <div style={{ marginLeft: "2rem", marginTop: "2rem" }}>
            <img src={TkCare} alt="" style={{ height: "63px" }} />
          </div>
        </div>
        <div className="login-right">
          <div className="h1 bold mb-15">Bienvenue sur TkCare ! 🎉</div>
          <div className="mb-2 h2">
            Créer des missions et trouver des candidats compétents rapidement et
            simplement !
          </div>

          <div className="mb-2 bold h2" style={{ fontSize: "24px" }}>
            Connexion 🔐
          </div>

          <form>
            <label className="row semibold">
              <img
                src={Email32}
                alt="icon"
                className="blue-icon"
                style={{ marginRight: ".5rem" }}
              ></img>
              Adresse e-mail
            </label>
            <div className="row">
              <input
                type="text"
                placeholder={"rh@test.fr ou mp@test.fr"}
                className="textInput"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                id="input-login-email"
              />
            </div>
            <label className="mt-2 row semibold">
              <img src={Lock} alt="icon" style={{ marginRight: ".5rem" }}></img>
              Mot de passe
            </label>
            <div style={{ position: "relative", display: "flex" }}>
              <input
                type={isVisible ? "text" : "password"}
                placeholder="mot de passe"
                className="textInput"
                autoComplete="1234"
                onChange={(e) => setPwd(e.target.value)}
                id="input-login-password"
              />
              <img
                src={isVisible ? EyeCrossed : Eye}
                width={32}
                alt=""
                className="eyeImg centeryourself mt-05"
                onClick={() => setIsVisible((isVisible) => !isVisible)}
                style={{ marginLeft: "auto" }}
              />
            </div>
            <div className="mt-05 mb-2">
              <Link
                to="/forgotten-password"
                className="link"
                style={{ fontSize: "14px" }}
              >
                Mot de passe oublié
              </Link>
            </div>
            {error && <ManualTextError message={error} />}
            <MyCheckbox setRememberMe={setRememberMe} rememberMe={rememberMe} />
            <Button
              className={canValidate ? "" : "btn-invalid"}
              buttonProps={connect}
              onClicked={() => login()}
              disabled={!canValidate || loading}
            />
          </form>
        </div>
      </div>
    </Background>
  );
};

export default Login;
