import React, { useEffect, useState } from "react";
import { useGetApplicationsByWelcomeBoard } from "../../api/applications";
import { WelcomeBoardState } from "../../api/enums";
import PayInfo from "../../components/PayInfo";

type Props = {
  mission: any;
};

export default function Payments({ mission }: Props) {
  const [validate, setValidate] = useState(true);
  const [lastMissionId, setLastMissionId] = useState(mission.id);
  const { data: applicants } = useGetApplicationsByWelcomeBoard(
    mission.id,
    WelcomeBoardState.Finished,
    validate,
    setValidate
  );

  useEffect(() => {
    if (mission.id !== lastMissionId) {
      setLastMissionId(mission.id);
      setValidate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mission]);

  return (
    applicants &&
    applicants.map((applicant: any, key: number) => {
      return (
        <PayInfo
          key={key}
          applicant={applicant}
          hourlyRate={mission.hourlyRate}
        />
      );
    })
  );
}
