import { useState, useEffect, useContext } from 'react';
import { UserContext } from '../components/context/UserContext';

type RequestRepo = {
  url: string;
  method: RequestMethod;
  body?: any;
};

type RequestMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export const useRepository = async ({
  url,
  body,
  method = 'GET',
}: RequestRepo) => {
  if (!url) {
    throw new Error('You must provide an url');
  }
};

const useRequest = <D, R>(
  request: RequestInfo,
  requestInit: RequestInit = {},
  mapper: (raw: R) => D,
  fire: boolean = false,
  setValidate: (validate: boolean) => any = () => {}
) => {
  interface State {
    data: D | null;
    error: null | string;
    loading: boolean;
  }

  const [state, setState] = useState<State>({
    data: null,
    error: null,
    loading: false,
  });

  const updateState = (newState: {}) =>
    setState({
      ...state,
      ...newState,
    });

  const { logout } = useContext(UserContext);

  useEffect(() => {
    if (fire && logout && !state.loading) {
      updateState({
        loading: true,
        error: null,
      });
      fetch(request, requestInit)
        .then(async (response) => {
          if (response.ok) {
            const isMedia = response.headers
              .get('content-type')
              .startsWith('image/');
            if (isMedia) {
              const blob = await response.blob();
              updateState({
                data: URL.createObjectURL(blob),
                loading: false,
              });
            } else {
              const data: R = await response.json();
              const page = new URLSearchParams(
                new URL(response.url).search
              ).get('page');
              const mappedData = mapper(data);
              const pageAsNumber = page !== null ? Number(page) : -1;
              const merge =
                pageAsNumber !== -1 &&
                pageAsNumber !== 1 &&
                state.data !== null;
              updateState({
                data: merge
                  ? [
                      ...(state.data as unknown as any[]),
                      ...(mappedData as unknown as any[]),
                    ]
                  : mappedData,
                loading: false,
                error: null,
              });
            }
            setValidate(false);
          } else {
            if (response.status === 400) {
              const errors = await response.json();
              const labelErrors = [];
              Object.entries(errors).forEach(([key, value]) => {
                if (typeof value === 'string') {
                  if (value.includes('JWT Token')) {
                    logout();
                  } else {
                    labelErrors.push(value);
                  }
                }
              });
              const formattedErrors = labelErrors.join(', ');
              updateState({
                error: formattedErrors,
                loading: false,
              });
            } else {
              updateState({
                error: response.status.toString(),
                loading: false,
              });
            }
            setValidate(false);
          }
        })
        .catch((err) => {
          updateState({
            error: err.message,
            loading: false,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fire, logout]);

  return state;
};

export { useRequest };
