import * as Yup from "yup";
import { requiredField } from "../../../data/yup";

export default function validationSchema() {
  return Yup.object().shape({
    firstname: Yup.string().required(requiredField),
    lastname: Yup.string().required(requiredField),
    phone: Yup.string().required(requiredField),
    email: Yup.string().required(requiredField),
    institutions: Yup.array().min(1, requiredField),
  });
}
