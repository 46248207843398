import React from "react";
import { Block } from "../Block";
import Checkbox from "../Checkbox";
import { BlockProps } from "./blockProps";
type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
  blockProps: BlockProps;
};

const CheckboxBlock = ({ value, onChange, blockProps }: Props) => (
  <Block icon={blockProps.icon} label={blockProps.label} alt={blockProps.alt}>
    <Checkbox value={value} onChange={onChange} />
  </Block>
);

export default CheckboxBlock;
