type Mood = {
  key: string;
  label: string;
  emoji: string;
};

const moods: Mood[] = [
  {
    key: 'adaptability',
    label: 'Adaptabilité',
    emoji: '⚙️',
  },
  {
    key: 'efficiency',
    label: 'Efficacité',
    emoji: '🚀️️',
  },
  {
    key: 'empathy',
    label: 'Empathie',
    emoji: '❤️‍',
  },
  {
    key: 'team_spirit',
    label: 'Esprit d’équipe',
    emoji: '👫️️',
  },
  {
    key: 'punctuality',
    label: 'Ponctualité',
    emoji: '⏰',
  },
  {
    key: 'reactivity',
    label: 'Réactivité',
    emoji: '🔥',
  },
];

const institutionQualities: Mood[] = [
  {
    key: 'reception',
    label: 'Accueil',
    emoji: '🤗️️',
  },
  {
    key: 'top_team',
    label: 'Équipe au top',
    emoji: '🙌',
  },
  {
    key: 'reliability',
    label: 'Fiabilité',
    emoji: '👍',
  },
  {
    key: 'reactivity',
    label: 'Réactivité',
    emoji: '🔥',
  },
  {
    key: 'organisation',
    label: 'Organisation',
    emoji: '🗂',
  },
  {
    key: 'equipment',
    label: 'Matériel',
    emoji: '🔬',
  },
  {
    key: 'management',
    label: 'Management',
    emoji: '🤵',
  },
  {
    key: 'location',
    label: 'Lieu',
    emoji: '🏥',
  },
];

export { Mood, moods, institutionQualities };
