import React, { useState } from "react";
import Popup from "../../components/Popup";
import { Check } from "../../assets/icons/icons";
import { ProtectedImage } from "../../components/ProtectedImage";
import { MediaFormat } from "../../data/medias";
import { Relation } from "../../data/Relation";
import InfiniteScroll from "react-infinite-scroll-component";

type EntryProps = {
  entry: Relation;
  isSelected: boolean;
  onChange: (value: boolean) => void;
};

function Entry({ entry, isSelected, onChange }: EntryProps) {
  return (
    <div
      className="row mb-1 p-1 bg-fff br-05"
      onClick={() => onChange(!isSelected)}
    >
      <input
        type="radio"
        className="mr-1"
        checked={isSelected}
        onChange={() => {}}
      />
      <ProtectedImage
        imageId={entry.imageId}
        alt="employee avatar"
        className="circle mr-1"
        format={MediaFormat.PROFILE}
        style={{ width: "32px", height: "32px" }}
      />
      <div className="blue">{entry.label}</div>
    </div>
  );
}

type Props = {
  title: string;
  entries: Relation[];
  selectedEntries: Relation[];
  setSelectedEntries: (selectedEntries: Relation[]) => void;
  setPopupVisible: (value: boolean) => void;
  multiple: boolean;
  increment: () => void;
  search?: string;
  setSearch?: (newSearch: string) => void;
};

export default function SelectRelationPopup({
  title,
  entries,
  selectedEntries,
  setSelectedEntries,
  setPopupVisible,
  multiple,
  increment,
  search,
  setSearch,
}: Props) {
  const [localSelectedEntries, setLocalSelectedEntries] =
    useState(selectedEntries);
  return (
    <Popup
      modalBodyStyle={{
        height: search !== undefined ? "80%" : "90%",
      }}
      title={title}
      dismiss={() => {
        setPopupVisible(false);
      }}
      button={
        <button
          className="mr-a"
          onClick={() => {
            setSelectedEntries(localSelectedEntries);
            setPopupVisible(false);
          }}
        >
          Valider
          <img src={Check} alt="" className="ml-1" />
        </button>
      }
      managers={
        <div
          style={{
            height: search !== undefined ? "80%" : "100%",
          }}
        >
          {search !== undefined && (
            <input
              type="text"
              placeholder={"prénom nom"}
              className="textInput"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              value={search}
              id="search"
            />
          )}
          <div
            style={{
              width: "100%",
              height: "100%",
              overflowY: "scroll",
              marginTop: search !== undefined ? "20px" : "0px",
            }}
          >
            <InfiniteScroll
              dataLength={entries.length}
              next={() => {
                increment();
              }}
              hasMore={entries.length % 25 === 0}
              loader={null}
            >
              {entries.map((entry, i) => {
                return (
                  <Entry
                    entry={entry}
                    key={i}
                    onChange={(_) => {
                      setLocalSelectedEntries(
                        localSelectedEntries.some(
                          (localSelectedEntry) =>
                            localSelectedEntry.id === entry.id
                        )
                          ? localSelectedEntries.filter(
                              (localSelectedEntry) =>
                                localSelectedEntry.id !== entry.id
                            )
                          : [...(multiple ? localSelectedEntries : []), entry]
                      );
                    }}
                    isSelected={localSelectedEntries.some(
                      (localSelectedEntry) => localSelectedEntry.id === entry.id
                    )}
                  />
                );
              })}
            </InfiniteScroll>
          </div>
        </div>
      }
      bgColor="#e9e8ec"
      color="black"
      subtitle={undefined}
      image={undefined}
      question={undefined}
      textarea={undefined}
      welcomeBoardList={undefined}
      style={{
        width: "90vw",
        height: "90vh",
      }}
    />
  );
}
