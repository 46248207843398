import { InputProps } from "../../../components/FormInput";
import {
  Address,
  Phone32,
  Email32,
  Portrait32,
  Briefcase32,
  Executive24,
} from "../../../assets/icons/icons";

const name: InputProps = {
  title: "Nom de l'établissement",
  type: "text",
  placeholder: "",
  icon: null,
  alt: "name",
};

const address: InputProps = {
  title: "Adresse postale",
  type: "text",
  placeholder: "",
  icon: Address,
  alt: "address",
};

const phone: InputProps = {
  title: "Téléphone",
  type: "number",
  placeholder: "",
  icon: Phone32,
  alt: "phone",
};

const email: InputProps = {
  title: "Adresse e-mail",
  type: "email",
  placeholder: "",
  icon: Email32,
  alt: "email",
};

const password: InputProps = {
  title: "Mot de passe",
  type: "password",
  placeholder: "",
  icon: null,
  alt: "password",
};

const confirmPassword: InputProps = {
  ...password,
  title: "Confirmer le mot de passe",
};

const firstname: InputProps = {
  title: "Prénom",
  type: "text",
  placeholder: "",
  icon: Portrait32,
  alt: "firstname",
};

const lastname: InputProps = {
  title: "Nom",
  type: "text",
  placeholder: "",
  icon: Portrait32,
  alt: "lastname",
};

const status: InputProps = {
  title: "Statut",
  type: "text",
  placeholder: "",
  icon: Briefcase32,
  alt: "status",
};

const manager: InputProps = {
  title: "Manager RH",
  type: "text",
  placeholder: "",
  icon: Executive24,
  alt: "manager",
};

const servicePrecision: InputProps = {
  title: "Précision(s) du service de soins",
  type: "text",
  placeholder: "",
  icon: null,
  alt: "Précision du service",
}

const inputs = {
  name,
  address,
  phone,
  email,
  password,
  confirmPassword,
  firstname,
  lastname,
  status,
  manager,
  servicePrecision
};

export {
  inputs,
  email,
  password,
  confirmPassword,
  firstname,
  lastname,
  status,
  phone,
  manager,
  servicePrecision
};
