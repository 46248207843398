import { useRequest } from "..";
import { MediaFormat } from "../../data/medias";
import { postWithAuth, deleteWithAuth, getWithAuth } from "../requests";

const usePostMedia = (
  entity: string,
  id: string,
  body: any,
  fire: boolean,
  setValidate: (validate: boolean) => void
) => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}media/${entity}/${id}`,
    postWithAuth(body, { "Content-Type": null }),
    (raw: any) => raw,
    fire,
    setValidate
  );
};

const postMedias = async (
  entity: string,
  id: string,
  formData: FormData[]
): Promise<boolean> => {
  const requests = formData.map((fd) =>
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}media/${entity}/${id}`,
      postWithAuth(fd, { "Content-Type": null })
    )
  );
  const responses = await Promise.all(requests);
  const errors = responses.filter((response) => !response.ok);
  return errors.length === 0;
};

const deleteMedias = async (ids: string[]): Promise<boolean> => {
  if (ids.length === 0) return true;
  const requests = ids.map((id) =>
    fetch(`${process.env.REACT_APP_API_BASE_URL}media/${id}`, deleteWithAuth())
  );
  const responses = await Promise.all(requests);
  const errors = responses.filter((response) => !response.ok);
  return errors.length === 0;
};

const useDeleteMedia = (id, validate, setValidate) => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}media/${id}`,
    deleteWithAuth(),
    (raw: any) => raw,
    validate,
    setValidate
  );
};

const useGetMedia = (id: string | null, format?: MediaFormat) => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}media/${id}/${
      format ?? MediaFormat.HORIZONTAL
    }`,
    getWithAuth(),
    (raw: any) => raw,
    typeof id === "string"
  );
};

const getMediaSource = async (id: string): Promise<string> => {
  return fetch(
    `${process.env.REACT_APP_API_BASE_URL}media/${id}/source`,
    getWithAuth()
  ).then(async (response) => {
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  });
};

export {
  usePostMedia,
  useDeleteMedia,
  useGetMedia,
  postMedias,
  deleteMedias,
  getMediaSource,
};
