import React, { useContext } from "react";
import Navbar from "../components/Navbar";
import Mission from "../components/Mission";
import { BoardOverlay } from "./stats";
import EstablishmentSelect from "../components/EstablishmentSelect";
import { UserContext } from "../components/context/UserContext";
import { useGetWelcomeBoardsByInstitution } from "../api/welcomeboards";
import { WelcomeBoardState } from "../api/enums";
import { useGetLastStatistics } from "../api/statistics";
import StatCard from "./stats/StatCard";

const texts = {
  mp: {
    missionTitle: "Vos missions",
    statisticsTitle: "Vos statistiques du mois",
  },
  rh: {
    missionTitle: "Les missions",
    statisticsTitle: "Les statistiques du mois",
  },
};

function Board() {
  const { isRh, selectedInstitution, contractType } = useContext(UserContext);

  const userTexts = isRh() ? texts.rh : texts.mp;

  const { data: stats } = useGetLastStatistics(selectedInstitution);

  const { data: draftOrPlanned } = useGetWelcomeBoardsByInstitution(
    1,
    isRh() ? WelcomeBoardState.Draft : WelcomeBoardState.Planned,
    selectedInstitution,
    contractType,
    true,
    (_) => {},
    1,
    isRh() ? undefined : false
  );

  const { data: planned } = useGetWelcomeBoardsByInstitution(
    1,
    isRh()
      ? WelcomeBoardState.Planned
      : `${WelcomeBoardState.Planned},${WelcomeBoardState.Ongoing}`,
    selectedInstitution,
    contractType,
    true,
    (_) => {},
    1,
    isRh() ? undefined : false
  );

  const { data: finishedOrOngoing } = useGetWelcomeBoardsByInstitution(
    1,
    isRh() ? WelcomeBoardState.Finished : WelcomeBoardState.Ongoing,
    selectedInstitution,
    contractType,
    true,
    (_) => {},
    1
  );

  return (
    <>
      <div className="">
        <Navbar />
        {isRh() && <EstablishmentSelect />}
        <div className="view">
          <div
            className={
              isRh()
                ? "mb-2 mt-2  mr-a title bold"
                : "mb-2 mt-2  mr-a title bold"
            }
          >
            {userTexts.missionTitle} 🚀
          </div>
          <div className="missionsContainer">
            {draftOrPlanned &&
              planned &&
              finishedOrOngoing &&
              [...draftOrPlanned, ...planned, ...finishedOrOngoing].map(
                (m, i) => {
                  return <Mission mission={m} key={i} />;
                }
              )}
          </div>
          <div className="mb-2 mt-3 as-fs title bold">
            {userTexts.statisticsTitle} 📈
          </div>
          <div className="statistiques">
            <BoardOverlay firstCategory="institution_wb_created" />
            <BoardOverlay
              firstCategory="institution_substitute_selected"
              secondCategory="institution_substitute_fidelized"
            />
            {stats &&
              stats
                .filter(
                  (stat) =>
                    ![
                      "institution_wb_created",
                      "institution_substitute_selected",
                      "institution_substitute_fidelized",
                    ].includes(stat.statCategory)
                )
                .map((stat, i) => {
                  return <StatCard key={i} stat={stat} />;
                })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Board;
