import { Hospital24, Laboratory24 } from "../assets/icons/icons";

enum InstitutionType {
  HOSPITAL = "hospital",
  LABORATORY = "laboratory",
}

const getInstitutionIcon = (type: string | null | undefined) => {
  if (type === null || type === undefined) return null;
  return type === InstitutionType.HOSPITAL ? Hospital24 : Laboratory24;
};

export { InstitutionType, getInstitutionIcon };
