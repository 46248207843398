import React from "react";
import { ProtectedImage } from "./ProtectedImage";
import { MediaFormat } from "../data/medias";
import { Portrait24, Briefcase24, Phone24 } from "../assets/icons/icons";
import { ManualTextError } from "./TextError/TextError";

export default function ApplicantInfo({
  applicant,
  children,
  patchHoursError,
}) {
  const { candidate } = applicant;
  return (
    <>
      <div className="applicant-profile-notation">
        <ProtectedImage
          imageId={candidate.profilePicture?.id}
          alt="applicant picture"
          className="br-05"
          style={{ width: "5.5rem", height: "5.5rem" }}
          format={MediaFormat.PROFILE}
        />
        <div
          className="col ai-fs mw-fc ml-1"
          style={{ gap: "10px", width: "14rem" }}
        >
          <div className="row">
            <img src={Portrait24} alt="" className="blue-icon icon-sm" />
            <span className="semibold ml-05">{candidate.fullname}</span>
          </div>
          <div className="row">
            <img src={Briefcase24} alt="" className="blue-icon icon-sm" />
            <span className="ml-05">{candidate.occupationLabel}</span>
          </div>
          <div className="row">
            <img src={Phone24} alt="" className="blue-icon icon-sm" />
            <span className="semibold ml-05">{candidate.phone}</span>
          </div>
        </div>
        {children}
      </div>
      {/* Error message */}
      {patchHoursError && (
        <ManualTextError
          style={{
            margin: "0 1rem 0 0",
            padding: 0,
            textAlign: "end",
            fontSize: "0.7rem",
          }}
          message={patchHoursError}
        />
      )}
    </>
  );
}
