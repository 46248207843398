import { MyCheckbox } from "../../components/MyCheckbox";
import React, { useContext, useState } from "react";
import Background from "../../components/Background";
import { TkCare } from "../../assets/logo/logo";
import { Button, gcu } from "../../components/buttons/buttons";
import useGetGcu from "../../api/gcu";
import useGetPrivacyPolicy from "../../api/privacyPolicy";
import { UserContext } from "../../components/context/UserContext";
import GcuPopup from "./GcuPopup";

const GcuValidation = () => {
  const [validateGcu, setValidateGcu] = useState(false);
  const [validatePrivacyPolicy, setValidatePrivacyPolicy] = useState(false);
  const [gcuPopupVisibility, setGcuPopupVisibility] = useState(false);
  const [privacyPolicyPopupVisibility, setPrivacyPolicyPopupVisibility] = useState(false);
  const { data: gcuData, error: gcuError }  = useGetGcu()
  const { data: privacyPolicyData, error: privacyPolicyError } = useGetPrivacyPolicy()
  const { updateGcu: access } = useContext(UserContext)
  return (
    <Background>
      <div className="login">
        <div className="login-left">
          <div style={{ marginLeft: "2rem", marginTop: "2rem" }}>
            <img src={TkCare} alt="" style={{ height: "63px" }} />
          </div>
        </div>
        <div className="login-right">
          <div className="h1 bold mb-15">Bienvenue sur TkCare ! 🎉</div>
          <div className="mb-2 h2">
            Avant de pouvoir accéder au site web de TkCare, merci de bien vouloir lire et accepter nos conditions générales d’utilisation ainsi que notre charte des données personnelles.
          </div>

          <div className="mb-2 bold h2" style={{ fontSize: "24px" }}>
            Nos conditions générales d’utilisation 📄
          </div>
          <div onClick={() => {
            if(gcuError === '404') {
              setValidateGcu(true)
            } else {
              setGcuPopupVisibility(true)
            }
          }}>
            <MyCheckbox rememberMe={validateGcu} setRememberMe={() => {}} label="J’ai lu et j’accepte les conditions générales de TkCare" />
          </div>
          <div className="mb-2 bold h2" style={{ fontSize: "24px" }}>
            Notre charte des données personnelles 🔐
          </div>
          <div onClick={() => {
            if(privacyPolicyError === '404') {
              setValidatePrivacyPolicy(true)
            } else {
              setPrivacyPolicyPopupVisibility(true)
            }
          }}>
            <MyCheckbox rememberMe={validatePrivacyPolicy} setRememberMe={() => {}} label="J’ai lu et j’accepte le charte des données personnelles de TkCare" />
          </div>
          <Button
              className={validateGcu && validatePrivacyPolicy ? "" : "btn-invalid"}
              buttonProps={gcu}
              onClicked={() => access()}
              disabled={!validateGcu || !validatePrivacyPolicy}
            />
        </div>
      </div>
      {((gcuPopupVisibility || privacyPolicyPopupVisibility) && (gcuData || privacyPolicyData)) && (
        <GcuPopup
          title={gcuPopupVisibility ? "Nos conditions générales d’utilisation 📄" : "Notre charte des données personnelles 🔐"}
          description={gcuPopupVisibility ? gcuData.content : privacyPolicyData.content}
          onClosed={() => {
            setGcuPopupVisibility(false)
            setPrivacyPolicyPopupVisibility(false)
          }}
          onValidated={() => {
            gcuPopupVisibility ? setValidateGcu(true) : setValidatePrivacyPolicy(true)
            setGcuPopupVisibility(false)
            setPrivacyPolicyPopupVisibility(false)
          }}
        />
      )}
    </Background>
  );
};

export default GcuValidation;
