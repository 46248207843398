import { PopupInfo } from "./../components/PopupInfo";
import React, { useState, useContext } from "react";
import Navbar from "../components/Navbar";
import { Outlet, useNavigate } from "react-router-dom";
import { UserContext } from "../components/context/UserContext";
import { PopupLogoutProps } from "../components/popup/popupsProps";

import {
  Profile24, 
  Laboratory24, 
  Hospital24, 
  Executive24, 
  Board24,
  Logout24, 
  ChevronRight } from "../assets/icons/icons.ts";
import { Gcu24, PrivacyPolicy24 } from "../assets/icons/icons";

export default function Profile() {
  const { logout, user, isHospital, isRh } = useContext(UserContext);

  const account = {
    itemName: "Votre compte",
    icon: Profile24,
    link: "/profile/account",
  };
  const boards = {
    itemName: "Vos Welcome Boards",
    icon: Board24,
    link: "/profile/welcome-boards",
  };
  const establishmentMP = {
    itemName: "Votre établissement",
    icon: isHospital ? Hospital24 : Laboratory24,
    link: `/profile/establishments/${user?.institutions?.[0]?.id ?? ""}`,
  };
  const proxManagers = {
    itemName: "Vos managers de proximités",
    icon: Executive24,
    link: "/profile/proximity-managers",
  };
  const establishments = {
    itemName: "Vos établissements",
    icon: isHospital ? Hospital24 : Laboratory24,
    link: "/profile/establishments",
  };
  const gcu = {
    itemName: "Conditions générales d’utilisation",
    icon: Gcu24,
    link: "/profile/gcu",
  }
  const privacyPolicy = {
    itemName: "Charte des données personnelles",
    icon: PrivacyPolicy24,
    link: "/profile/privacy-policy",
  }

  const instanciationsMP = [account, boards, establishmentMP, gcu, privacyPolicy];
  const instanciationsRH = [account, establishments, proxManagers, boards, gcu, privacyPolicy];

  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const page = window.location.pathname;

  const instanciations = isRh() ? instanciationsRH : instanciationsMP;

  return (
    <div>
      <Navbar />
      <div className="container d-flex mt-3">
        <div className="profile-left pointer" style={{ width: "300px" }}>
          {instanciations.map((m, i) => {
            return (
              <div
                className="profile-item pointer"
                onClick={() => navigate(m.link)}
                key={i}
              >
                <img
                  src={m.icon}
                  alt="icon"
                  className={`mr-1 ${page.includes(m.link) ? "pink-icon" : "blue-icon"}`}
                  style={{ width: "24px", height: "24px" }}
                />
                <span
                  style={{ fontSize: "16px", maxWidth: "230px" }}
                  className={
                    page.includes(m.link)
                      ? "pink semibold"
                      : "semibold"
                  }
                >
                  {m.itemName}
                </span>
                <img
                  // src={page.includes(m.link) ? ChevronPinkImg : ChevronIcon}
                  src={ChevronRight}
                  className={`ml-a ${page.includes(m.link) ? "pink-icon" : "blue-icon"}`}
                  alt=""
                />
              </div>
            );
          })}
          <div
            className="profile-item pointer semibold"
            onClick={() => setIsVisible(true)}
          >
            <img src={Logout24} alt="logout" className="mr-1" />
            Déconnexion
            <img src={ChevronRight} className="ml-a" alt="" />
          </div>
        </div>
        <Outlet />
      </div>
      {isVisible && (
        <PopupInfo
          popupProps={PopupLogoutProps}
          onClosed={() => setIsVisible(false)}
          onValidated={() => logout()}
        />
      )}
      {/* {popUpVisible &&
                (<SelectManagersPopup />)
            } */}
    </div>
  );
}
