import React, { useState, useContext, useEffect } from "react";
import DisplayChoice from "../components/DisplayChoice";
import MissionCard from "../pages/recruitment/MissionCard";
import Navbar from "../components/Navbar";
import Toggle from "../components/Toggle";
import EstablishmentSelect from "../components/EstablishmentSelect";
import MissionSmall from "../components/MissionSmall";
import MissionSmallHeader from "../components/MissionSmallHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../components/context/UserContext";
import {
  useGetWelcomeBoardsByInstitution,
  useGetWelcomeBoardsCountByInstitution,
} from "../api/welcomeboards";
import InfiniteScroll from "react-infinite-scroll-component";

function Recruitments() {
  const location = useLocation();
  const [toggleEntries, setToggleEntries] = useState([]);
  const [welcomeBoardState, setWelcomeBoardState] = useState({
    status: "unknown",
    path: "/unknown",
    label: "",
    counts: [],
  });
  const [displayMode, setdisplayMode] = useState(1);
  const { selectedInstitution, contractType } = useContext(UserContext);
  const [validate, setValidate] = useState(false);
  const [page, setPage] = useState(1);
  const { data: welcomeBoards } = useGetWelcomeBoardsByInstitution(
    page,
    welcomeBoardState.status,
    selectedInstitution,
    contractType,
    validate,
    setValidate,
    25,
    ["planned,ongoing", "draft,refused"].includes(welcomeBoardState.status) ? false : undefined
  );
  const { data: countResult } = useGetWelcomeBoardsCountByInstitution(
    selectedInstitution,
    contractType,
    validate,
    ["planned,ongoing", "draft,refused"].includes(welcomeBoardState.status) ? false : undefined
  );
  const navigate = useNavigate();

  const recruitmentsFilters = [
    {
      status: "draft,refused",
      path: "/recruitments/validation",
      label: `À valider`,
      counts: ["draft", "refused"],
    },
    {
      status: "planned,ongoing",
      path: "/recruitments/dispatch",
      label: `À attribuer`,
      counts: ["planned", "ongoing"],
    },
  ];

  const missionsFilters = [
    {
      status: "planned",
      path: "/missions/future",
      label: `À venir`,
      counts: ["planned"],
    },
    {
      status: "ongoing",
      path: "/missions/present",
      label: `En cours`,
      counts: ["ongoing"],
    },
    {
      status: "finished",
      path: "/missions/past",
      label: `Terminées`,
      counts: ["finished"],
    },
  ];

  useEffect(() => {
    const index = location.pathname.indexOf("/");
    const path = location.pathname.substring(index);
    if (
      toggleEntries.length === 0 ||
      !toggleEntries.find((toggleEntries) => toggleEntries.path === path)
    ) {
      const toggleEntries = location.pathname.includes("recruitments")
        ? recruitmentsFilters
        : missionsFilters;
      setToggleEntries(toggleEntries);
      setWelcomeBoardState(
        toggleEntries.find(
          (toggleEntry) => toggleEntry.path === location.pathname
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setPage(1);
    if (selectedInstitution && contractType) {
      setValidate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, selectedInstitution, contractType]);

  useEffect(() => {
    if (welcomeBoardState.status !== "unknown") {
      navigate(welcomeBoardState.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [welcomeBoardState]);

  function changeDisplay(params) {
    setdisplayMode(params);
  }

  const count = (toggleEntry) => {
    return toggleEntry && countResult
      ? toggleEntry.counts
          .map(
            (count) =>
              Object.entries(countResult).find((attr) => attr[0] === count)[1]
          )
          .reduce((a, b) => a + b, 0)
      : null;
  };

  return (
    <>
      <Navbar />
      <EstablishmentSelect />
      <div className="view">
        <div className="mt-3">
          {countResult && (
            <Toggle
              chooseToggle={(index) => {
                setWelcomeBoardState(toggleEntries[index]);
              }}
              items={toggleEntries.map((toggleEntry) => {
                const label = toggleEntry.label;
                return `${label} (${count(toggleEntry)})`;
              })}
              selectedItemIdx={toggleEntries.findIndex(
                (toggleEntry) => toggleEntry === welcomeBoardState
              )}
            />
          )}
        </div>
        <div className="row mt-2 mb-2">
          <div className="h2 subtitle bold">
            {count(welcomeBoardState)} Mission
            {count(welcomeBoardState) > 1 && "s"}{" "}
            {welcomeBoardState.label.toLowerCase()}
          </div>
          <DisplayChoice passInfoToParent={changeDisplay} />
        </div>
        {welcomeBoards && (
          <InfiniteScroll
            dataLength={welcomeBoards.length}
            next={() => {
              setPage(page + 1);
              setValidate(true);
            }}
            hasMore={welcomeBoards.length < count(welcomeBoardState)}
          >
            {displayMode === 1
              ? welcomeBoards.map((mission, i) => {
                  return (
                    <div key={i} className="mb-1">
                      <MissionCard
                        mission={mission}
                        currentPage={location.pathname}
                        key={i}
                        onClicked={() => {
                          navigate(`${welcomeBoardState.path}/${mission.id}`);
                        }}
                      />
                    </div>
                  );
                })
              : welcomeBoards.map((mission, i) => {
                  return (
                    <div key={i} className="mb-1">
                      {i === 0 && (
                        <MissionSmallHeader
                          isHospital={selectedInstitution?.type === "hospital"}
                        />
                      )}
                      <div>
                        <MissionSmall
                          mission={mission}
                          onClicked={() => {
                            navigate(`${welcomeBoardState.path}/${mission.id}`);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
          </InfiniteScroll>
        )}
      </div>
    </>
  );
}

export default Recruitments;
