import React from "react";
import { Link } from "react-router-dom";
import { WelcomeBoardState } from "../api/enums";

import {
  Clock24,
  Calendar24,
  Hospital24,
  Laboratory24,
  GirlPlus24,
  Idea,
} from "../assets/icons/icons";
import Formatter from "./utils/Formatter";

function Mission({ mission }) {
  const getRedirection = (mission) => {
    if (mission.status === WelcomeBoardState.Draft) {
      return "recruitments/validation";
    }
    if (mission.status === WelcomeBoardState.Planned) {
      return mission.isCompleted ? "missions/future" : "recruitments/dispatch";
    }
    if (mission.status === WelcomeBoardState.Ongoing) {
      return "missions/present";
    }
    return "missions/past";
  };

  const getStatus = (mission) => {
    if (mission.status === WelcomeBoardState.Draft) {
      return "À valider";
    }
    if (mission.status === WelcomeBoardState.Planned) {
      return mission.isCompleted ? "À venir" : "À attribuer";
    }
    if (mission.status === WelcomeBoardState.Ongoing) {
      return "En cours";
    }
    return "Terminées";
  };

  return (
    <div className="mission ">
      <div className="subtitle as-fs">{getStatus(mission)}</div>
      <div className="missionContainer shadowEffect as-fs pointer">
        <Link to={`/${getRedirection(mission)}/${mission.id}`} className="blue">
          <div className="row mb-05">
            <img
              src={mission.isHospital ? Hospital24 : Laboratory24}
              alt="lab_icon"
              className="blue-icon"
            />
            <div className="ml-05  subtitle">{Formatter.formatMissionTitle(mission.name)}</div>
          </div>
          <div className="row mb-1">
            <img
              src={mission.service?.icon}
              alt="lab_icon"
              className="blue-icon"
            />
            <div className="ml-05 text semibold">{mission.serviceLabel}</div>
          </div>
          <div className="mb-1">
            <div className="row mb-05">
              <img src={Calendar24} alt="lab_icon" className="grey-icon" />
              <div className="ml-05 text gray">Dates</div>
            </div>
            <div className="row">
              <div className="text semibold">{mission.dateRange}</div>
            </div>
          </div>

          <div className="mb-1">
            <div className="row mb-05">
              <img src={Clock24} alt="lab_icon" className="grey-icon" />
              <div className="ml-05 text gray">Horaires</div>
            </div>
            <div className="row">
              <div className="missionText semibold">{mission.hourRange}</div>
            </div>
          </div>
          <div>
            <div className="row mb-05">
              <img src={GirlPlus24} alt="lab_icon" className="grey-icon" />
              <div className="ml-05 text gray">Nombre d'intérimaires</div>
            </div>
            <div className="row ml-025 jc-sb">
              <div className="semibold">
                {mission.countApplication} candidat
                {mission.countApplication !== 1 ? "s ont" : " a"} postulé
              </div>
              <img src={Idea} className="ml-" alt="idea" />
            </div>
          </div>
        </Link>
      </div>
      <Link
        to={`/${getRedirection(mission)}`}
        className="text as-fe mt-1 underlined blue"
      >
        Voir plus
      </Link>
    </div>
  );
}

export default Mission;
