import { WelcomeBoardState } from '../../api/enums';
import {
  CrossRed,
  HourGlass,
  Employee32,
  CheckGreen,
} from '../../assets/icons/icons';
import { getNumberOfDaysBetweenDates } from '../../api/format';

type BadgeInfoProps = {
  className: string;
  icon: string;
  label: string;
};

type AskedDateResponse = {
  createdAt: string;
  customerCheckinDatetime?: string;
  customerCheckoutDatetime?: string;
  dailyComment?: string;
  date: string;
  id: string;
  state: 'finished' | 'ongoing' | 'canceled' | 'blocked' | 'accepted';
  substituteCheckinDatetime: string;
  substituteCheckoutDatetime?: string;
  updatedAt: string;
};

const getBadgeInfoProps = (mission: any, pathname: string) => {
  let badgeInfo: BadgeInfoProps;
  const recruitments = pathname.includes('recruitments');
  if (mission.welcomeBoardState === WelcomeBoardState.Draft) {
    badgeInfo = {
      className: 'badge gold bg-lightgold',
      icon: HourGlass,
      label: 'En attente',
    };
  }
  if (mission.welcomeBoardState === WelcomeBoardState.Refused) {
    badgeInfo = {
      className: 'badge red bg-red1',
      icon: CrossRed,
      label: 'Refusée',
    };
  }
  if (mission.welcomeBoardState === WelcomeBoardState.Planned) {
    badgeInfo = {
      className: recruitments
        ? 'badge pink bg-red1'
        : 'badge gold bg-lightgold',
      icon: recruitments ? Employee32 : HourGlass,
      label: recruitments
        ? `${mission.countApplication} candidat${
            mission.countApplication > 1 ? 's' : ''
          }`
        : `Dans ${getNumberOfDaysBetweenDates(mission.startAt)} jours`,
    };
  }
  if (mission.welcomeBoardState === WelcomeBoardState.Ongoing) {
    const count = recruitments
      ? mission.countApplication
      : mission.countApplicationOngoing + mission.countApplicationAbsent;
    badgeInfo = {
      className: 'badge pink bg-red1',
      icon: Employee32,
      label: `${count} candidat${count > 1 ? 's' : ''}`,
    };
  }
  if (mission.welcomeBoardState === WelcomeBoardState.Finished) {
    const count =
      mission.countApplicationOngoing + mission.countApplicationFinished;
    badgeInfo = {
      className: 'badge pink bg-red1',
      icon: Employee32,
      label: `${count} candidat${count > 1 ? 's' : ''}`,
    };
  }
  return badgeInfo;
};

const getCandidateArrivalProps = (
  currentDate: AskedDateResponse
): BadgeInfoProps => {
  const absent = currentDate.state === WelcomeBoardState.Canceled;
  const arrived =
    currentDate.customerCheckinDatetime !== null ||
    currentDate.substituteCheckinDatetime !== null;
  return {
    className: arrived
      ? 'badge green bg-green4'
      : absent
      ? 'badge pink bg-red1'
      : 'badge gold bg-lightgold',
    icon: arrived ? CheckGreen : absent ? CrossRed : HourGlass,
    label: 'Arrivée',
  };
};

const getCandidateDepartureProps = (
  currentDate: AskedDateResponse
): BadgeInfoProps => {
  const absent = currentDate.state === WelcomeBoardState.Canceled;
  const departure =
    currentDate.customerCheckoutDatetime !== null ||
    currentDate.substituteCheckoutDatetime !== null;
  return {
    className: departure
      ? 'badge green bg-green4'
      : absent
      ? 'badge pink bg-red1'
      : 'badge gold bg-lightgold',
    icon: departure ? CheckGreen : absent ? CrossRed : HourGlass,
    label: 'Départ',
  };
};

export {
  BadgeInfoProps,
  getBadgeInfoProps,
  getCandidateArrivalProps,
  getCandidateDepartureProps,
};
