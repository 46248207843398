import { useRequest } from "..";
import { get } from "../requests";

const useGetGcu = () => {
  return useRequest(
    `${process.env.REACT_APP_API_BASE_URL}cgv/customer`,
    get(),
    (raw: any) => raw,
    true
  );
};

export default useGetGcu