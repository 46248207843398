import React from "react";

type Props = {
  icon: string;
  label: string;
  alt: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
};

const Block = ({ icon, label, alt, children, style }: Props) => (
  <>
    <div className="row mt-2" style={style}>
      <img src={icon} alt={alt} className='blue-icon mr-05'/>
      <div className="semibold">{label}</div>
    </div>
    <div className="mt-1">{children}</div>
  </>
);

export { Block };
