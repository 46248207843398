import React from "react";
import { Staffs32 as StaffsIcon } from "../../../assets/icons/icons";
import { Block } from "../Block";
import { Staff, occupations } from "../../../data/occupations";
import AddButton from "./AddButton";
import CircleInput from "./CircleInput";
import { Select } from "../../../components";
import BinButton from "./BinButton";

type Props = {
  staffs: Staff[];
  onChange: (values: Staff[]) => void;
};

const StaffsBlock = ({ staffs, onChange }: Props) => (
  <Block icon={StaffsIcon} label="Equipe" alt="staffs">
    <>
      <div className="row ai-fs jc-fs">
        <>
          <div
            style={{
              display: "inline-flex",
              flexWrap: "wrap",
            }}
          >
            {staffs.map((staff, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: index > 0 && index % 3 !== 0 ? "32px" : "0px",
                    gap: "8px",
                    marginTop: index > 2 ? "8px" : "0px",
                  }}
                  key={index}
                >
                  <CircleInput
                    value={staff?.number ?? ""}
                    placeholder={"1"}
                    onChange={(number) => {
                      const updatedStaffs = staffs.map(
                        (currentStaff, subIndex) => {
                          if (subIndex === index) {
                            currentStaff.number = number;
                          }
                          return currentStaff;
                        }
                      );
                      onChange(updatedStaffs);
                    }}
                  />
                  <Select
                    selectedItem={staff?.occupation}
                    placeholder="IDE"
                    items={occupations}
                    onItemSelected={(occupation) => {
                      const updatedStaffs = staffs.map(
                        (currentStaff, subIndex) => {
                          if (subIndex === index) {
                            currentStaff.occupation = occupation;
                          }
                          return currentStaff;
                        }
                      );
                      onChange(updatedStaffs);
                    }}
                    style={{ width: "264px" }}
                  />
                  {index === staffs.length - 1 && (
                    <BinButton
                      onClick={() => {
                        const updatedStaffs = staffs;
                        updatedStaffs.pop();
                        onChange(updatedStaffs);
                      }}
                      style={{
                        marginLeft: (index + 1) % 3 !== 0 ? "24px" : "16px",
                      }}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </>
      </div>
      <AddButton
        label="Ajouter des
               membres"
        onClick={() => {
          const updatedStaffs = staffs;
          updatedStaffs.push({ number: "", occupation: null });
          onChange(updatedStaffs);
        }}
      />
    </>
  </Block>
);

export default StaffsBlock;
